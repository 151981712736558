import {lifecycle} from 'recompose';

// make sure that we always have a project selection row on the timesheet, unless it's been completely submitted or approved
export default lifecycle({
  componentDidUpdate(prevProps) {
    if (
      // this.props.mode !== APPROVAL_MODE &&
      !this.props.timesheet.isSubmitted
    ) {
      // ensure we have a blank row as long as the timesheet has not been entirely submitted
      // (and, we don't do the blank row on the approval view)
      this.props.addRow();
    }
  },
  componentDidMount() {
    // make sure we have a blank row
    this.props.addRow();
  }
});
