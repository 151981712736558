import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * <TimesheetDayTitles />
 * Timesheet day titles (for mobile devices)
 */
function TimesheetDayTitles({entries, totals}) {
  function renderEntryHeader({Date}, index) {
    const approved = totals && totals[index] && totals[index].Approved;
    const submitted =
      !approved && totals && totals[index] && totals[index].Submitted;

    return (
      <div className="col-day" key={Date}>
        <div className='day col-xs-2'>
          {moment(Date).format('ddd')}
        </div>        
        <div className='day-num col-xs-1'>
          {moment(Date).format('D')}
        </div>
        {approved && <i className="fa fa-lock" />}
        {submitted && <i className="fa fa-check" />}
      </div>
    );
  }

  return (
    <div className="day-titles col-md-12 col-xs-3">
      {entries.map((entry, index) => renderEntryHeader(entry, index))}
    </div>
  );
}

TimesheetDayTitles.propTypes = {
  entries: PropTypes.array.isRequired
};

export default TimesheetDayTitles;
