import {createAction} from 'redux-actions';
import {showMessageFor} from '../../constants';

import * as types from './types';

// UI state
export const toggleCalendar = createAction(types.TOGGLE_CALENDAR);
export const toggleMobileDashboard = createAction(
  types.TOGGLE_MOBILE_DASHBOARD
);
export const clearMessages = createAction(types.CLEAR_MESSAGES);
export const clearLoading = createAction(types.CLEAR_LOADING);
export const setLoading = createAction(types.SET_LOADING);
export const setWarning = e => dispatch => {
  dispatch({type: types.SET_WARNING, payload: e});
  setTimeout(
    () => dispatch({type: types.SET_WARNING, payload: ''}),
    showMessageFor
  );
};
export const setError = e => dispatch => {
  dispatch({type: types.SET_ERROR, payload: e});
  setTimeout(
    () => dispatch({type: types.SET_ERROR, payload: ''}),
    showMessageFor
  );
};
export const setSuccess = e => dispatch => {
  dispatch({type: types.SET_SUCCESS, payload: e});
  setTimeout(
    () => dispatch({type: types.SET_SUCCESS, payload: ''}),
    showMessageFor
  );
};
export const showConfirmation = createAction(types.SHOW_CONFIRMATION);
export const hideConfirmation = createAction(types.HIDE_CONFIRMATION);
// setSavePending({someid: true})
export const setSavePending = createAction(types.SET_SAVE_PENDING);
export const onTimesheetNavigate = createAction(types.ON_TIMESHEET_NAVIGATE);
export const setTimesheetUser = createAction(
  types.SET_TIMESHEET_USER,
  sel => (sel) ? sel.value : null
);

// time entry
export const setActiveEntry = createAction(types.SET_ACTIVE_ENTRY);
export const setActiveRow = createAction(types.SET_ACTIVE_ROW);
export const setEditRow = createAction(types.SET_EDIT_ROW);
