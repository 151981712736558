import React, { Component } from 'react';
import Dialog from '../Dialog';
import NCRForm from './NCRForm';

class NCRDialog extends Component {
  componentDidMount() {
  }
  state = {
    hdCaseNum: null,
  };

  handleFormSubmitSuccess = (hdCaseNum) => {
    this.setState({ hdCaseNum, submitError: null }); // Reset error on success
    this.showAlert(hdCaseNum);
    this.props.onClose();
  }
  
  handleFormSubmitError = (error) => {
    this.setState({ submitError: error });
    this.showErrorAlert(error);
  }

  showAlert = (hdCaseNum) => {
    alert(`NCR created successfully. HD Case Number: ${hdCaseNum}`);
  }

  showErrorAlert = (error) => {
    alert(error);
  }

  render() {
    const { heading, title, onClose } = this.props;
    return (
      <Dialog
        className="ncr-dialog"
        title={title}
        onConfirm={onClose}       
        showFooter={false}
        onDismiss={onClose}>
        <div className="ncr-dialog-content">
          {heading && heading.length > 0 && (
            <p className="ncr-dialog-heading">{heading}</p>
          )}
          <div className='ncr-form'>
            <NCRForm 
              onFormSubmitSuccess={this.handleFormSubmitSuccess} 
              onFormSubmitError={this.handleFormSubmitError}
            />
          </div> 
        </div>
      </Dialog>
    );
  }
}

export default NCRDialog;