import {connect} from 'react-redux';
import {compose} from 'recompose';
import withCurrentUser from '../../auth/hoc/withCurrentUser';
import * as actions from '../actions';

// redux actions and data
export default compose(
  withCurrentUser,
  connect(
    ({timesheet}, {uiState = {}, currentUser}) => ({
      activeEntry: timesheet.activeEntry,
      activeRow: timesheet.activeRow,
      editRow: timesheet.editRow,
      uiState: {...timesheet.ui, ...uiState},
      timesheetUser: timesheet.timesheetUser || (currentUser && currentUser.id)
    }),
    actions
  )
);
