import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import {withHandlers} from 'recompose';
import classNames from 'classnames'
import Tooltip from 'rc-tooltip'
import { OPERATION_OUTLOOKS } from '../../constants'
import rowShape from './shapes/rowShape'

class OpOutlookSelection extends React.Component {
  state = {
    visible: false,
    isMenuOpen: false
  }


  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      this.selectRef &&
      this.selectRef.state.isOpen &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.selectRef.closeMenu()
    }
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setSelectRef = component => {
    this.selectRef = component
  }

  handleMenuOpen = () => {
    this.setState({
      visible: false,
      isMenuOpen: true
    })
  }

  handleMenuClose = () => {
    this.setState({isMenuOpen: false})
  }

  onVisibleChange = (visible) => {
    if (!this.state.isMenuOpen) {
      this.setState({visible})
    }    
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const {disabled} = this.props
    const cls = classNames('timesheet--opoutlookselection col-md-12 col-xs-9', {
      'opoutlookselection--disabled': disabled
    })
    const cls2 = classNames('opoutlook-title day-titles col-xs-3', {
      'opoutlook-title--disabled': disabled
    })
    return (
      <div>
        <div className={cls2}>Outlook</div>
        <div className={cls} ref={this.setWrapperRef}>
          {this.renderContent()}
        </div>
      </div>
    )
  }

  renderContent() {
    const {disabled, onChange, row} = this.props
    const opOutlook = row.Operation ? row.Operation.Outlook : 0
    const opOutlookText = OPERATION_OUTLOOKS.find(o => o.VALUE === opOutlook).STRING
    const opOutlookEmoji = OPERATION_OUTLOOKS.find(o => o.VALUE === opOutlook).EMOJI
    const content = <ReactSelect
      disabled={disabled}
      ref={this.setSelectRef}
      clearable={false}
      value={opOutlook}
      options={makeOptions(OPERATION_OUTLOOKS)}     
      onChange={onChange}
      onOpen={this.handleMenuOpen}
      onClose={this.handleMenuClose}
    />

    if (!disabled) {
      return (
        <Tooltip
          overlay={<div>{opOutlook}<span> </span><span role='img'>{String.fromCodePoint(opOutlookEmoji)}</span> - <span>{opOutlookText}</span></div>}    
          trigger='hover'            
          onVisibleChange={this.onVisibleChange}
          visible={this.state.visible}
          placement={'top'}>
          <span>{content}</span>
        </Tooltip>
      )   
    } else {
      return (
        content
      )
    }  
  }
}

const makeOptions = outlooks =>
  outlooks.map(p => ({
    label: p.VALUE + ' ' + String.fromCodePoint(p.EMOJI),
    value: p.VALUE
  }));

const container = withHandlers({
  onChange: ({onChange, rowIndex}) => newSelection => {
    onChange(rowIndex, newSelection && newSelection.value)
  }
})

OpOutlookSelection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  row: rowShape.isRequired
}

export default container(OpOutlookSelection)