import moment from 'moment';

export default {
  isWeekend(d) {
    var day;
    if (typeof d === 'string')
      // string, lets hope it parses
      day = new Date(d).getUTCDay();
    else if (d.toDate)
      // moment
      day = d.day();
    else throw new Error('Expected moment or string');
    return day === 0 || day === 6;
  },
  format(d, company) {
    if (!d) return '';
    return  moment(d).format('YYYY-MM-DD')
  },
  formatTime(d) {
    if (!d) return '';
    const hours = Math.floor(d);
    const mins = d - hours;
    const pad = d => (d < 10 ? '0' + d : d);
    return `${pad(hours)}:${pad(Math.round(mins * 60))}`;
  }
};
