import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import classNames from 'classnames';
import Alert from './Alert';
import ClearButton from './ClearButton';
import Glyphicon from './Glyphicon';
import {stopPropagation} from '../../constants';
import appearingTransition from '../../shared/hoc/appearingTransition';
import isIOS from '../../utils/isIOS';
import Tooltip from 'rc-tooltip'

// avoid letting the form submit... we'll never want to do a "real" post!
const preventDefault = e => e.preventDefault();

const Dialog = props => {
  const useColor = props.size !== 'small';

  const onConfirmClick = e => {
    e.preventDefault();
    props.onConfirm();
  };
  const onCancelClick = e => {
    e.preventDefault();
    props.onDismiss();
  };
  const onSelectAllClick = e => {
    e.preventDefault();
    props.onSelectAll();
  };
  const onClearAllClick = e => {
    e.preventDefault();
    props.onClearAll();
  };
  const onExtraButtonClick = e => {
    e.preventDefault();
    props.extraButton.onClick();
  };

  const onAfterOpen = e => {
    props.onOpen && props.onOpen(e);
  };

  const renderSubmitButton = () => {
    const content = (
      <ClearButton
        type="submit"
        className="save-submit"
        disabled={props.enableConfirm === false || props.submitDisabled}
        onClick={onConfirmClick}>
        <Glyphicon glyph="check" />
        &nbsp;{props.confirmLabel}
      </ClearButton>
    )
  
    if (props.submitDisabled) {
      return (
        <Tooltip
          overlay={
            <span>Please enter a note</span>
          }
        >
          <div>{content}</div>
        </Tooltip>
      )
    }
  
    return (content)
  }

  const renderSelectAllButton = () => {
    const content = (
      <ClearButton
        type="submit"
        className="select-all-button"
        disabled={props.enableSelectAll === false}
        onClick={onSelectAllClick}>
        <Glyphicon glyph="check" />
        &nbsp;{props.selectAllLabel}
      </ClearButton>
    )
    return (content)
  }

  const renderClearAllButton = () => {
    const content = (
      <ClearButton
        type="submit"
        className="clear-all-button"
        disabled={props.enableClearAll === false}
        onClick={onClearAllClick}>                
        <Glyphicon glyph="remove" />
        &nbsp;{props.clearAllLabel}
      </ClearButton>
    )
    return (content)
  }

  return (
    <ReactModal
      isOpen={true}
      onAfterOpen={onAfterOpen}
      //onRequestClose={props.onDismiss}
      contentLabel={props.title}
      showSelectAll={props.showSelectAll}
      showClearAll={props.showClearAll}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          padding: 0,
          ...contentStyle(props),
          transition: 'transform .25s',
          transform: props.isAppearing ? 'translateY(-80vh)' : 'translateY(0)'
        },
        overlay: {
          // should have a transition, ideally...
          backgroundColor: 'rgba(0, 0, 0, .7)',
          zIndex: 15,
          ...overlayStyle(props)
        }
      }}>
      <Panel
        className={classNames(
          'panel',
          {'panel-default': !useColor},
          props.className
        )}
        onSubmit={preventDefault}
        onClick={stopPropagation}
        size={props.size}>
        {props.title && (
          <header
            className={classNames('panel-heading', {'bg-primary': useColor})}>            
            <h4 className="panel-title col-md-10">{props.title}</h4>
            <button type="button" className="modal-button btn-close col-md-2" aria-label="Close" onClick={onCancelClick}>X</button>
          </header>
        )}
        <div className="panel-body">
          {props.showClearAll && (
            renderClearAllButton(props)
          )}
          {props.showSelectAll && (
            renderSelectAllButton(props)
          )}
          {props.children}
          <Alert error={props.error} />
        </div>
        {props.showFooter && (
          <footer className="panel-footer text-right">
            {props.extraButton && (
              <ClearButton
                type="button"
                className="extra"
                onClick={onExtraButtonClick}>
                <Glyphicon glyph={props.extraButton.icon} />
                &nbsp;{props.extraButton.label}
              </ClearButton>
            )}
            {props.showConfirm && (
              renderSubmitButton(props)
            )}
            {props.showCancel && (
              <ClearButton
                type="button"
                className="cancel"
                onClick={onCancelClick}
                disabled={props.cancelDisabled}>                
                <Glyphicon glyph="remove" />
                &nbsp;{props.cancelLabel}
              </ClearButton>
            )}
          </footer>
        )}
      </Panel>
    </ReactModal>
  );
};

const contentStyle = ({size}) => {
  // we need this to auto size vertically (to match the content)
  if (size === 'small') {
    return {
      position: 'absolute',
      margin: '10vh auto',
      width: '40vw',
      minWidth: '300px',
      maxWidth: '450px',
      overflow: 'visible',
      bottom: 'auto'
    };
  }
  return {
    position: 'static',
    margin: '30px auto',
    width: '90vw',
    maxWidth: '900px',
    border: 'none'
  };
};

const overlayStyle = ({size}) => {
  if (size === 'small') {
    return {
      // should NOT happen, because we only use that with small enough dialog
      // but "just in case" they have a very short screen
      overflow: 'auto'
    };
  }
  return {};
};

const Panel = styled.form`
  //position: absolute;
  //top: 0; bottom: 0; right: 0; left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  // leave room for header
  // this is a bit of a hack and it wont resize if they change the phone orientation
  // max-height: ${() => window.innerHeight - 50}px;
  // on iOS, need to leave extra room for the navigation controls which are included in the vh calculation
  // https://caniuse.com/#feat=viewport-units
  max-height: calc(100vh - ${isIOS() ? '120px' : '50px'});
	
  // adjust styles a bit for smaller size dialogs (which are usually using a vertical form)
  ${({size}) =>
    size === 'small' &&
    `
    .form-group {
      margin: 0;
    }
    .alert {
      margin-top: 15px;
      margin-bottom: 0;
    }
  `}

  .panel-body {
    flex-grow: 1;
    overflow: ${({size}) => (size === 'small' ? 'visible' : 'auto')};
  }
`;

Dialog.propTypes = {
  // when dialog is closed or cancelled
  onDismiss: PropTypes.func.isRequired,
  // selects all valid time records
  onSelectAll: PropTypes.func,
  // clears valid time record selection
  onClearAll: PropTypes.func,
  // when OK button is clicked
  onConfirm: PropTypes.func,
  // set to false to disable the OK button (i.e, when validation fails)
  enableConfirm: PropTypes.bool,
  // label for the OK button
  confirmLabel: PropTypes.string,
  // label for the Cancel button (defaults to Cancel)
  cancelLabel: PropTypes.string,
  // label for the Select All button (defaults to Cancel)
  selectAllLabel: PropTypes.string,
  // label for the Clear All button (defaults to Cancel)
  clearAllLabel: PropTypes.string,
  // dialog title
  title: PropTypes.string.isRequired,
  // set large / small size for dialog
  size: PropTypes.string,
  showSelectAll: PropTypes.bool,
  showClearAll: PropTypes.bool,
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  showFooter: PropTypes.bool,
  // optional error message to be displayed as alert in the dialog
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  // optional additional button to display
  extraButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired
  })
};
Dialog.defaultProps = {
  showCancel: true,
  showConfirm: true,
  showFooter: true,
  selectAllLabel: 'Select All',
  clearAllLabel: 'Clear All',
  cancelLabel: 'Cancel',
  confirmLabel: 'OK'
};

export default appearingTransition(Dialog);
