import {graphql} from 'react-apollo';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import {SAVE_TIME_MUTATION} from '../../queries';
import {compose, withHandlers} from 'recompose';
import {hasTimeOnDate} from '../../api/methods';
import {buildTsInput} from '../../../shared/utils/queries';

// Saving Time In Lieu data
//
const saveInLieu = graphql(SAVE_TIME_MUTATION, {
  props: ({
    ownProps: {
      setError,
      updateTimesheetData,
      setSavePending,
      timesheet,
      timesheetSource,
      activeEntry,
      activeRow
    },
    mutate
  }) => ({
    saveInLieu(date, previousSelection, newSelection) {
      if ((previousSelection || '') === (newSelection || '')) {
        return;
      }
      if (newSelection && !hasTimeOnDate(timesheet, date)) {
        setError(
          'You cannot select an In Lieu type until after you have entered hours on this day.'
        );
        return;
      }
      const sourceEntry = find(
        timesheet.inLieuRow.entries,
        e => e.Date === date
      );
      const entry = {
        id: sourceEntry.id,
        Date: date,
        InLieuType: newSelection
      };
      setSavePending({
        [entry.id]: true
      });
      return mutate({
        variables: {
          ...buildTsInput(timesheet),
          row: {},
          entry
        },

        optimisticResponse: {
          __typename: 'Mutation',
          // __optimistic: true,
          saveTime: {
            __typename: 'TimesheetEntry',
            OvernightCompensation: false,
            TimeAllocation: 0,
            ...sourceEntry,
            ...entry
          }
        },

        update: (store, {data: {saveTime}}) => {
          const entryIndex = findIndex(
            timesheetSource.timeInLieu,
            e => e.Date === date
          );
          updateTimesheetData(
            {
              timeInLieu: {
                [entryIndex]: {
                  // this could include a new id, so we need to do an explicit update
                  $merge: saveTime
                }
              }
            },
            store
          );
        }
      })
        .catch(err => {
          setError(err);
        })
        .then(result => {
          if (sourceEntry) setSavePending({[sourceEntry.id]: false});
        });
    }
  })
});

const clearInLieu = withHandlers({
  clearInLieu: ({saveInLieu, timesheet}) => () => {
    timesheet.inLieuRow.entries.forEach(entry =>
      saveInLieu(entry.Date, entry.Selection, null)
    );
  }
});

export default compose(saveInLieu, clearInLieu);
