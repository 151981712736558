import {graphql} from 'react-apollo';
import pick from 'lodash/pick';

import {APPROVAL_MODE} from '../../../constants';
import {SAVE_TIME_MUTATION} from '../../queries';
import buildRowInput from './_buildRowInput';

// saveTime mutation
// Used for:
//  - updating the time allocation on a single entry (invoked from TimesheetEntries)
//  - updating the overnight comp flag on a single entry (invoked from TimesheetDetail)
//
// Parameters are all optional:
//  - hours: new time allocation (if specified).  0 to delete the entry
//  - overnightCompensation: new overnight compensation flag value for this entry
//  - rowIndex, entryIndex: identify the entry
export default graphql(SAVE_TIME_MUTATION, {
  props: ({
    ownProps: {setError, setSuccess, updateTimesheetData, setSavePending, timesheet, mode},
    mutate
  }) => ({
    updateTime({
      hours,
      overnightCompensation,
      rowIndex,
      entryIndex,
      detailIndex = 0
    }) {      
      // console.log('Updating time', { timesheet, entryIndex, rowIndex, hours, overnightCompensation })
      const row = timesheet.rows[rowIndex];
      const entry = {
        // note the id could be a transient id.  We'll get the real id back
        ...pick(row.entries[entryIndex], [
          'OvernightCompensation',
          'Date',
          'id',
        ]),
        ...pick(row.entries[entryIndex].Detail[detailIndex], [
          'TimeAllocation',
          'id'
        ])
      };
      if (hours === 0 && entry.TimeAllocation === 0) {
        // case where they are setting 0 in a non existent entry
        // this happens if they type a 0 in a new cell, or backspace in a new cell
        return;
      }
      if (hours !== undefined) entry.TimeAllocation = hours;
      if (overnightCompensation !== undefined) {
        entry.OvernightCompensation = overnightCompensation;
        // we need to make sure we save a 0-hour entry, otherwise we'll just have the header
        // and it won't work in Epicor
        if (!entry.TimeAllocation || entry.TimeAllocation === 0.01) {
          entry.TimeAllocation = 0.01;
          entry.OvernightCompensationOff = overnightCompensation
        }              
      }
      // don't let them change anything on that date while we do this, or it could mess up the header records in Epicor
      setSavePending({
        [entry.Date]: true,
        [entry.id]: true
      });
      return mutate({
        variables: {
          ...buildRowInput(timesheet, row),
          entry,
          submit: mode === APPROVAL_MODE
        },
        optimisticResponse: {
          __typename: 'Mutation',
          // __optimistic: true,
          saveTime: {
            __typename: 'TimesheetEntry',
            InLieuType: null,
            ...row.entries[entryIndex],
            ...entry
          }
        },

        update: (store, {data: {/* __optimistic,  */ saveTime}}) => {
          // we should update the entry
          // if(!saveTime.__optimistic)
          //   saveTime.__optimistic = false
          const entryUpdates = pick(saveTime, [
            'id',
            'InLieuType',
            'OvernightCompensation',
            'PayType',
            'Submitted',
            'LaborHed'
          ]);
          const detailUpdates = pick(saveTime, [
            'id', 
            'TimeAllocation',
            'ClockInTime',
            'ClockOutTime',
            'ClockInDate',
            'ClockOutDate'
          ]);
          detailUpdates.__typename = 'TimesheetEntryDetail';
          const detailOp = row.entries[entryIndex].Detail[detailIndex]
            ? {
                [detailIndex]: {$merge: detailUpdates}
              }
            : {
                $push: [
                  {
                    ClockInTime: 0,
                    ClockOutTime: 0,
                    ClockInDate: '',
                    ClockOutDate: '',
                    Narrative: '',
                    ...detailUpdates
                  }
                ]
              };
          updateTimesheetData(
            {
              rows: {
                [rowIndex]: {
                  entries: {
                    [entryIndex]: {
                      // this could include a new id, so we need to do an explicit update
                      $merge: entryUpdates,
                      Detail: detailOp
                    }
                  }
                }
              }
            },
            store
          );
        }
      })
        .catch(err => {
          try {
            const json = JSON.parse(err.graphQLErrors[0].message.substr(5))
            setError(json.ErrorMessage)
          } catch(e) {
            setError(err);
          }                  
        })
        .then(
          () => setSuccess('Timesheet entry has been updated.')
        )
        .then(result => {
          // unlock cell and column          
          setSavePending({[entry.id]: false, [entry.Date]: false});
        });
    }
  })
});
