import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function CalendarHeader({toggleCalendar, current}) {
  var formattedDate = moment(current).format('MMM Do, YYYY');
  return (
    <div className="timesheet-calendar-header" onClick={toggleCalendar}>
      <i className="fa fa-calendar" aria-hidden="true" />Week of {formattedDate}
    </div>
  );
}

CalendarHeader.propTypes = {
  current: PropTypes.string,
  toggleCalendar: PropTypes.func.isRequired
};

export default CalendarHeader;
