import {APPROVAL_MODE} from '../../constants';

/**
 * Compute locked reason for the specified entry.
 */
export default function getEntryLockedReason(
  entry,
  // either daily total, for views that lock by column (timesheet summary view),
  // or entry again, for the detail view
  {Posted, Approved, Submitted, RoadPayInEligible},
  // the row
  {closed},
  mode,
  isSummaryView,
  isApprovalDetailView,
  contract,
  isOpComplete
) {
  if (contract && contract.Valid === false) {
    return 'DAY_NOT_CONTRACTED'
  }
  if (Posted) {
    return 'POSTED';
  }
  if (Approved && isOpComplete) {
    return 'APPROVED_OP_COMPLETE'
  }
  if (Approved) {
    return 'APPROVED';
  }
  if (RoadPayInEligible) {
    return 'ROADPAY';
  }
  if (Submitted && mode !== APPROVAL_MODE) {
    return 'SUBMITTED';
  }
  if (!Submitted && mode === APPROVAL_MODE) {
    return 'NOT SUBMITTED';
  }
  if (closed) {
    return 'CLOSED';
  }
  if (isSummaryView && entry.Detail && entry.Detail.length > 1) {
    return 'MES';
  }
}
