import {createAction} from 'redux-actions';
import Authentication from '../utils/Authentication';

import * as types from './types';

export const toggleMenu = e => {
  e.stopPropagation();
  return {
    type: types.TOGGLE_MENU
  };
};
export const signOut = (history, apolloClient, reason = '') => {
  Authentication.logout();
  history.replace('/logout');
  setTimeout(() => {
    // in timeout so that the queries don't try to re-run right away
    apolloClient.resetStore();
  }, 300);
  return {
    type: types.AUTH_SIGNOUT,
    payload: reason
  };
};

export const signIn = createAction(types.AUTH_SIGNIN);
