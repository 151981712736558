export default function getDayOfWeek(d) {
    var date = new Date(d);
    var weekday = new Array(7);
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";
    weekday[7] =  "Sun";

    return weekday[date.getDay() + 1];
}