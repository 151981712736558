import React from 'react';
import Tooltip from 'rc-tooltip';
import ProjectDisplay from './ProjectDisplay';

const ProjectOverlay = ({onClick, row}) => {
  const display = <ProjectDisplay onClick={onClick} row={row} />;
  if (row.Job && row.Job.Closed) {
    return (
      <Tooltip
        overlay={
          <span>This job has been closed and can't be edited or recalled</span>
        }
        placement="top">
        {display}
      </Tooltip>
    );
  }
  return display;
};

export default ProjectOverlay;
