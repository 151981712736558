import React from 'react';
import styled from 'styled-components';

import Clone from './Clone';
import ApprovalRecall from '../../approval/components/Recall';
import TimesheetRecall from './Recall';
import Submit from './Submit';
import UserSelection from './UserSelection';

import hoc from '../hoc/timesheetControlProps';

// Timesheet controls
const TimesheetControlBar = props => {
  const {
    currentUser,
    timesheet,
    recallTimesheetApproval
  } = props;

  const {totals} = timesheet;

  // if any time is submitted, we can unsubmit
  const isUnsubmittable = totals.some(x => !x.AllPosted && x.Submitted);
  const isUnapprovable = totals.some(
    // if ANY time is approved, we can unapprove
    x => !x.AllPosted && x.Approved && x.Submitted
  );
  const isSameUser =
    currentUser.Company === timesheet.Company &&
    currentUser.EmployeeNum === timesheet.EmployeeNum;

  return (
    <div className="timesheet-desktop-controls">
      <UserSelection {...props} />
      <Clone {...props} disabled={!props.isCloneable} />
      <Container>
        {
          //If this person can approve (not logged in user), display recall button with recall to approver/employee
          //If this person cannot approve (they have themself selected), they can recall only their own timesheet.
          (props.canApprove ? 
            <ApprovalRecall 
              {...props} 
              disabled={!(isUnsubmittable || isUnapprovable) || isSameUser} 
              isUnsubmittable={isUnsubmittable}
              isUnapprovable={isUnapprovable}
              onRecall={recallTimesheetApproval}
            />
            :
            <TimesheetRecall {...props} disabled={!props.isRecallable} />)
        }        
        <Submit {...props} disabled={!props.isSubmittable} />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export default hoc(TimesheetControlBar);
