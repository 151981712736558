import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';

// sort an array of records using a specification of the form { SortKey: 1|-1 }
// if multiple keys are provided they will be used in the order given
export default function sortListData(records, sort) {
  return reduce(
    sort,
    (result, sortOrder, sortKey) => {
      const temp = sortBy(result, [sortKey]);
      if (sortOrder === -1) temp.reverse();
      return temp;
    },
    records
  );
}
