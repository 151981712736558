import find from 'lodash/find';

export function getTimesheetTimeTrackingMethod(timesheet) {
  return {
    usePercentage: false
  };
}

// check if the timesheet has an entry for the given date
export function hasTimeOnDate(timesheet, date) {
  return !!find(timesheet.rows, row =>
    find(row.entries, e => e.Date === date && e.TimeAllocation > 0)
  );
}
