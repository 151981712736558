import React from 'react';
import {LogoutButton, LoginHeader} from '../../shared/components/styles';
import Logo from './bedrock_wordmark.png';

const SignOutPage = ({reason}) => (
  <form className="form-signin logout">
    <LoginHeader className="form-signin-heading">
      <img alt="TAIT Logo" className="tait-logo" src={Logo} />
    </LoginHeader>
    <div className="login-wrap logout-wrap">
      <h4>You have been logged out.</h4>

      {reason && <p className="error">{reason}</p>}
      <LogoutButton className="btn btn-lg btn-login btn-block" href="/login">
        Sign In Again
      </LogoutButton>
    </div>
  </form>
);

export default SignOutPage;
