import React from 'react';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';

import './App.css';
import SignInPage from './auth/containers/SignInPageContainer';
import SignOutPage from './auth/containers/SignOutPageContainer';
import MainPage from './app/pages/MainPage';
import ResetPasswordPage from './passwordreset/containers/ResetPasswordContainer'

/**
 * Top level routes for the application.
 *
 * appPage is a decorator that includes a getCurrentUser query to pass the user info
 * (and check the login validity)
 * If they are not currently logged in, they will be immediately redirected to the login screen.
 */
export default () => (
  <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
    <Switch>
      <Route path="/resetpassword/:resetToken" component={ResetPasswordPage} />
      <Route path="/login" component={SignInPage} />
      <Route path="/logout" component={SignOutPage} />
      <Redirect from="/" exact to="/login" />
      <Route path="/" component={MainPage} />      
    </Switch>
  </BrowserRouter>
);
