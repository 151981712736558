import pick from 'lodash/pick'
import fromPairs from 'lodash/fromPairs'
import {UPDATE_OP_OUTLOOK} from '../../queries';
import {compose} from 'recompose';
import {graphql}  from 'react-apollo';
import buildRowInput from './_buildRowInput'
import {buildTsInput} from '../../../shared/utils/queries'
// update outlook on related op
//
const updateOpOutlook = graphql(UPDATE_OP_OUTLOOK, {
  props: ({
    ownProps: {
      setError,
      setSuccess,
      updateTimesheetData,
      setSavePending,
      timesheet,
      activeRow
    },
    mutate
  }) => ({
    updateOpOutlook(rowIndex, newSelection) {
      let row = timesheet.rows[rowIndex]
      if (!row) row = timesheet.rows[activeRow]
      setSavePending(fromPairs(row.entries.map(e => [e.id, true])));
      return mutate({
        variables: {
          ...buildTsInput(timesheet),
          ...buildRowInput(timesheet, row),
          opOutlook: newSelection
        },

        update: (store, {data: {updateOpOutlook}}) => {
          const opOutlookUpdates = pick(updateOpOutlook, [
            'Outlook'
          ])
          updateTimesheetData(
            {
              rows: {
                [rowIndex]: {
                  Operation: {
                    $merge: opOutlookUpdates
                  }                  
                }
              }
            },
            store
          );
        }
      })
        .catch(err => {
          setError(err);
        })
        .then(
          () => setSuccess('Outlook has been updated.')
        )
        .then(result => {
          setSavePending(fromPairs(row.entries.map(e => [e.id, false])));
        });
    }
  })
});

export default compose(updateOpOutlook);
