import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TotalCell from './TotalCell';
import {getTimesheetTimeTrackingMethod} from '../api/methods';

/**
 * <TimesheetTotals />
 * Total tally at bottom of the timesheet rows
 */
function TimesheetTotals(
  {timesheet, submitTimesheet, toggleMobileDashboard},
  context
) {
  function renderTotalCell(tot) {
    var isPercent = getTimesheetTimeTrackingMethod(timesheet).usePercentage;
    return (
      <TotalCell
        key={tot.Date}
        date={tot.Date}
        value={tot.Total}
        usePercentage={isPercent}
      />
    );
  }

  var cls = 'timesheet-totals row';

  const weeklyTotalsClass = classNames('weekly-totals col-xs-12', {
    'col-md-3': (timesheet.Company === '100' || timesheet.Company === '700' || timesheet.Company === '770'),
    'col-md-4': (timesheet.Company !== '100' && timesheet.Company !== '700' && timesheet.Company !== '770')
  })
  const timesheetDaysClass = classNames('timesheet-days col-xs-12', {
    'col-md-6': (timesheet.Company === '100' || timesheet.Company === '700' || timesheet.Company === '770'),
    'col-md-7': (timesheet.Company !== '100' && timesheet.Company !== '700' && timesheet.Company !== '770')
  })

  return (
    <div className={cls}>
      <div className={weeklyTotalsClass}>
        <i
          className="fa fa-2x fa-th-large mobile-menu-toggle pull-left"
          aria-hidden="true"
          onClick={toggleMobileDashboard}
        />
        <div className="project-selection" />
      </div>
      <div className={timesheetDaysClass}>
        {timesheet.totals.map(renderTotalCell)}
      </div>
      <div className="col-md-1 grand-total">
        <span>Total</span>
        <span className="timesheet-totals--grand-total">
          {timesheet.grandTotal}
        </span>
      </div>
    </div>
  );
}
TimesheetTotals.propTypes = {
  timesheet: PropTypes.object,
  toggleMobileDashboard: PropTypes.func.isRequired,
  submitTimesheet: PropTypes.func.isRequired
};

export default TimesheetTotals;
