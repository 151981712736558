import React, {Component} from 'react';

import hoc from '../hoc/employeeList';

import ApprovalEmployeeItem from './ApprovalEmployeeItem';
import Spinner from '../../shared/components/Spinner';
import ReactSelect from '../../shared/components/inputs/ReactSelect';

export class ApprovalEmployeeList extends Component {
  componentDidMount() {
    const {expandedUsers, timesheetUser, toggleUser} = this.props;

    const noSelections =
      expandedUsers.undefined === false ||
      Object.keys(expandedUsers).length === 0;

    if (noSelections && timesheetUser) {
      toggleUser(timesheetUser);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {expandedUsers, timesheetUser, toggleUser} = this.props;

    const noSelections =
      expandedUsers.undefined === false || !Object.keys(expandedUsers).length;
    const noUser = typeof timesheetUser === 'undefined';

    if (noSelections && noUser && nextProps.timesheetUser) {
      toggleUser(nextProps.timesheetUser);
    }
  }

  render() {
    const {
      date,
      employeeList,
      expandedUsers,
      isLoading,
      nameFilter,
      setNameFilter,
      setTimesheetUser,
      timesheetUser,
      toggleUser,
      view,
      supervisorList,
      setSupervisorSelect,
      supervisorSelect,
      currentUser,
      setExpandedRows
    } = this.props;

    return (
      <div className="approval-employee-list has-spinner">
        {isLoading && <Spinner />}
        <div className="placeholder-heading" />
        <div className="approval-controls">
          <h2>Select Supervisor:</h2>
          <ReactSelect 
            clearable={false}
            options={makeOptions(supervisorList)}
            onChange={setSupervisorSelect}
            value={supervisorSelect}            
          />
          <h2>Search Employees:</h2>
          <input
            type="text"
            placeholder="Type to filter..."
            onChange={setNameFilter}
            value={nameFilter}
          />
        </div>
        <ul className="list-scroll">
          {employeeList.map(emp => (
            <ApprovalEmployeeItem
              key={emp.id}
              employee={emp}
              expanded={!!expandedUsers[emp.id]}
              isSelectedUser={timesheetUser === emp.id}
              selectedDate={date}
              toggleUser={toggleUser}
              view={view}     
              setTimesheetUser={setTimesheetUser}
              currentUser={currentUser}
              setExpandedRows={setExpandedRows}
            />
          ))}
        </ul>
        <div className="fill-space" />
      </div>
    );
  }
}

const makeOptions = users => {
  //Todo Fix this
  if (users){
    return users.map(u => ({
      label: u.Name,
      value: u.id
    }))
  } else {
    return []
  }
}

export default hoc(ApprovalEmployeeList);
