import React from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip'
import className from 'classnames'

import roundTime from '../api/roundTime';

/**
 * Cell used for the grand totals at the bottom and for the sub totals (Chargeable / Non Chargeable rows)
 */
const TotalCell = ({date, value, usePercentage, includeDayOfWeek = true, timeOff = false, mobile=false}) => {
  var time = value * (usePercentage ? 10 : 1);
  var cls = 'timesheet-cell timesheet-cell--total total-days';
  if (usePercentage) {
    if (time < 100) {
      cls += ' under';
    } else if (time > 100) {
      cls += ' over';
    } else {
      cls += ' equal';
    }
  } else {
    if (time <= 0) {
      cls += ' under';
    } else if (time > 24) {
      cls += ' over';
    } else {
      cls += ' equal';
    }
  }

  let content = (
    <span className="total-time">
          {roundTime(time)}
          {usePercentage ? '%' : ''}
        </span>
  )

  if (timeOff) {
    content = (
      <Tooltip overlay={<span>{'Sum of Vacation and PTO'}</span>} placement="top">
        {content}
      </Tooltip>
    )
  }

  return (    
    <div className={className(cls, {
      'timesheet-cell--total--mobile': mobile
    })

      }>
      {includeDayOfWeek &&
        date && (
          <span className="day-of-week">{moment(date).format('ddd')}</span>
        )}
      {content}
    </div>    
  );
};

export default TotalCell;
