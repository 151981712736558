import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Dialog from '../Dialog';
import TimeDialogCell from './TimeDialogCell';

class TimeDialog extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isRecall: PropTypes.bool.isRequired,
    isApprove: PropTypes.bool.isRequired,
    totals: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
  };

  static state = {
    dates: {}
  };

  handleSelectionChange(total) {
    const {dates = {}} = this.state || {};
    const {Date: date, Total: amount} = total;
    const key = date.format('YYYY-MM-DD');

    if (typeof dates[key] === 'undefined') {
      dates[key] = amount;
    } else {
      delete dates[key];
    }

    this.setState({dates});
  }

  SelectAll(totals) {
    //Select Each Record in totals
    var count = 0;
    var today = new Date();
    var today = today.getFullYear() + '-' + ('0'+(today.getMonth() +1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    var isRecalltoApprover = this._reactInternalFiber.pendingProps.isRecalltoApprover;
    var isRecall = this._reactInternalFiber.pendingProps.isRecall;
    var isApprove = this._reactInternalFiber.pendingProps.isApprove;
    const {dates = {}} = this.state || {};
    totals.forEach(total => {
      const {Date: date, Total: amount} = total;
      const key = date.format('YYYY-MM-DD');
      var recDate = total.Date._d.getFullYear() + '-'+ ('0'+(total.Date._d.getMonth() +1)).slice(-2) + '-'+ ('0' + total.Date._d.getDate()).slice(-2);
      var isSelected = this._reactInternalFiber.stateNode._reactInternalFiber.child.pendingProps.children.props.children[1].props.children[0][count].props.checked;

      if (isApprove && !isSelected && !total.Posted && !total.Approved && total.Submitted){// && total.Total > 0){//!isSelected && recDate <= today && !total.Submitted) {
        if (typeof dates[key] === 'undefined') {
          dates[key] = amount;
        } else {
          delete dates[key];
        }
      }
      if (!isRecalltoApprover && isRecall && !isSelected && !total.Posted && !total.Approved && total.Submitted){// && total.Total > 0){//!isSelected && recDate <= today && !total.Submitted) {
        if (typeof dates[key] === 'undefined') {
          dates[key] = amount;
        } else {
          delete dates[key];
        }
      }      
      if (isRecalltoApprover && isRecall && !isSelected && !total.Posted && total.Approved && total.Submitted){// && total.Total > 0){//!isSelected && recDate <= today && !total.Submitted) {
        if (typeof dates[key] === 'undefined') {
          dates[key] = amount;
        } else {
          delete dates[key];
        }
      }
      else if (!isRecall && !isSelected && recDate <= today && !total.Submitted){//} && total.Total > 0) {
        if (typeof dates[key] === 'undefined') {
          dates[key] = amount;
        } else {
          delete dates[key];
        }
      }
      count++;
    });
    this.setState({dates});
  }

  ClearAll(totals) {
    //UnSelect Each Record in totals
    var today = new Date();
    var today = today.getFullYear() + '-' + ('0'+(today.getMonth() +1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    var count = 0;
    totals.forEach(total => {
      var isSelected = this._reactInternalFiber.stateNode._reactInternalFiber.child.pendingProps.children.props.children[1].props.children[0][count].props.checked;
      if (isSelected){
        this.handleSelectionChange(total);
      }
      count++;
    }
    );
  }

  render() {
    const {heading, onClose, onSave, isRecall, isApprove, isRecalltoApprover, totals, title} = this.props;

    const {dates = {}} = this.state || {};

    const numSelected = Object.keys(dates).length;
    const amount = Object.keys(dates).reduce((acc, key) => acc + dates[key], 0);

    return (
      <Dialog
        className="time-dialog"
        enableConfirm={numSelected > 0}
        showSelectAll = {true}
        showClearAll = {true}
        onConfirm={() => onSave(Object.keys(dates))}
        onDismiss={onClose}
        onSelectAll= {() => this.SelectAll(totals)}        
        onClearAll= {() => this.ClearAll(totals)}
        isRecall={isRecall}
        isRecalltoApprover={isRecalltoApprover}
        isApprove={isApprove}
        title={title}>
        <div className="time-dialog-content">
          {heading &&
            heading.length > 0 && (
              <p className="time-dialog-heading">{heading}</p>
            )}
          <div className="time-dialog-cells col-md-12">
            {totals.map((total, i) => (
              <TimeDialogCell
                amount={total.Total}
                approved={total.Approved}
                checked={
                  typeof dates[total.Date.format('YYYY-MM-DD')] !== 'undefined'
                }
                date={total.Date}
                disabled={total.Disabled}
                key={i}
                message={total.Message}
                onChange={e => this.handleSelectionChange(total)}
                posted={total.Posted}
                readOnly={total.Date > Date.now() || total.Total > 24}
                submitted={total.Submitted}
              />
            ))}
            <div className="time-dialog-cell time-dialog-totals">
              <p className="time-dialog-cell-title">
                Total Selected<br />Hours
              </p>
              <div className="time-dialog-cell-content">{amount.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default TimeDialog;
