import {lifecycle} from 'recompose';

export default lifecycle({
  componentDidUpdate() {
    const {user, date} = this.props.match.params;
    if (this.props.employeeList && !!user) {
      const match = this.props.employeeList.find(e => e.id === user);
      if (!match || !match.Timesheets.find(x => x.PeriodStartDate === date)) {
        // the timesheet that is currently selected is invalid for the current filter state, so go back to the
        // default view
        this.props.history.push('/approval');
      }
    }
  }
});
