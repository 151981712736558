import {graphql} from 'react-apollo';

import {buildTsInput} from '../../../shared/utils/queries';
import {APPROVE_TIMESHEET_MUTATION} from '../../queries';

export default graphql(APPROVE_TIMESHEET_MUTATION, {
  props: ({
    ownProps: {
      timesheet,
      timesheetUser,
      currentUser,
      setLoading,
      setError,
      setSuccess,
      clearLoading
    },
    mutate
  }) => ({
    approveTimesheet(selectedDates) {
      const dates = timesheet.totals
        .filter(x => selectedDates.includes(x.Date.format('YYYY-MM-DD')))
        .map(x => x.Date.format('YYYY-MM-DD'));
      const userRec = currentUser.UsersManaged.find(
        u => u.id === timesheetUser
      );
      if (!userRec) {
        return setError('Invalid timesheet user');
      }
      if (!userRec.ApproverId) {
        return setError('User is missing approver id');
      }
      if (!dates.length) {
        return setError('There are no days which can be approved.');
      }

      setLoading();

      return mutate({
        variables: {
          ...buildTsInput(timesheet),
          dates,
          approverId: userRec.ApproverId
        }
      })
        .then(
          () => {
            setSuccess('Timesheet has been approved');
          },
          e => setError(e)
        )
        .then(clearLoading);
    }
  })
});
