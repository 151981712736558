import React from 'react';
import styled from 'styled-components';

const Single = ({label, onClick, className}) => (
  <span onClick={onClick} className={className}>
    {label}
    <i className="fa fa-chevron-right" />
  </span>
);

const ProjectDisplay = props => {
  const {onClick, row} = props;

  return (
    <Style {...props}>
      {row.Project && <Single label={row.Project.label} onClick={onClick} />}
      {row.Job && <Single label={row.Job.label} onClick={onClick} />}
      {row.Operation && (
        <Single label={row.Operation.label} onClick={onClick} />
      )}
      {row.ReworkCode && (
        <Single label={row.ReworkCode.label} onClick={onClick} className="rework-code" />
      )}
      {row.IndirectCode && (
        <Single label={row.IndirectCode.label} onClick={onClick} />
      )}
    </Style>
  );
};

const Style = styled.div`
  align-items: center;
  min-height: 40px;

  @media (max-width: 991px) {
    width: calc(100% - 48px);
  }

  span {
    display: inline;
    i {
      padding: 0 4px;
    }
  }

  span.rework-code {
    color: orangered;
    font-weight: bold;
  }

  span:last-child {
    i {
      display: none;
    }
  }
`;

export default ProjectDisplay;