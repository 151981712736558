// GraphQL query component for the ApprovalEmployeeList

import {graphql, compose} from 'react-apollo';

import {APPROVAL_USERS_QUERY} from '../../queries';
import errorAlert from '../../../shared/hoc/errorAlert';

const query = APPROVAL_USERS_QUERY;

export default compose(
  graphql(query, {
    options: (ownProps) => ({
      fetchPolicy: 'network-only',
      variables: {
        supervisorSelection: ownProps.supervisorSelect
      }
    }),
    props: ({data}) => {
      return {
        isLoading: data.loading,
        error: data.error,
        employeeList: data.timeForApproval || []
      };
    }
  }),
  errorAlert
);
