// HOC to retrieve the current timesheet data, based on the date (from route)

import {graphql, compose, withApollo} from 'react-apollo';
import Spinner from '../../shared/components/Spinner';
import {TIMESHEET_DETAIL_QUERY} from '../queries';
import calculateTimesheetValues from '../api/calculateTimesheetValues';
import timesheetActions from './timesheetActions';
import {withHandlers, branch, renderComponent} from 'recompose';
import update from 'immutability-helper';

const query = TIMESHEET_DETAIL_QUERY;
export default compose(
  graphql(query, {
    options: {
      fetchPolicy: 'network-only'
    },
    props: ({data}) => {
      // console.log('Graphql, got data', data);
      return {
        loading: data.loading,
        error: data.error,
        timesheetSource: data.timesheet,
        timesheet: data.timesheet && calculateTimesheetValues(data.timesheet)
      };
    }
  }),
  // only load once we have data
  // this is different from checking for "loading", because when doing an updated query
  // (for a different week) we'll still be rendering with the old timesheet data (so the transition works)
  branch(({timesheet}) => !timesheet, renderComponent(Spinner)),
  withApollo,
  withHandlers({
    updateTimesheetData: ({client, date, timesheetUser}) => (
      updates,
      store = client
    ) => {
      const timesheetSource = store.readQuery({
        query,
        variables: {date, timesheetUser}
      }).timesheet;
      const updated = updates.length
        ? updates.reduce(
            (ts, operation) => update(ts, operation),
            timesheetSource
          )
        : update(timesheetSource, updates);
      store.writeQuery({
        query,
        variables: {date, timesheetUser},
        data: {
          timesheet: updated
        }
      });
    }
  }),
  // loadingSpinner,
  timesheetActions
);
