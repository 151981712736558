import ApolloClient from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import SerializingLink from './utils/SerializingLink';
import {setFatalError} from './app/actions';

export default function createApolloClient(store) {
  const client = new ApolloClient({
    link: makeLink(store),
    cache: new InMemoryCache().restore({})
  });

  return client;
}

function makeLink(store) {
  const http = new HttpLink({
    uri: '/api/graphql',
    credentials: 'same-origin'
  });
  const errorHandlingLink = onError(({graphQLErrors, networkError}) => {
    if (networkError && networkError.statusCode === 401) {
      window.location.replace('/login');
    } else if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach(graphQLError => {
        const errorMessage = graphQLError && graphQLError.message;
        if (errorMessage.startsWith('Duplicate entry')) {
          store.dispatch(
            setFatalError(
              'We have detected duplicate entries. If you have BEDROCK open in another browser tab, or on your mobile device, please close the duplicate session and then click OK. If you continue to see this error, please put a ticket in <a href="https://ithelp.taittowers.com">https://ithelp.taittowers.com</a>'
            )
          );
          return;
        }
      });
    }
  });
  // const logRequestsLink = new ApolloLink((operation, forward) => {
  //   console.log('Sending request', operation.toKey())
  //   return forward(operation)
  // })
  return ApolloLink.from([errorHandlingLink, new SerializingLink(true), http]);
}
