import React from 'react';
// import PropTypes from 'prop-types'

const NoMatch = props => <div />;

NoMatch.propTypes = {};

export default NoMatch;

// vim ft=javascript.jsx
