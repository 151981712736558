export default selections =>
  selections.reduce((acc, selection) => {
    let {type, ...value} = selection;

    if (type === 'JobsByCompany') {
      type = 'Job'
    }

    const typename = type === 'Job' ? 'JobInfo' : 'Lookup';
    if (type === 'Job') {
      value.Closed = false;
    }    

    return {[type]: {...value, __typename: typename}, ...acc};
  }, {});
