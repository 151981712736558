import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function NextWeek(props, context) {
  //var next = '/timesheet/' +
  //   moment(props.current).add(1, 'week').format('YYYY-MM-DD');
  var onClick = () => {
    var next = moment(props.current).add(1, 'week');
    props.goToTimesheet(next);
  };
  return (
    <a className="timesheet-nextweek" onClick={onClick}>
      <i className="fa fa-lg fa-arrow-right" aria-hidden="true" />
    </a>
  );
}

NextWeek.propTypes = {
  current: PropTypes.string,
  goToTimesheet: PropTypes.func.isRequired
};

export default NextWeek;
