import moment from 'moment';
import range from 'lodash/range';
import makeBlankEntry from './_makeBlankEntry';

export default timesheet => ({
  __typename: 'TimesheetRow',
  // you MUST specify an id or Apollo will be confused
  id: 'TMP_' + Math.random(),
  Project: null,
  Job: null,
  Operation: null,
  IndirectCode: null,
  ReworkCode: null,
  Narrative: '',
  IsSystemEntry: false,
  entries: range(
    0,
    moment(timesheet.PeriodEndDate).diff(timesheet.PeriodStartDate, 'days') + 1
  ).map(i => makeBlankEntry(timesheet, i))
});
