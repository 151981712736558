import {graphql} from 'react-apollo';
import {CURRENT_USER_QUERY} from '../queries';

// access the currentUser (uses apollo cache)
export default graphql(CURRENT_USER_QUERY, {
  props: ({data}) => ({
    currentUser: data.currentUser
  }),
  options: {
    fetchPolicy: 'cache-only'
  }
});
