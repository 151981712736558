import React from 'react'
import Tooltip from 'rc-tooltip'

import PercentCompleteSelection from './PercentCompleteSelection'

const PercentCompleteSelectionOverlay = ({tooltip, tooltipPlacement, ...props}) => {
  const content = <PercentCompleteSelection {...props} />
  if (tooltip && tooltip !== 'NO_ENTRIES') {
    return (
      <Tooltip
        overlay={<span>{tooltip}</span>}
        placement={tooltipPlacement || 'top'}>
        <span>{content}</span>
      </Tooltip>   
    )   
  }
  return content
}

export default PercentCompleteSelectionOverlay