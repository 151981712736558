export default function() {
  var isTouch = false;
  var isTouchTimer = undefined;

  function addtouchclass(e) {
    var el = document.documentElement;
    isTouch = true;

    if (typeof isTouchTimer !== 'undefined') {
      clearTimeout(isTouchTimer);
    }

    if (!el.classList.contains('can-touch')) {
      el.classList.add('can-touch');
    }

    isTouchTimer = setTimeout(function() {
      isTouch = false;
    }, 500);
  }

  function removetouchclass(e) {
    var el = document.documentElement;

    if (!isTouch && el.classList.contains('can-touch')) {
      el.classList.remove('can-touch');
    }
  }

  document.addEventListener('touchstart', addtouchclass, false);
  document.addEventListener('mouseover', removetouchclass, false);
}
