import {withHandlers} from 'recompose';
import findIndex from 'lodash/findIndex';

export default withHandlers({
  saveOvernightComp: ({
    setError,
    setSavePending,
    updateTime,
    timesheet
  }) => async (date, previousSelection, newSelection) => {
    if (previousSelection === newSelection) {
      return;
    }
    // don't let them change anything on that date while we do this, or it could mess up the header records in Epicor
    setSavePending({[date]: true});
    try {
      if (previousSelection) {
        await updateOvernightComp(previousSelection, false);
      }
      if (newSelection) {
        await updateOvernightComp(newSelection, true);
      }
    } catch (e) {
      console.warn('Error saving overnight comp', e);
      setError(e);
    }
    setSavePending({[date]: false});

    function updateOvernightComp(rowSelection, newValue) {
      const rowIndex = findIndex(
        timesheet.rows,
        row => row.id === rowSelection
      );
      if (rowIndex < 0)
        // should not happen
        throw new Error('cannot find row ' + rowSelection);
      const entryIndex = findIndex(
        timesheet.rows[rowIndex].entries,
        e => e.Date === date
      );
      if (entryIndex < 0) throw new Error('cannot find entry ' + date);
      return updateTime({
        rowIndex,
        entryIndex,
        overnightCompensation: newValue
      });
    }
  },
  clearOvernightComp: ({updateTime, timesheet}) => () => {
    timesheet.rows.forEach((row, rowIndex) =>
      row.entries.forEach(
        (entry, entryIndex) =>
          entry.OvernightCompensation &&
          updateTime({rowIndex, entryIndex, overnightCompensation: false})
      )
    );
  }
});
