import React from 'react';
import styled from 'styled-components';
import search from './search.svg';

const NestedDropdownHeader = ({
  handleSearchInput,
  loading,
  onClick,
  search,
  selection,
  onKeyDown
}) => {
  const currentSelection = selection.slice(-1)[0];
  let placeholder;

  if (!currentSelection) {
    placeholder = 'Select a Project';
  } else if (currentSelection.id === 'INDIRECT') {
    placeholder = 'Select Indirect Code';
  } else if (selection.length === 3) {
    placeholder = 'Select Rework Code';
  } else if (selection.length === 1) {
    placeholder = 'Select a Job';
  } else {
    placeholder = 'Select an Operation';
  }

  return (
    <div onKeyDown={onKeyDown}>
      <HeadContainer>
        <input
          className="search-bar"
          onClick={onClick}
          onChange={handleSearchInput}
          placeholder={placeholder}
          readOnly={loading}
          type="text"
          value={search}
        />
      </HeadContainer>
    </div>
  );
};

const HeadContainer = styled.div`
  padding: 0.1rem 0;

  .search-bar {
    background: url(${search}) no-repeat scroll;
    background-size: auto calc(100% - 13px);
    background-position: top 6px right 5px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.8);
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 12px;
    padding-right: 25px;
    font-family: AvantGardeGothicITCW01Bk;
    font-size: 14px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;

    &:focus {
      outline-width: 0;
    }
  }
`;

export default NestedDropdownHeader;
