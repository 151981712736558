import makeBlankRow from './_makeBlankRow';
import hasBlankRow from './_hasBlankRow';

// handler to add a row on the timesheet
export default ({updateTimesheetData, timesheet}) => () => {
  if (!hasBlankRow(timesheet)) {
    updateTimesheetData({
      rows: {
        $push: [makeBlankRow(timesheet)]
      }
    });
  }
  // console.log('Original timesheet', timesheet);
  // console.log('Updated timesheet', updated);
};
