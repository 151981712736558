import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';
import Tooltip from 'rc-tooltip';

import TimeDialog from '../../shared/components/time-dialog';

/**
 * <Recall />
 * Recall button for timesheet
 */
export default class Recall extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    forDashboard: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    onRecall: PropTypes.func.isRequired,
    timesheet: PropTypes.object.isRequired
  };

  state = {
    isRecallToEnteredDialogOpen: false,
    isRecallToSubmittedDialogOpen: false
  };

  _onCloseRecallToEnteredDialog() {
    const isRecallToEnteredDialogOpen = false;
    this.setState({isRecallToEnteredDialogOpen});
  }

  _onCloseRecallToSubmittedDialog() {
    const isRecallToSubmittedDialogOpen = false;
    this.setState({isRecallToSubmittedDialogOpen});
  }

  _onOpenRecallToEnteredDialog() {
    if (this.props.isUnsubmittable) {
      const isRecallToEnteredDialogOpen = true;
      this.setState({isRecallToEnteredDialogOpen});
    }
  }

  _onOpenRecallToSubmittedDialog() {
    if (this.props.isUnapprovable) {
      const isRecallToSubmittedDialogOpen = true;
      this.setState({isRecallToSubmittedDialogOpen});
    }
  }

  _onRecallToEntered(dates) {
    this.props.onRecall(dates, false);
    this.setState({isRecallToEnteredDialogOpen: false});
  }

  _onRecallToSubmitted(dates) {
    this.props.onRecall(dates, true);
    this.setState({isRecallToSubmittedDialogOpen: false});
  }

  renderRecallToEntered(isUnsubmittable) {
    return (
      <MenuItem
        eventKey="1"
        disabled={!isUnsubmittable}
        onClick={() => this._onOpenRecallToEnteredDialog()}>
        Recall to Employee
      </MenuItem>
    );
  }

  renderRecallToApproved(isUnapprovable) {
    let component = (
      <MenuItem
        eventKey="2"
        disabled={!isUnapprovable}
        onClick={() => this._onOpenRecallToSubmittedDialog()}>
        Recall to Approver
      </MenuItem>
    );
    if (!isUnapprovable) {
      component = (
        <Tooltip
          overlay={<span>No approved time to recall</span>}
          placement="bottom">
          {component}
        </Tooltip>
      );
    }
    return component;
  }

  render() {
    const {
      disabled,
      isUnsubmittable,
      isUnapprovable,
      forDashboard,
      mobile,
      timesheet
    } = this.props;

    const cls = classNames('btn timesheet--recall', {
      'actions-sub-button': forDashboard || mobile,
      'desktop-control': !mobile
    });

    const totals = isUnapprove =>
      timesheet.totals.map(total => {
        let message;
        if (total.Posted) {
          message = 'Already Posted';
        } else if (isUnapprove && !total.Approved) {
          message = 'Not Yet Approved';
        } else if (!total.Submitted) {
          message = 'Not Yet Submitted';
        } else if (total.Date > Date.now()) {
          message = 'Future Date';
        }

        return {
          ...total,
          Message: message,
          Disabled: !!message
        };
      });

    return (
      <div>
        <DropdownButton
          // This style prevents the button from remaining in a stuck focus state.
          bsStyle="tait"
          className={cls}
          disabled={disabled}
          id="recall"
          title={
            <span>
              <i
                className="fa fa-lg fa-arrow-circle-o-down"
                aria-hidden="true"
              />
              Recall
            </span>
          }>
          {this.renderRecallToEntered(isUnsubmittable)}
          {this.renderRecallToApproved(isUnapprovable)}
        </DropdownButton>
        {this.state.isRecallToEnteredDialogOpen && (
          <TimeDialog
            heading=""
            onClose={() => this._onCloseRecallToEnteredDialog()}
            onSave={dates => this._onRecallToEntered(dates)}
            totals={totals(false)}
            isRecalltoApprover={false}
            isRecall={true}
            title={`Recall to Employee Selected Approved Days for ${
              timesheet.EmployeeName
            }`}
          />
        )}
        {this.state.isRecallToSubmittedDialogOpen && (
          <TimeDialog
            heading=""
            onClose={() => this._onCloseRecallToSubmittedDialog()}
            onSave={dates => this._onRecallToSubmitted(dates)}
            totals={totals(true)}
            isRecalltoApprover={true}
            isRecall={true}
            title={`Recall to Approver Selected Approved Days for ${
              timesheet.EmployeeName
            }`}
          />
        )}
      </div>
    );
  }
}
