import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

// prepare options for the projects that are currently active on the timesheet
const buildProjectOptions = timesheet => i => {
  const result = timesheet.rows
    .map(r => {
      let label;
      if (timesheet.totals[i].Total > 0) {
        if (!r.entries[i].TimeAllocation && !r.entries[i].OvernightCompensation)
          return null;
      } else {
        // if we don't have any time on this day, then we'll include rows that have ANY time on the timesheet
        if (!find(r.entries, e => e.TimeAllocation)) return null;
      }
      if (r.Job && !r.Job.Closed) label = r.Job.label;
      else if (r.IndirectCode && timesheet.overnightRow.Description === 'Overnight') label = r.IndirectCode.label;
      else return null;
      return {
        value: r.id,
        label
      };
    })
    .filter(x => x);
  return sortBy(result, 'label');
};

export default buildProjectOptions;