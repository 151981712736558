import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import {withHandlers} from 'recompose';
import classNames from 'classnames'
import { PERCENT_COMPLETES } from '../../constants'
import rowShape from './shapes/rowShape'

class PercentCompleteSelection extends React.Component {
  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      this.selectRef &&
      this.selectRef.state.isOpen &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.selectRef.closeMenu()
    }
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setSelectRef = component => {
    this.selectRef = component
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const {disabled, onChange, row} = this.props
    const percentCalc = row.Operation ? row.Operation.CompletedQty / row.Operation.RunQty : 0
    // Limit percent to 1 to prevent percentages greater than 100%
    const percent = percentCalc > 1 ? 1 : percentCalc
    const cls = classNames('timesheet--percentcompleteselection col-md-12 col-xs-9', {
      'percentcompleteselection--disabled': disabled,
      'percentcompleteselection--completed': row.Operation && row.Operation.OpComplete
    })

    const cls2 = classNames('percent-complete-title day-titles col-xs-3', {
      'percent-complete-title--disabled': disabled
    })    
    
    return (
      <div>
        <div className={cls2}>Percent Complete</div>
        <div className={cls} ref={this.setWrapperRef}>
          <ReactSelect
            disabled={disabled}
            ref={this.setSelectRef}
            clearable={false}
            value={{
              label: percent.toLocaleString(undefined, {style: 'percent'}),
              value: percent
            }}
            options={makeOptions(PERCENT_COMPLETES)}     
            onChange={onChange}
          />
        </div>
      </div>
    )
  }
}

const makeOptions = percentCompletes =>
  percentCompletes.map(p => ({
    label: p.STRING,
    value: p.VALUE
  }));

const container = withHandlers({
  onChange: ({onChange, rowIndex}) => newSelection => {
    onChange(rowIndex, newSelection && newSelection.value)
  }
})

PercentCompleteSelection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  row: rowShape.isRequired
}

export default container(PercentCompleteSelection)