import {compose} from 'recompose';
import redux from '../employeeList/redux';
import approvalHeader from '../../hoc/approvalHeader'

// we need to apply the approved filter so that it will show the
// "No timesheet to approved" if all timesheets have been approved
export default compose(
    redux,
    approvalHeader
);
