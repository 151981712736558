import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {Link, Route} from 'react-router-dom';

import hoc from '../hoc/approvalButton';

const HeaderApprovalButton = ({timesheetPath, approvalPath}) => {
  return (
    <Container>
      <Route
        path="/approval"
        render={() => (
          <Link className="btn btn-default" to={timesheetPath}>
            Timesheets <i className="fa fa-clock-o" />
          </Link>
        )}
      />
      <Route
        path="/timesheet"
        render={() => (
          <Link className="btn btn-approvals" to={approvalPath}>
            Approvals <i className="fa fa-eye" />
          </Link>
        )}
      />
    </Container>
  );
};

HeaderApprovalButton.propTypes = {
  location: PropTypes.object.isRequired
};

const Container = styled.div`
  @media (min-width: 991px) {
    display: inline-block;
    margin-left: 10px;
  }

  @media (max-width: 991px) {
    display: none;
    visibility: hidden;
  }

  .btn {
    color: #333;
    border: 1px solid #cdcdcd;

    &.btn-approvals {
      background-color: #fff;
      border: 1px solid #cdcdcd;
    }

    &.btn-default {
      background-color: #fff;
    }

    &:hover {
      background: #333;
      color: #fff;
    }
  }

  a {
    height: 35px;
    margin-top: 3px;
  }
`;

export default hoc(HeaderApprovalButton);
