import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

/**
 * <EditRow />
 * Button to delete the current row
 */

class EditRow extends React.Component {
  render() {
    const {
      disabled,
      onEdit
    } = this.props
    const cls = classNames('btn timesheet-edit', {
      'disabled': disabled
    })
    return (
      <button className={cls} disabled={disabled} onClick={onEdit}>
        <i className="fa fa-edit" aria-hidden="true" />
      </button>
    )
  }
}

EditRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default EditRow;
