import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'rc-tooltip';
import { OPERATION_OUTLOOKS } from '../../constants';

/**
 * <TimesheetDayTitles />
 * Timesheet column header (for desktop devices)
 */
function TimesheetHeaderRow({startDate, totals, company}) {
  var d = moment(startDate);
  var entries = [];
  for (let i = 0; i < 7; i++) {
    entries.push(d.clone());
    d.add(1, 'day');
  }

  function renderEntryHeader(date, index) {
    const approved = totals[index].Approved;
    const submitted = !approved && totals[index].Submitted;

    const cls = classNames(
      'timesheet-cell',
      'timesheet-cell--header',
      {'timesheet-cell--approved': approved},
      {'timesheet-cell--submitted': submitted}
    );

    return (
      <div className={cls} key={date.toDate()}>
        {date.format('ddd D')}
        {approved && <i className="fa fa-lock" />}
        {submitted && <i className="fa fa-check" />}
      </div>
    );
  }

  const timesheetProjectClass = classNames('timesheet-project', {
    'col-md-3': (company === '100' || company === '700' || company === '770'),
    'col-md-4': (company !== '100' && company !== '700' && company !== '770')
  })
  const timesheetDaysClass = classNames('timesheet-days', {
    'col-md-6': (company === '100' || company === '700' || company === '770'),
    'col-md-7': (company !== '100' && company !== '700' && company !== '770')
  })

  return (
    <div className="timesheet-project-header">
      <div className="row">
        <div className={timesheetProjectClass}>
          <div className="project-heading">Project</div>
        </div>
        <div className={timesheetDaysClass}>
          <div className="day-titles">{entries.map(renderEntryHeader)}</div>
        </div>
        {(company === '100' || company === '700' || company === '770') && (
          <div className='col-md-1 header-percent-complete'>Percent Complete</div>
        )}     
        {(company === '100' || company === '700' || company === '770') && (
          <Tooltip
            overlay={<div>
              {OPERATION_OUTLOOKS.slice(1).map(outlook => (<p><span role='img'>{String.fromCodePoint(outlook.EMOJI)} {outlook.VALUE} - {outlook.STRING}</span></p>))}
            </div>}    
            trigger='hover'               
            placement={'bottom'}>
            <div className='col-md-1 header-op-outlook'>Outlook</div> 
          </Tooltip> 
        )}                  
        <div className="col-md-1 header-totals">Totals</div>
      </div>
    </div>
  );
}
TimesheetHeaderRow.propTypes = {
  startDate: PropTypes.string.isRequired,
  totals: PropTypes.array.isRequired
};

export default TimesheetHeaderRow;
