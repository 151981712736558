import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import moment from 'moment';
import styled from 'styled-components';
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';

/**
 * <CalendarDialog />
 * Used to pop up the calendar selection screen.
 */
class CalendarDialog extends React.Component {
  handleChange = day => {
    this.props.onDateSelected(moment(day));
  };

  render() {
    var show = this.props.show;
    if (!show) {
      return null;
    }

    return (
      <Dialog
        title=""
        size="small"
        className="calendar-dialog"
        onDismiss={this.props.onClosed}
        showFooter={false}>
        <StyleContainer>
          <DayPicker
            onDayClick={this.handleChange}
            onTodayButtonClick={this.handleChange}
            firstDayOfWeek={1}
            initialMonth={moment(this.props.current).toDate()}
            todayButton="Today"
          />
        </StyleContainer>
      </Dialog>
    );
  }
}
CalendarDialog.propTypes = {
  show: PropTypes.bool,
  onDateSelected: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  current: PropTypes.string
};

const StyleContainer = styled.div`
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  abbr {
    cursor: default;
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Month {
    margin: 0px;
  }

  .DayPicker-NavBar {
    height: 11px;

    span {
      background: none;
    }
    span:first-child {
      left: 1.5rem;

      &:before {
        content: '\f053';
        font-family: FontAwesome;
      }
    }

    span:last-child:before {
      content: '\f054';
      font-family: FontAwesome;
    }
  }

  .DayPicker-Weekday {
    display: table-cell;
  }

  .DayPicker-Caption {
    text-align: center;

    div {
      font-size: 15px;
    }
  }

  .DayPicker-TodayButton {
    border: 1px solid orange;
    color: orange;
    transition: all 0.4s;
    border-radius: 4px;
    background-color: transparent;
    float: right;
    padding: 10px 15px;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: orange;
      color: white;
      border: 1px solid orange;
    }
  }
`;

export default CalendarDialog;
