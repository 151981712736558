import {buildTsInput} from '../../../shared/utils/queries';

// used to build the TimesheetRowInput object based on timesheet / row data
export default function(timesheet, row) {
  return {
    ...buildTsInput(timesheet),
    row: {
      id: row.id,
      Job: row.Job && row.Job.value,
      Project:
        row.Project && row.Project.value !== 'INDIRECT'
          ? row.Project.value
          : null,
      IndirectCode: row.IndirectCode && row.IndirectCode.value,
      Operation: row.Operation && row.Operation.value,
      ReworkCode: row.ReworkCode && row.ReworkCode.value,
      // specify that so that we can apply it to new time entries
      Narrative: row.Narrative,
      RunQty: row.Operation && row.Operation.RunQty,
      CompletedQty: row.Operation && row.Operation.CompletedQty,
      Outlook: row.Operation && row.Operation.Outlook,
      OpComplete: row.Operation && row.Operation.OpComplete
    }
  };
}
