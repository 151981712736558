import {connect} from 'react-redux';
import * as actions from '../../actions/employeeList';

// redux actions and data
export default connect(
  state => ({
    expandedUsers: state.approval.expandedUsers,
    nameFilter: state.approval.nameFilter
  }),
  actions
);
