import {compose} from 'recompose';

import routeParams from './routeParams';
import redux from './redux';
import query from './query';
import trackDefaultView from './trackDefaultView';
import timesheetRedux from '../../../timesheet/hoc/timesheetRedux';
import supervisorList from './supervisorList';
import approvalHeader from '../approvalHeader'

export default compose(
  timesheetRedux,
  routeParams,
  redux,
  query,
  trackDefaultView,
  supervisorList,
  approvalHeader
);
