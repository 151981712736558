import {compose} from 'recompose';
import redux from './redux';
import nameFilter from './nameFilter';
import goToFirstTimesheet from './goToFirstTimesheet';
import leaveInvalidTimesheet from './leaveInvalidTimesheet';

export default compose(
  redux,
  nameFilter,
  goToFirstTimesheet,
  leaveInvalidTimesheet,
);
