import {connect} from 'react-redux';

import * as actions from '../../actions/approvalDetail';

export default connect(
  state => ({
    focusRow: state.approval.focusRow,
    editRow: state.approval.editRow,
    expandedRows: state.approval.expandedRows
  }),
  actions
);
