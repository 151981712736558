import {graphql} from 'react-apollo';
import pick from 'lodash/pick'
import fromPairs from 'lodash/fromPairs'
import {UPDATE_PERCENT_COMPLETE} from '../../queries';
import {compose} from 'recompose';
import buildRowInput from './_buildRowInput'
import {buildTsInput} from '../../../shared/utils/queries'
// update percent complete on related op
//
const updatePercentComplete = graphql(UPDATE_PERCENT_COMPLETE, {
  props: ({
    ownProps: {
      setError,
      setSuccess,
      updateTimesheetData,
      setSavePending,
      timesheet,
      activeRow      
    },
    mutate
  }) => ({
    updatePercentComplete(rowIndex, newSelection) {
      let row = timesheet.rows[rowIndex]
      if (!row) row = timesheet.rows[activeRow]
      setSavePending(fromPairs(row.entries.map(e => [e.id, true])));
      return mutate({
        variables: {
          ...buildTsInput(timesheet),
          ...buildRowInput(timesheet, row),
          percentComplete: newSelection
        },

        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   // __optimistic: true,
        //   updatePercentComplete: {
        //     __typename: 'TimesheetEntry',
        //     ...sourceRow,
        //     ...row
        //   }
        // },

        update: (store, {data: {updatePercentComplete}}) => {
          const percentCompleteUpdates = pick(updatePercentComplete, [
            'RunQty',
            'CompletedQty',
            'OpComplete'
          ])
          updateTimesheetData(
            {
              rows: {
                [rowIndex]: {
                  Operation: {
                    $merge: percentCompleteUpdates
                  }                  
                }
              }
            },
            store
          );
        }
      })
        .catch(err => {
          setError(err);
        })
        .then(
          () => setSuccess('Percent Complete has been updated.')
        )
        .then(result => {
          setSavePending(fromPairs(row.entries.map(e => [e.id, false])));
        });
    }
  })
});

export default compose(updatePercentComplete);
