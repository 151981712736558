import {lifecycle} from 'recompose';

export default lifecycle({
  componentDidUpdate(prevProps) {
    if (prevProps.view !== this.props.view && this.props.view) {
      this.props.setDefaultView(this.props.view);
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setCurrentPath(this.props.location.pathname);
    }
  }
});
