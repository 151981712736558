import count from 'lodash/countBy'

export default function getNoteLockedReason(
  row,
  mode
) {
  if (
  mode !== 'timesheet' &&
  row.entries.reduce(
    (count, val, i) =>
      count + val.Detail.reduce((acc, detail) => {
        if (detail.Narrative !== '') {
          acc.push(detail)
        }
        return acc
      }, []).length,  //narrativeCount(val).length,
    0
  ) > 1) {
    return 'MULTIPLE_NARRATIVES'
  }
  else if (
    mode === 'timesheet' &&
    count(row.entries, x => !x.id.startsWith('TMP')).false === 7
  ) {
    return 'NO_ROW_ENTRIES'
  }
}