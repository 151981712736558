import {graphql} from 'react-apollo';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import {SAVE_ON_CALL_MUTATION} from '../../queries';
import {compose, withHandlers} from 'recompose';
import {buildTsInput} from '../../../shared/utils/queries';

// Saving On Call data
//
const saveOnCall = graphql(SAVE_ON_CALL_MUTATION, {
  props: ({
    ownProps: {
      setError,
      updateTimesheetData,
      setSavePending,
      timesheet,
      timesheetSource,
      activeEntry,
      activeRow
    },
    mutate
  }) => ({
    saveOnCall(date, previousSelection, newSelection) {
      if ((previousSelection || '') === (newSelection || '')) {
        return;
      }
      const sourceEntry = find(
        timesheet.onCallRow.entries,
        e => e.Date === date
      );
      const entry = {
        id: sourceEntry.id,
        Date: date,
        OnCall: newSelection
      };
      setSavePending({
        [entry.id]: true
      });
      return mutate({
        variables: {
          ...buildTsInput(timesheet),
          row: {},
          entry
        },

        optimisticResponse: {
          __typename: 'Mutation',
          // __optimistic: true,
          saveOnCall: {
            __typename: 'TimesheetEntry',
            OvernightCompensation: false,
            TimeAllocation: 0,
            ...sourceEntry,
            ...entry
          }
        },

        update: (store, {data: {saveOnCall}}) => {
          const entryIndex = findIndex(
            timesheetSource.timeOnCall,
            e => e.Date === date
          );
          updateTimesheetData(
            {
              timeOnCall: {
                [entryIndex]: {
                  // this could include a new id, so we need to do an explicit update
                  $merge: saveOnCall
                }
              }
            },
            store
          );
        }
      })
        .catch(err => {
          setError(err);
        })
        .then(result => {
          if (sourceEntry) setSavePending({[sourceEntry.id]: false});
        });
    }
  })
});

const clearOnCall = withHandlers({
  clearOnCall: ({saveOnCall, timesheet}) => () => {
    timesheet.onCallRow.entries.forEach(entry =>
      saveOnCall(entry.Date, entry.Selection, null)
    );
  }
});

export default compose(saveOnCall, clearOnCall);
