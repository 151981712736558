import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link, Route } from 'react-router-dom';
import Tooltip from 'rc-tooltip';

import hoc from '../hoc/approvalButton';

const ApprovalButton = ({ timesheetPath, approvalPath }) => {
  return (
    <Container>
      <Route
        path="/approval"
        render={() => (
          <Tooltip placement="left" overlay={<span>Timesheets</span>}>
            <Link to={timesheetPath}>
              Timesheets
            </Link>
          </Tooltip>
        )}
      />
      <Route
        path="/timesheet"
        render={() => (
          <Tooltip placement="left" overlay={<span>Approvals</span>}>
            <Link to={approvalPath}>
              Approvals
            </Link>
          </Tooltip>

        )}
      />
    </Container>
  );
};

ApprovalButton.propTypes = {
  location: PropTypes.object.isRequired
};

const Container = styled.div`
 text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    padding-left: 0 !important;
    
    &:hover {
      background-color: #ddd;
}
  a {
    display: flex;
    width: 100%;
    padding: 20px;
  }
  
`;

export default hoc(ApprovalButton);