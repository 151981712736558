import gql from 'graphql-tag';

import {timesheetFields} from '../shared/utils/queries';

export const APPROVAL_USERS_QUERY = gql`
  query TimeForApproval($supervisorSelection: String) {
    timeForApproval(supervisorSelection: $supervisorSelection) {
      id
      Name  
      HasEnteredTime    
      Timesheets {
        PeriodStartDate
        Approved
      }
    }
  }
`;

export const RECALL_TIMESHEET_APPROVAL_MUTATION = gql`
mutation RecallTimesheeApproval($ts: TimesheetInput!, $dates: [Date]!, $resubmit: Boolean!) {
  recallTimesheetApproval(ts: $ts, dates: $dates, resubmit: $resubmit) {
    ts {
      ${timesheetFields}
    }
    message
  }
}
`;

export const UPDATE_ENTRY_DETAIL_MUTATION = gql`
mutation UpdateEntryDetail($ts: TimesheetInput!, $newDetail: TimesheetRowInput!) {
  updateEntryDetail(ts: $ts, newDetail: $newDetail) {
    ${timesheetFields}
  }
}
`;
