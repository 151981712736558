import gql from 'graphql-tag';

// retrieve the currently logged in user
// (even when viewing another user's timesheet, this will return the base user)
export const CURRENT_USER_QUERY = gql`
  query {
    currentUser {
      id
      Name
      Company
      EmployeeNum
      OnCallEmployee
      Subcontractor {
        Freelancer
        Contracted
        Contracts {
          Start
          End
          ProjectID
        }
      }      
      ApprovalsView
      UsersManaged {
        id
        Name
        ApproverId
        OnCallEmployee
        Subcontractor {
          Freelancer
          Contracted
          Contracts {
            Start
            End
            ProjectID
          }
        }        
      }
      SupervisorList {
        id
        EmployeeNum
        Company
        Name             
      }
    }
  }
`;
