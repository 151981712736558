import {graphql} from 'react-apollo';
import {buildTsInput} from '../../../shared/utils/queries';
import {UPDATE_ENTRY_DETAIL_MUTATION} from '../../queries';

export default graphql(UPDATE_ENTRY_DETAIL_MUTATION, {
  props: ({
    ownProps: {
      clearLoading,
      setEditRow,
      setLoading,
      setSavePending,
      setError,
      setSuccess,
      updateTimesheetData,
      timesheet
    },
    mutate
  }) => ({
    updateEntryDetail(id, input) {
      setLoading();
      setSavePending({[id]: true});

      mutate({
        variables: {
          ...buildTsInput(timesheet),
          newDetail: {
            id,
            ReworkCode: input.ReworkCode && input.ReworkCode.value,
            IndirectCode: input.IndirectCode && input.IndirectCode.value,
            Job: input.Job && input.Job.value,
            Operation: input.Operation && input.Operation.value,
            Project:
              input.Project && input.Project.value !== 'INDIRECT'
                ? input.Project.value
                : null
          }
        }
      })
        .then(
          () => setSuccess('Timesheet entry has been updated.'),
          err => setError(err)
        )
        .then(() => {
          clearLoading();
          setSavePending({[id]: false});
          setEditRow(null);
        });
    }
  })
});
