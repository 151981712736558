import {connect} from 'react-redux';
import {compose, lifecycle} from 'recompose';
import SignOutPage from '../pages/SignOutPage';
import withSignout from '../hoc/withSignout';

export default compose(
  withSignout,
  connect(state => ({
    reason: state.auth.signOutReason,
    authenticated: state.auth.authenticated
  })),
  lifecycle({
    componentDidMount() {
      if (this.props.authenticated) this.props.signOut();
    }
  })
)(SignOutPage);
