import React from 'react';
import Tooltip from 'rc-tooltip';

import OnCallCell from './OnCallCell';
import getEntryLockedReason from '../api/getEntryLockedReason';

const OnCallCellOverlay = ({invalidSelection, mode, labels, ...props}) => {
  const {
    selection,
    isSavePending,
    posted,
    submitted,
    options,
    approved
  } = props;

  const selected = options.find(x => x.value === selection);
  const lockedReason = getEntryLockedReason(
    {}, // entry: don't need
    {
      Approved: approved,
      Posted: posted,
      Submitted: submitted
    },
    {}, // row: don't need
    mode
  );
  const tooltip =    
    (lockedReason && labels.lockedReason[lockedReason]) ||
    (typeof selected === 'undefined' ? null : selected.label);

  let content = (
    <OnCallCell
      isReadOnly={!!lockedReason || isSavePending}
      isInvalidSelection={!!invalidSelection}
      {...props}
    />
  );
  if (tooltip) {
    content = (
      <Tooltip overlay={<span>{tooltip}</span>} placement="top">
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default OnCallCellOverlay;
