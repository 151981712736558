import {lifecycle} from 'recompose';
import getApprovalLink from '../../api/getApprovalLink';

export default lifecycle({
  componentDidUpdate() {
    if (this.props.employeeList && this.props.match.path === '/approval') {
      const first = this.props.employeeList.find(e => e.Timesheets.length > 0);
      if (first) {
        this.props.history.push(
          getApprovalLink(first, first.Timesheets[0], this.props.defaultView)
        );
      }
    }
  }
});
