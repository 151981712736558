import {compose} from 'recompose';

import getDetailEntries from './getDetailEntries';
import updateEntryDetail from './updateEntryDetail';
import sortContainer from './sortContainer';
import redux from './redux';

export default compose(
  redux,
  getDetailEntries,
  updateEntryDetail,
  sortContainer
);
