import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import Dialog from './Dialog';

// Wrapper for a dialog that will invoke an action from an action collection
const ConfirmationDialog = ({dialogState, ...props}) => {
  if (!dialogState) return null;
  const action = dialogState.successAction;
  const confirmAction = isFunction(action)
    ? action
    : () => {
        props[action[0]].apply(this, action.slice(1));
        props.hideConfirmation();
      };
  const dismissAction = props.hideConfirmation;

  return (
    <div ref={React.createRef()}>
      <Dialog
        confirmLabel="OK"
        title={dialogState.title}
        onConfirm={confirmAction}
        onDismiss={dismissAction}>
        <p>{dialogState.message}</p>
      </Dialog>
    </div>
  );
};
ConfirmationDialog.propTypes = {
  dialogState: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    // action name and parameters, OR a function to execute
    successAction: PropTypes.oneOfType([PropTypes.array, PropTypes.func])
      .isRequired
  }),
  // this will be called when the dialog is dismissed, or when the success action is invoked WHEN it is passed as an
  // array (NOT if it is passed as a function, since in that case it is assumed it will hide the dialog as a side
  // effect)
  hideConfirmation: PropTypes.func.isRequired
};

export default ConfirmationDialog;
