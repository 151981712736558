import React from 'react'
import ReactModal from 'react-modal'
import classNames from 'classnames';
import styled from 'styled-components';
import isIOS from '../../utils/isIOS'
import Countdown from 'react-countdown-now'
import ClearButton from '../../shared/components/ClearButton'
import GlyphIcon from '../../shared/components/Glyphicon'
import withSignout from '../../auth/hoc/withSignout'

export default function(ComposedClass) {
  class AutoLogout extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        warningTime: 20000 * 60 * 6, // 2 hours
        signoutTime: 3000,
        warningReached: false,
        countdown: 10000 * 60 // 10 min
      };
    }

    componentDidMount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);

      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      // this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      // window.alert("You will be logged out automatically in 1 minute")
      this.setState({warningReached: true})     
      this.changeFavicon(process.env.PUBLIC_URL + '/favicon2.png')
      console.log('You will be logged out automatically in 1 minute.');
    };

    onComplete = () => {          
      this.props.signOut('Logged out due to inactivity.')
    }

    changeFavicon = (src) => {

      const link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon')
      link.id = 'dynamic-favicon'
      link.rel = 'shortcut icon'
      link.href = src
      if (oldLink) {
        document.head.removeChild(oldLink)
      }
      document.head.appendChild(link)
    }

    // logout = () => {
    //   // Send a logout request to the API
    //   console.log('Sending a logout request to the API...');
    //   this.destroy();
    // };

    // destroy = () => {
    //  //clear the session
    //   //browserHistory.push('/');
    //   window.location.assign('/');
    // };

    render() {
      return (
        <div>          
          <ComposedClass {...this.props} />          
          {this.state.warningReached && (
            <ReactModal
              isOpen={true}
              style={{
                content: {
                  padding: 0,
                  ...contentStyle(this.props),
                  transition: 'transform .25s',
                  transform: this.props.isAppearing ? 'translateY(-80vh)' : 'translateY(0)'
                },
                overlay: {
                  // should have a transition, ideally...
                  backgroundColor: 'rgba(0, 0, 0, .7)',
                  zIndex: 15,
                  ...overlayStyle(this.props)
                }
              }}
            >
            <Panel
              className={classNames(
                'panel',
                'panel-default',
                // this.props.className
              )}
            >
              
              <header
                className={classNames('panel-heading', 'bg-primary')}>
                <h4 className="panel-title">Session Expiring</h4>
              </header>
              
              <div className="panel-body">
                {/* {props.children} */}
                {/* <Alert error={'test'} /> */}
                <p>Your session will expire in </p>
                <Countdown 
                  date={Date.now() + this.state.countdown} 
                  onComplete={() => this.onComplete()}
                />
              </div>
              <footer className='panel-footer text-right'>
                <ClearButton
                  type='button'
                  className='cancel'>
                    <GlyphIcon glyph='cancel' />
                    &nbsp;I Need More Time
                  </ClearButton>                  
              </footer>
            </Panel>
            </ReactModal>   
          )}
        </div>              
      );
    }
  }

  const contentStyle = ({size}) => {
    // we need this to auto size vertically (to match the content)
    if (size === 'small') {
      return {
        position: 'absolute',
        margin: '10vh auto',
        width: '40vw',
        minWidth: '300px',
        maxWidth: '450px',
        overflow: 'visible',
        bottom: 'auto'
      };
    }
    return {
      position: 'static',
      margin: '30px auto',
      width: '90vw',
      maxWidth: '900px',
      border: 'none'
    };
  };
  
  const overlayStyle = ({size}) => {
    if (size === 'small') {
      return {
        // should NOT happen, because we only use that with small enough dialog
        // but "just in case" they have a very short screen
        overflow: 'auto'
      };
    }
    return {};
  };

  const Panel = styled.form`
  //position: absolute;
  //top: 0; bottom: 0; right: 0; left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  // leave room for header
  // this is a bit of a hack and it wont resize if they change the phone orientation
  // max-height: ${() => window.innerHeight - 50}px;
  // on iOS, need to leave extra room for the navigation controls which are included in the vh calculation
  // https://caniuse.com/#feat=viewport-units
  max-height: calc(100vh - ${isIOS() ? '120px' : '50px'})
	
  // adjust styles a bit for smaller size dialogs (which are usually using a vertical form)
  ${({size}) =>
    size === 'small' &&
    `
    .form-group {
      margin: 0;
    }
    .alert {
      margin-top: 15px;
      margin-bottom: 0;
    }
  `}

  .panel-body {
    flex-grow: 1;
    overflow: ${({size}) => (size === 'small' ? 'visible' : 'auto')};
  }
`;

  return withSignout(AutoLogout)
}