import {withProps} from 'recompose';

// compute the detail entries to be displayed on the detail view

export default withProps(({timesheet}) => {
  const entries = [];
  timesheet.rows.filter(isDetailShown).forEach((row, rowIndex) => {
    row.entries.forEach((entry, entryIndex) => {
      entries.push(
        ...entry.Detail.map((d, detailIndex) => ({
          ...entry,
          row,
          rowIndex,
          entryIndex,
          detailIndex,
          ProjectSortKey: getSortKey(row),
          ...d
        }))
      );
    });
  });
  return {entries};
});

// compute a sortable string for the project selection
function getSortKey({Project, Job, Operation, IndirectCode, ReworkCode}) {
  return [Project, Job, Operation, IndirectCode, ReworkCode]
    .map(f => f && f.label)
    .filter(x => !!x)
    .join('/');
}

function isDetailShown(row) {
  // don't show the system entries on the detail view
  return !row.IsSystemEntry;
}
