import count from 'lodash/countBy'

export default function getOpOutlookLockedReason(
  row,
  mode
) {
  if (
    mode === 'timesheet' &&
    count(row.entries, x => !x.id.startsWith('TMP')).false === 7
  ) {
    return 'NO_ENTRIES' // return empty string to still disable the component
  } else if (!row.Job) {
    return 'INDIRECT_ROW'
  } else if (    
    mode === 'timesheet' &&
    (count(row.entries, x => x.Approved === true && !x.id.startsWith('TMP')).true === count(row.entries, x => !x.id.startsWith('TMP')).true ||
    count(row.entries, x => x.Submitted === true && !x.id.startsWith('TMP')).true === count(row.entries, x => !x.id.startsWith('TMP')).true)
  ) {
    return 'NO_ACTIVE_TIME'
  }
}