import {withState, withHandlers, compose} from 'recompose';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Authentication from '../utils/Authentication';
import SignInPage from '../pages/SignInPage';
import {signIn} from '../actions/index';

export default compose(
  withRouter,
  withState('user', 'setUser', ''),
  withState('password', 'setPassword', ''),
  withState('error', 'setError', ''),
  withState('loading', 'setLoading', false),
  connect(null, {signIn}),
  withHandlers({
    login: ({user, password, setError, setLoading, history, signIn}) => e => {
      e.preventDefault();
      if (!user) {
        setError('Please enter Username');
      } else if (!password) {
        setError('Please enter Password');
      } else {
        setLoading(true);
        Authentication.login(user, password).then(
          userInfo => {
            signIn(userInfo);
            setLoading(false);
            history.push('/timesheet');
          },
          e => {
            setLoading(false);
            setError(e.toString());
          }
        );
      }
    }
  })
)(SignInPage);
