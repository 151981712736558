import React from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';
import styled from 'styled-components';
import {findDOMNode} from 'react-dom';

import ItemList from './ItemList';
import itemShape from './itemShape';
import NestedDropdownHeader from './NestedDropdownHeader';

const applySearch = (items, search) => {
  return items.filter(
    i =>
      i.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      i.id.toLowerCase().indexOf(search.toLowerCase()) > -1
  );
};

const TRANSITION_DURATION = 300;

class NestedDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }  
  state = {
    expanded: this.props.expanded,
    search: '',
    // 5 next ones are used for transition
    prevItems: null,
    prevSelection: null,
    transitionClass: '',
    transitionDirection: 'forward',
    transitionKey: 0,
    cursor: -1
  };

  _handleBreadcrumbClick = index => this.props.setSelectionLength(index + 1);

  _handleDropdownClick = e => this.setState({expanded: true});

  _handleItemSelection = item => {
    if (!this.props.loading) {
      this.props.selectItem(item);
    }
  };

  _handleSearchInputChanged = e => {
    const search = e.target.value;

    this.setState({search}, () => {
      this.props.onSearchChanged && this.props.onSearchChanged(search);
    });
  };

  _triggerTransition() {
    const prevItems = this.state.prevItems;
    if (this._viewport) this._viewport.scrollTop = 0;
    this.setState(
      {
        transitionClass: 'entering',
        transitionKey: this.state.transitionKey + 1
      },
      () => {
        // remove the entering class, triggering the transition
        setTimeout(() => {
          this.setState({transitionClass: ''});
        }, 50);
        // remove prevItems, unmounting the exiting component
        setTimeout(() => {
          if (this.state.prevItems === prevItems) {
            this.setState({prevItems: null});
          }
        }, TRANSITION_DURATION * 1.3);
      }
    );
  }

  componentDidMount() {
    document.addEventListener('mousedown', e => {
      if (
        this._container &&
        !this._container.contains(e.target) &&
        this.state.expanded
      ) {
        const state = {
          expanded: false
        };

        this.setState(state, () => this.props.resetSelections());
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // transition groups are not working for this component
    // (the .enter and .enter-active classes are somehow being applied in the same frame and so the transition is not triggered)
    // so this component maintains the transition state explicitly
    if (nextProps.selection.length !== this.props.selection.length) {
      const state = {
        prevItems: this.props.items,
        prevSelection: this.props.selection,
        transitionDirection:
          nextProps.selection.length > this.props.selection.length
            ? 'forward'
            : 'backward',
        search: ''
      };
      this.setState(state, () => {
        if (!nextProps.loading) {
          // show the transition only once we are done loading, otherwise the spinner does not always have the time to show
          // and it looks wonky
          // console.log('Transition (new selection)', state.transitionDirection)
          this._triggerTransition();
        }
      });
    } else if (!nextProps.loading && this.props.loading) {
      // console.log('Transition (not loading)', this.state.transitionDirection)
      this._triggerTransition();
    }
  }

  handleKeyDown = (e) => {
    const { cursor, search, prevItems, prevSelection } = this.state
    // eslint-disable-next-line
    let {items, loading} = this.props
    if (loading && prevSelection && prevItems) {
      items = prevItems
    }
    items = applySearch(items, search)
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) { // up arrow
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
      this.scrollList(this.state.cursor - 1)
    } else if (e.keyCode === 40 && cursor < this.props.items.length - 1) { // down arrow
      this.setState( prevState => ({
        cursor: prevState.cursor + 1
      }))
      this.scrollList(this.state.cursor + 1)
    } else if (e.keyCode === 13) {      
      let {items, loading} = this.props
      if (loading && this.state.prevSelection && this.state.prevItems) {
        items = this.state.prevItems
      }
      const item = applySearch(items, search)[this.state.cursor]
      this._handleItemSelection(item)
      this.setState({cursor: 0})
    } else if (e.keyCode === 27) {
      this.setState({expanded: false})
    } else {
      this.setState({
        cursor: 0
      })
    }
  }

  scrollList = (i) => {
    const list = document.getElementById('project-viewport')
    if (list) {
      const liHeight = 42
      const scrollTop = list.scrollTop
      const viewport = scrollTop + 250
      const liOffset = liHeight * i

      if (liOffset < scrollTop || (liOffset + liHeight) > viewport) {
        list.scrollTop = liOffset
      }    
    }    
  }

  render() {
    let {items, selection} = this.props;

    const {
      resetSelections,
      loading,
      loadingComponent,
      unselectItem
    } = this.props;

    const {
      expanded,
      prevItems,
      prevSelection,
      search,
      transitionClass,
      transitionDirection,
      transitionKey,
      cursor
    } = this.state;

    if (loading && prevSelection && prevItems) {
      // keep showing the current list while the next level is loading
      items = prevItems;
      selection = prevSelection;
    }

    return (
      <Container
        className="nested-dropdown"
        ref={el => {
          this._container = findDOMNode(el);
        }}
        scrollTop={this._viewport ? this._viewport.scrollTop : 0}>
        <NestedDropdownHeader
          handleSearchInput={this._handleSearchInputChanged}
          loading={loading}
          onClick={this._handleDropdownClick}
          search={search}
          selection={selection}
          onKeyDown={this.handleKeyDown}
        />
        {expanded && (
          <div
            id='project-viewport'
            className={'viewport ' + transitionDirection}
            ref={el => {
              this._viewport = el;
            }}>
            {loading && React.createElement(loadingComponent, {})}
            {prevItems &&
              !loading && (
                // for transition
                <ItemList
                  className="exiting"
                  items={prevItems}
                  key={transitionKey - 1}
                  loading={false}
                  loadingComponent={loadingComponent}
                  onBack={identity}
                  onBreadcrumbClick={identity}
                  onClear={identity}
                  selectItem={identity}
                  selection={prevSelection}
                  cursor={cursor}
                />
              )}

            <ItemList
              className={transitionClass}
              items={applySearch(items, search)}
              key={transitionKey}
              loading={loading}
              loadingComponent={loadingComponent}
              onBack={unselectItem}
              onBreadcrumbClick={this._handleBreadcrumbClick}
              onClear={resetSelections}
              selectItem={this._handleItemSelection}
              selection={selection}
              cursor={cursor}
            />
          </div>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  .nested-dropdown {
    position: relative;
  }
  .navigation {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    flex: 0 0 auto;
    padding: 0px 0px 0 15px;
    width: 100%;
  }

  .navigation,
  .navigation * {
    background-color: #eaeaea;
  }

  .item-list {
    flex-shrink: 0;
    min-height: 8rem;
    height: 100%;
    position: relative;
    width: 100%;
    transition: transform ${TRANSITION_DURATION}ms;

    &.exiting {
      // absolute position is required for transition
      position: absolute;
    }
  }
  .forward {
    .item-list {
      transform: translateX(0);
    }
    .item-list.entering {
      transform: translateX(100%);
    }
    .item-list.exiting {
      transform: translateX(-100%);
    }
  }
  .backward {
    .item-list {
      transform: translateX(0);
    }
    .item-list.entering {
      transform: translateX(-100%);
    }
    .item-list.exiting {
      transform: translateX(100%);
    }
  }

  .viewport {
    border-left: 1px solid rgb(204, 204, 204);
    border-top: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow-x: hidden;
    z-index: 9;
    height: auto;
    margin-top: -1px;
    margin-bottom: 12px;
    // min-height is used during the transition, to avoid squeezing then unsqueezing the viewport
    // maybe we should just use a fixed height??
    max-height: 20rem;
    background: white;
    width: calc(100% - 3px);
    left: 0px;
  }

  .viewport,
  .viewport > .item-list {
    background-color: #f7f7f7;
  }

  .spinner-container {
    height: 10000px;

    .spinner-inner {
      top: ${({scrollTop}) => `${scrollTop + 100}px`};
    }
  }
`;

NestedDropdown.defaultProps = {
  items: [],
  loading: false
};

NestedDropdown.propTypes = {
  items: PropTypes.arrayOf(itemShape).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingComponent: PropTypes.func.isRequired,
  onSearchChanged: PropTypes.func,
  resetSelections: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(itemShape).isRequired,
  setSelectionLength: PropTypes.func.isRequired,
  unselectItem: PropTypes.func.isRequired
};

export default NestedDropdown;
