import {connect} from 'react-redux';

import * as actions from '../../actions/approvalHeader';

export default connect(
  state => ({
    sidebarExpanded: state.approval.sidebarExpanded
  }),
  actions
);
