import {compose, withState, withProps, withHandlers} from 'recompose';
import sortListData from '../../../shared/utils/sortListData';

export default compose(
  withState('sort', 'onSort', {
    ClockInDate: 1,
    ClockInTime: 1
  }),
  withProps(({entries, sort}) => ({
    entries: sortListData(entries, sort)
  })),
  withHandlers({
    onHeaderClick: ({sort, onSort}) => colName =>
      onSort({[colName]: sort[colName] === 1 ? -1 : 1})
  })
);
