import moment from 'moment';
import {withProps} from 'recompose';

// parameters from the route for the timesheet page
export default withProps(({match}) => ({
  date:
    match.params.date ||
    moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD')
}));
