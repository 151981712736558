export const EMPTY = {};

export const primaryColor = '#0e66b4';
export const secondaryColor = '#f17325';
export const black = '#333';
export const white = '#f1f2f7';
export const inLieuOptions = [
  {label: 'Paid', value: '2'},
  {label: 'Holiday', value: '1'}
];
// how many ms to show error / success message
export const showMessageFor = 5000;
export const stopPropagation = e => e.stopPropagation();

export const APPROVAL_MODE = 'approval';
export const TIMESHEET_MODE = 'timesheet';

export const  INVALID_INDIRECT_INLIEU_CODES = {
  AnnualHoliday: 'IL14',
  BankHoliday: 'IL13',
  CompassionateLeave: 'IL34',
  JuryService: 'IL15',
  Maternity_Paternity: 'IL16',
  Sick: 'IL17',
  UnpaidLeave: 'IL18'
}

export const PERCENT_COMPLETES = [
  {
    STRING: '0%',
    VALUE: 0  
  },
  {
    STRING: '5%',
    VALUE: 0.05  
  },
  {
    STRING: '25%',
    VALUE: 0.25
  },
  {
    STRING: '50%',
    VALUE: 0.5 
  },
  {
    STRING: '75%',
    VALUE: 0.75 
  },
  {
    STRING: '95%',
    VALUE: 0.95
  },
  {
    STRING: '100%',
    VALUE: 1
  }
]

export const OPERATION_OUTLOOKS = [
  {
    STRING: 'No Selection',
    VALUE: 0,
    EMOJI: ''
  },
  {
    STRING: 'No Problems',
    VALUE: 1,
    EMOJI: '0x2600'
  },
  {
    STRING: 'Problems well understood with solutions in progress',
    VALUE: 2,
    EMOJI: '0x1F324'
  },
  {
    STRING: 'Problems understood - no solutions yet',
    VALUE: 3,
    EMOJI: '0x2601'
  },
  {
    STRING: 'Problem understanding in progress',
    VALUE: 4,
    EMOJI: '0x1F329'
  },
  {
    STRING: 'Undefined problems - no clear next steps',
    VALUE: 5,
    EMOJI: '0x1F32A'
  }
]  