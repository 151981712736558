import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import TimeDialog from '../../shared/components/time-dialog';

/**
 * <Recall />
 * Recall button for timesheet
 */
export default class Recall extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    forDashboard: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    onRecall: PropTypes.func.isRequired,
    timesheet: PropTypes.object.isRequired
  };

  state = {
    isDialogOpen: false
  };

  _onCloseDialog() {
    const isDialogOpen = false;
    this.setState({isDialogOpen});
  }

  _onOpenDialog() {
    const isDialogOpen = true;
    this.setState({isDialogOpen});
  }

  _onSave(dates) {
    this.props.onRecall(dates);
    this.setState({isDialogOpen: false});
  }

  render() {
    const {disabled, forDashboard, mobile, timesheet} = this.props;

    const cls = classNames('btn timesheet--recall', {
      'actions-sub-button': forDashboard || mobile,
      'desktop-control': !mobile
    });

    const totals = timesheet.totals.map(total => {
      let message;
      if (total.Posted) {
        message = 'Already Posted';
      } else if (total.Approved) {
        message = 'Already Approved';
      } else if (!total.Submitted) {
        message = 'Not Yet Submitted';
      } else if (total.Date > Date.now()) {
        message = 'Future Date';
      }

      return {
        ...total,
        Message: message,
        Disabled:
          total.Date > Date.now() ||
          total.Approved ||
          total.Posted ||
          !total.Submitted
      };
    });

    return (
      <div>
        <button
          className={cls}
          disabled={disabled}
          onClick={() => this._onOpenDialog()}>
          <i className="fa fa-lg fa-arrow-circle-o-down" aria-hidden="true" />
          Recall
        </button>
        {this.state.isDialogOpen && (
          <TimeDialog
            heading=""
            onClose={() => this._onCloseDialog()}
            onSave={dates => this._onSave(dates)}
            isRecalltoApprover={false}
            isRecall={true}
            totals={totals}
            title={`Recall Selected Days for ${timesheet.EmployeeName}`}
          />
        )}
      </div>
    );
  }
}
