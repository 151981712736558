import React from 'react';
import Banner from '../../shared/components/Banner'
import {Link} from 'react-router-dom'
import hoc from '../hoc/approvalDetail';

// import ActiveHeader from '../../shared/components/simple-grid/ActiveHeader';
// import ApprovalDetailRow from './ApprovalDetailRow';
import ApprovalControlBar from './ApprovalControlBar';

import ApprovalDetailView from './ApprovalDetailView'
import TimesheetFooter from '../../timesheet/components/TimesheetFooter'

class ApprovalDetail extends React.Component {
  render() {
    const {
      timesheet,
      setTimesheetUser,
      mode
    } = this.props

    const isApproved = !timesheet.isPosted && timesheet.isApproved;
    const isSubmitted =
      !(timesheet.isPosted || timesheet.isApproved) && timesheet.isSubmitted;
    const unsubmittedTime = timesheet.UnsubmittedTime.length > 0  

    return (
      <div className="detail-container">
        {timesheet.isPosted && <Banner bgStyle="default" text="Posted" />}
        {isApproved && <Banner bgStyle="success" text="Approved" />}
        {mode === 'timesheet' &&
          isSubmitted && <Banner bgStyle="info" text="Submitted" />}
        {unsubmittedTime &&
            <Banner 
              bgStyle='unsubmitted' 
              text={
                <div>
                  <div>
                    {this.props.currentUser.id === timesheet.EmployeeNum + ":" + timesheet.Company
                      ? `You have unsubmitted time for the week${timesheet.UnsubmittedTime.length === 1 ? '' : 's'} of`
                      : `${timesheet.EmployeeName} has unsubmitted time for the week${timesheet.UnsubmittedTime.length === 1 ? '' : 's'} of`}
                  </div> 
                  <div>
                    {timesheet.UnsubmittedTime
                      .map(time => <Link onClick={() => setTimesheetUser({value: timesheet.EmployeeNum + ":" + timesheet.Company})} to={`/timesheet/${time}`} className='timesheet-detail-unsubmitted--link'>{time}</Link>)
                      .reduce((prev, curr) => [prev, ' | ', curr])}
                  </div>
                </div>
              }
            />
        }
        <ApprovalControlBar {...this.props} />
        <div className="detail-view-scroll">
          <div className="detail-view">      
              <ApprovalDetailView {...this.props} />          
          </div>
        </div>
        <TimesheetFooter {...this.props} />
      </div>
    )
  }
}

// const renderHeader = ({onHeaderClick, sort}) => (
//   <thead>
//     <tr className="timesheet-project-header">
//       <ActiveHeader
//         className="projectName"
//         columnName="ProjectSortKey"
//         displayName="Project"
//         sort={sort}
//         onHeaderClick={onHeaderClick}
//         columnType="string"
//       />
//       <ActiveHeader
//         columnName="ClockInDate"
//         displayName="Clock In Date"
//         sort={sort}
//         onHeaderClick={onHeaderClick}
//         columnType="date"
//       />
//       <ActiveHeader
//         columnName="ClockInTime"
//         displayName="Clock In Time"
//         sort={sort}
//         onHeaderClick={onHeaderClick}
//         columnType="time"
//       />
//       <ActiveHeader
//         columnName="ClockOutDate"
//         displayName="Clock Out Date"
//         sort={sort}
//         onHeaderClick={onHeaderClick}
//         columnType="date"
//       />
//       <ActiveHeader
//         columnName="ClockOutTime"
//         displayName="Clock Out Time"
//         sort={sort}
//         onHeaderClick={onHeaderClick}
//         columnType="time"
//       />
//       <ActiveHeader
//         columnName="TimeAllocation"
//         displayName="Hours"
//         sort={sort}
//         onHeaderClick={onHeaderClick}
//         columnType="number"
//       />
//       <th />
//     </tr>
//   </thead>
// );

// const renderEntries = ({entries, ...props}) => (
//   <tbody>
//     {entries.map(e => <ApprovalDetailRow {...props} entry={e} key={e.id} />)}
//   </tbody>
// );

// const renderNoEntries = () => (
//   <tbody>
//     <tr>
//       <td colSpan="99" className="no-entries">
//         <div className="alert alert-warning">
//           No time has been submitted for approval this week
//         </div>
//       </td>
//     </tr>
//   </tbody>
// );

export default hoc(ApprovalDetail);