import defaultLabels from '../../timesheet/api/labels';

export default {
  lockedReason: {
    ...defaultLabels.lockedReason,
    MES: 'Please toggle the "Detail View" to edit this entry',
    'NOT SUBMITTED': 'Time is not Submitted'
  },
  noteLockedReason: {
    ...defaultLabels.noteLockedReason
  },
  percentCompleteLockedReason: {
    ...defaultLabels.percentCompleteLockedReason
  },
  opOutlookLockedReason: {
    ...defaultLabels.opOutlookLockedReason
  },
  percentCompleteTooltipReason: {
    ...defaultLabels.percentCompleteTooltipReason
  }
};
