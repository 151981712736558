export default {
  lockedReason: {
    CLOSED: "This job has been closed and can't be edited or recalled",
    MES: 'This MES time entry is read only and cannot be edited',
    SUBMITTED: 'Time is Submitted',
    APPROVED: 'Time is Approved',
    POSTED: 'Time has been Posted',
    ROADPAY: 'You do not currently have Road Pay eligible hours on this day',
    DAY_NOT_CONTRACTED: 'This day is not contracted',
    OP_COMPLETE: 'Cannot edit time when operation is completed',
    APPROVED_OP_COMPLETE: 'Please un-complete the Op using the Percent Complete dropdown to edit this time.'
  },
  noteLockedReason: {
    MULTIPLE_NARRATIVES: `There are multiple notes associated with this row, go to the detail view to review each note`,
    NO_ROW_ENTRIES: 'Cannot enter a note when no time has been entered'
  },
  percentCompleteLockedReason: {
    INDIRECT_ROW: 'Cannot update percent complete on indirect labor',
    NO_ACTIVE_TIME: 'Cannot update percent complete with no active records'
  },
  opOutlookLockedReason: {
    INDIRECT_ROW: 'Cannot update outlook on indirect labor',
    NO_ACTIVE_TIME: 'Cannot update outlook with no active records'
  },
  percentCompleteTooltipReason: {
    OP_COMPLETE: 'This operation is now complete and no one else can find it to clock to'
  }
};