import PropTypes from 'prop-types';
import entryShape from './entryShape';

const lookupShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});
const jobShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  Closed: PropTypes.bool
});
const opShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  Rework: PropTypes.bool,
  RunQty: PropTypes.number,
  CompletedQty: PropTypes.number,
  Outlook: PropTypes.number,
  OpComplete: PropTypes.bool
})

export default PropTypes.shape({
  Project: lookupShape,
  Job: jobShape,
  Operation: opShape,
  IndirectCode: lookupShape,
  ReworkCode: lookupShape,
  Narrative: PropTypes.string,
  entries: PropTypes.arrayOf(entryShape).isRequired,
  chargeable: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired
});
