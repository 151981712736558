import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';

// A modal dialog that will cause a page refresh when it is dismissed
const FatalErrorDialog = ({message}) => {
  const onClose = () => {
    window.location.href = window.location.href;
  };

  return (
    <Dialog
      confirmLabel="OK"
      title="An error has occurred"
      onConfirm={onClose}
      showCancel={false}
      onDismiss={onClose}>
      <p dangerouslySetInnerHTML={{__html: message}} />
    </Dialog>
  );
};
FatalErrorDialog.propTypes = {
  message: PropTypes.string.isRequired
};

export default FatalErrorDialog;
