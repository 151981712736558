import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Banner = ({bgStyle = 'default', text}) => {
  const cls = classNames('banner', {
    'banner-default': /default/i.test(bgStyle),
    'banner-primary': /primary/i.test(bgStyle),
    'banner-info': /info/i.test(bgStyle),
    'banner-success': /success/i.test(bgStyle),
    'banner-warning': /warning/i.test(bgStyle),
    'banner-error': /error/i.test(bgStyle),
    'banner-unsubmitted': /unsubmitted/i.test(bgStyle)
  });

  return (
    <Container className={cls}>
      <strong>{text}</strong>
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 0 3px 6px 0px #8e8e8e;
  display: block;
  padding: 15px;
  text-align: center;

  &:before {
    box-shadow: 0 3px 6px 0px #8e8e8e;
    content: ' ';
  }

  &.banner-default {
    background-color: #f6f6f6;
    color: #5d5d5d;
  }

  &.banner-primary {
    background-color: #98b6e2;
    color: #102038;
  }

  &.banner-info {
    background-color: #9bd8eb;
    color: #1b6d85;
  }

  &.banner-warning {
    background-color: #fcdab2;
    color: #784304;
  }

  &.banner-error {
    background-color: #fff2f2;
    color: #ba0505;
  }

  &.banner-success {
    background-color: #aae3c6;
    color: #16452d;
  }

  &.banner-unsubmitted {
    background-color: #c9b7db;
    color: #3b1a57;
  }
`;

Banner.propTypes = {
  bgStyle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Banner;
