import React from 'react';
import PropTypes from 'prop-types';

import rowShape from './shapes/rowShape';
import ProjectOverlay from './ProjectOverlay';
import ProjectSelection from './ProjectSelection';
import UpdateSelection from './UpdateSelection';
import EditRowOverlay from './EditRowOverlay';

const Project = ({
  company,
  deleteRow,
  hasProjectSelection,
  isEdit,
  isRowEditable,
  onFocus,
  row,
  rowIndex,
  setActiveRow,
  setEditRow,
  setError,
  timesheet,
  updateRow,
  onComplete
}) => {
  const props = {
    company,
    row,
    rowIndex,
    setError,
    timesheet,
    updateRow
  };
  const _onCancelRowEdit = () => {
    setEditRow(null);
  };

  const _onDeleteRow = () => {
    setActiveRow(null);
    deleteRow(rowIndex);
  };

  const _onEditRow = () => {
    setEditRow(rowIndex);
  };

  const _onComplete = () => {
    setActiveRow(null);
    onComplete && onComplete();
  }

  if (!hasProjectSelection) {
    return <ProjectSelection {...props} />;
  }  

  if (isEdit) {
    return (
      <UpdateSelection
        onCancel={_onCancelRowEdit}
        onCompleted={_onComplete}
        onDelete={_onDeleteRow}
        {...props}
      />
    );
  }

  return (
    <React.Fragment>
      {hasProjectSelection &&
        !isEdit && <EditRowOverlay disabled={!isRowEditable} onEdit={_onEditRow} />}
      <ProjectOverlay onClick={onFocus} row={row} />
    </React.Fragment>
  );
};

Project.propTypes = {
  deleteRow: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isRowEditable: PropTypes.bool.isRequired,
  hasProjectSelection: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  row: rowShape.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  setEditRow: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  timesheet: PropTypes.object.isRequired,
  updateRow: PropTypes.func.isRequired
};

export default Project;
