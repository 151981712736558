import moment from 'moment';

export default (timesheet, i) => ({
  __typename: 'TimesheetEntry',
  id: 'TMP_' + Math.random(),
  Date: moment(timesheet.PeriodStartDate)
    .add(i, 'days')
    .format('YYYY-MM-DD'),
  Detail: [],
  LaborHed: [],
  OvernightCompensation: false,
  Approved: false,
  Submitted: false,
  PostedToGL: false,
  PayType: ''
});
