import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';

import TimeDialog from '../../shared/components/time-dialog';

/**
 * <Submit />
 * Submit button for timesheet
 */
export default class Submit extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    forDashboard: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    timesheet: PropTypes.object.isRequired
  };

  state = {
    isDialogOpen: false,
    isSubmitAndApproveDialogOpen: false
  };

  _onCloseDialog() {
    this.setState({
      isDialogOpen: false,
      isSubmitAndApproveDialogOpen: false
    });
  }

  _onOpenDialog() {
    const isDialogOpen = true;
    this.setState({isDialogOpen});
  }

  _onOpenSubmitAndApproveDialog() {
    this.setState({
      isSubmitAndApproveDialogOpen: true
    });
  }

  _onSubmit(dates) {
    this.props.onSubmit(dates);
    this._onCloseDialog();
  }

  _onSubmitAndApprove(dates) {
    this.props.onSubmitAndApprove(dates);
    this._onCloseDialog();
  }

  renderButton() {
    const {canApprove, disabled, forDashboard, mobile} = this.props;
    const cls = classNames('btn timesheet--submit', {
      'actions-sub-button': forDashboard || mobile,
      'desktop-control': !mobile
    });

    if (!canApprove || disabled) {
      return (
        <button
          className={cls}
          disabled={disabled}
          onClick={() => this._onOpenDialog()}>
          <i className="fa fa-lg fa-check-circle-o" aria-hidden="true" />
          Submit
        </button>
      );
    }

    return (
      <DropdownButton
        // This style prevents the button from remaining in a stuck focus state.
        bsStyle="tait"
        pullRight
        className={cls}
        id="submit"
        title={
          <span>
            <i className="fa fa-lg fa-arrow-circle-o-down" aria-hidden="true" />
            Submit
          </span>
        }>
        <MenuItem eventKey="1" onClick={() => this._onOpenDialog()}>
          Submit
        </MenuItem>
        <MenuItem
          eventKey="2"
          onClick={() => this._onOpenSubmitAndApproveDialog()}>
          Submit &amp; Approve
        </MenuItem>
      </DropdownButton>
    );
  }

  render() {
    const {timesheet} = this.props;

    const totals = timesheet.totals.map(total => {
      let message;
      if (total.AllPosted) {
        message = 'Already Posted';
      } else if (total.AllApproved) {
        message = 'Already Approved';
      } else if (total.AllSubmitted) {
        message = 'Already Submitted';
      } else if (total.Date > Date.now()) {
        message = 'Future Date';
      } else if (total.Total > 24) {
        message = 'Hours cannot be greater than 24'
      }

      return {
        ...total,
        Message: message,
        Disabled:
          // TODO: set disabled state according to cell state, not whole day
          total.Date > Date.now() ||
          total.AllApproved ||
          total.AllPosted ||
          total.AllSubmitted ||
          total.Total > 24
      };
    });

    return (
      <div>
        {this.renderButton()}
        {this.state.isDialogOpen && (
          <TimeDialog
            heading="By submitting this timesheet, you agree that it is complete and accurate."
            onClose={() => this._onCloseDialog()}
            onSave={dates => this._onSubmit(dates)}
            totals={totals}
            title={`Submit Selected Days for ${timesheet.EmployeeName}`}
          />
        )}
        {this.state.isSubmitAndApproveDialogOpen && (
          <TimeDialog
            heading="By approving this timesheet, you agree that it is complete and accurate."
            onClose={() => this._onCloseDialog()}
            onSave={dates => this._onSubmitAndApprove(dates)}
            totals={totals}
            title={`Submit & Approve Selected Days for ${
              timesheet.EmployeeName
            }`}
          />
        )}
      </div>
    );
  }
}
