import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Button = styled.button`
  background: transparent;

  // I don't want to highlight them when they are focused
  &:focus:not(:hover) {
    color: inherit;
  }
`;

const ClearButton = ({disabled, className, children, onClick, label}) => (
  <Button
    className={classNames('btn', className)}
    onClick={onClick}
    aria-label={label}
    disabled={disabled}>
    {children}
  </Button>
);

export default ClearButton;
