import {graphql} from 'react-apollo';

import {SUBMIT_TIMESHEET_MUTATION} from '../../queries';
import {buildTsInput} from '../../../shared/utils/queries';

export default graphql(SUBMIT_TIMESHEET_MUTATION, {
  props: ({
    ownProps: {timesheet, setLoading, setError, setSuccess, clearLoading},
    mutate
  }) => ({
    submitTimesheet(selectedDates) {
      const dates = timesheet.totals
        .map(x => x.Date.format('YYYY-MM-DD'))
        .filter(x => selectedDates.includes(x));

      if (!dates.length) {
        return setError('There are no days which can be submitted.');
      }

      setLoading();

      return mutate({
        variables: {
          ...buildTsInput(timesheet),
          dates
        }
      })
        .then(
          () => {
            setSuccess('Timesheet has been submitted');
          },
          e => setError(e)
        )
        .then(clearLoading);
    }
  })
});
