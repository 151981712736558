import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import timesheetData from '../../timesheet/hoc/timesheetData';

import CalendarDialog from '../../shared/components/CalendarDialog';
import ConfirmationDialog from '../../shared/components/ConfirmationDialog';
import Spinner from '../../shared/components/Spinner';

/**
 * <TimesheetBasePage />
 * Base for timesheet and approval pages.
 * Children will be rendered inside.
 * Manages the transition between timesheets (different employees and different dates)
 */
class TimesheetBasePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionDirection: 'right',
      transitionActive: false,
      previousTimesheet: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.timesheet &&
      this.props.timesheet &&
      this.props.timesheet.id !== nextProps.timesheet.id
    ) {
      const oldTs = this.props.timesheet,
        newTs = nextProps.timesheet;
      this.setState({
        transitionDirection:
          oldTs.EmployeeNum !== newTs.EmployeeNum
            ? 'up'
            : oldTs.PeriodStartDate < newTs.PeriodStartDate ? 'right' : 'left',
        previousTimesheet: oldTs
      });
      setTimeout(() => {
        this.setState({
          transitionActive: true
        });
      }, 50);
      setTimeout(() => {
        this.setState({
          previousTimesheet: null,
          transitionActive: false
        });
      }, 500);
    }
  }

  // renders a timesheet view, using the view defined by the children components.
  renderTimesheet(timesheet, entering) {
    const props = this.props;

    return (
      <div
        key={timesheet.id}
        className={classNames(
          'timesheet',
          'slide-page',
          this.state.transitionDirection,
          {
            enter: entering && this.state.previousTimesheet !== null,
            active: this.state.transitionActive,
            exit: !entering && this.state.previousTimesheet !== null
          }
        )}>
        {/* make sure we render the children with the timesheet that was passed */}
        {props.children({...omit(props, ['children']), timesheet})}
      </div>
    );
  }

  render() {
    const props = this.props;

    let cls = 'timesheet-container has-spinner';
    if (props.uiState.switchingTimesheet) {
      cls += ' switching';
    }
    return (
      <div className={cls}>
        {(props.loading || props.uiState.loading) && <Spinner />}
        {props.timesheet && this.renderTimesheet(props.timesheet, true)}

        {this.state.previousTimesheet &&
          this.renderTimesheet(this.state.previousTimesheet, false)}
        <ConfirmationDialog
          dialogState={props.uiState.confirmDialog}
          {...props}
        />
        <CalendarDialog
          onDateSelected={d => props.goToTimesheet(d)}
          onClosed={props.toggleCalendar}
          show={props.uiState.showCalendar}
          current={props.timesheet.PeriodStartDate}
        />
      </div>
    );
  }
}
TimesheetBasePage.propTypes = {
  uiState: PropTypes.shape({
    savePending: PropTypes.object.isRequired
  }).isRequired,
  timesheet: PropTypes.object.isRequired,
  clearMessages: PropTypes.func.isRequired,
  goToTimesheet: PropTypes.func.isRequired
};

export default timesheetData(TimesheetBasePage);
