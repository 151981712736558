import React from 'react';
import styled from 'styled-components';

const BackButton = ({back, isRoot, loading}) => (
  <Button className="back-button" onClick={back} disabled={isRoot || loading} />
);

const Button = styled.button`
  background: none;
  border: none;
  color: #959595;
  display: inline-block;
  height: 34px;
  /* width: 20px; */
  line-height: 34px;
  float: left;
  font-size: 18px;
  cursor: pointer;
  padding: 0px 7px 0 0;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    color: #333;
  }

  &:after {
    content: '\f060';
    font-family: FontAwesome;
  }
`;

export default BackButton;
