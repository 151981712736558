import React from 'react'
import Tooltip from 'rc-tooltip'
import PropTypes from 'prop-types';
import {DropdownButton, Button, MenuItem} from 'react-bootstrap';

// disable proptype check on the bsStyle
// we are intentionally passing an invalid style to prevent having the default BS style applied..
// this yields spurious warnings in development.
// The following statements suppress the check on the bsStyle prop
delete DropdownButton.propTypes.bsStyle;
delete Button.propTypes.bsStyle;

const CloneOverlay = ({ disabled, copyPreviousWeekWithTime, copyPreviousWeek }) => {
  const content = (
    <DropdownButton
      // This style prevents the button from remaining in a stuck focus state.
      bsStyle="tait"
      disabled={disabled}
      id="copy-last-week"
      title={
        <span>
          Copy Last Week <i className="fa fa-clone" />{' '}
        </span>
      }>
      <MenuItem eventKey="1" onClick={copyPreviousWeekWithTime}>
        Copy Records with Time
      </MenuItem>
      <MenuItem eventKey="2" onClick={copyPreviousWeek}>
        Copy Records without Time
      </MenuItem>
    </DropdownButton>
  )

  if (disabled) {
    return (
      <Tooltip
        overlay={<span>Can't copy last week when a day has been submitted for the current week</span>}
      >
        <span>{content}</span>
      </Tooltip>
    )
  }

  return content
}

CloneOverlay.propTypes = {
  copyPreviousWeek: PropTypes.func.isRequired,
  copyPreviousWeekWithTime: PropTypes.func.isRequired
}

export default CloneOverlay