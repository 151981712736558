import React from 'react';
import classNames from 'classnames'

import OnCallCellOverlay from './OnCallCellOverlay';
import TimesheetDayTitles from './TimesheetDayTitles';

function OnCallSelectionRow({
  labels,
  mode,
  onClear,
  onSelect,
  optionsBuilder,
  row,
  savePending,
  totals,
  company
}) {
  const titleClass = classNames('title col-xs-12', {
    'col-md-2': (company === '100' || company === '700' || company === '770'),
    'col-md-1': (company !== '100' && company !== '700' && company !== '770')
  })
  const totalClass = classNames('total col-xs-12', {
    'col-md-2': (company === '100' || company === '700' || company === '770'),
    'col-md-3': (company !== '100' && company !== '700' && company !== '770')
  })
  const daysClass = classNames('days col-xs-12', {
    'col-md-6': (company === '100' || company === '700' || company === '770'),
    'col-md-7': (company !== '100' && company !== '700' && company !== '770')
  })
  return (
    <div className="timesheet-line row timesheet-days timesheet-line--oncall">
      <div className={titleClass}>
        <span>{row.Description}</span>
      </div>
      <div className={totalClass}>
        {row.TotalDays} day{row.TotalDays === 1 ? '' : 's'}
      </div>
      <div className={daysClass}>
        <TimesheetDayTitles entries={row.entries} />
        <div className="day-inputs col-md-12 col-xs-9">
          {row.entries.map((entry, i) => (
            <OnCallCellOverlay
              options={optionsBuilder(i)}
              checked={entry.OnCall}
              onChange={onSelect}
              approved={totals[i].Approved}
              date={entry.Date}
              invalidSelection={entry.InvalidSelection}
              isSavePending={savePending[entry.id] || savePending[entry.Date]}
              key={entry.Date}
              labels={labels}
              mode={mode}            
              posted={totals[i].Posted}
              selection={entry.OnCall}
              submitted={totals[i].Submitted}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OnCallSelectionRow;
