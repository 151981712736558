import range from 'lodash/range';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import roundTime from './roundTime';
const {INVALID_INDIRECT_INLIEU_CODES} = require('../../../src/constants')

// calculate the timesheet totals (both for the bottom, and at row level)
// return updated timesheet
export default function calculateTimesheetValues(timesheet) {
  let hasWeekendTime = false;
  const startDate = moment(timesheet.PeriodStartDate);
  const totals = Array(7)
    .fill(null)
    .map((_, i) => ({
      Approved: false,
      Chargeable: 0,
      Date: moment(startDate).add(i, 'days'),
      NonChargeable: 0,
      Posted: false,
      TimeOff: 0,
      Submitted: false,
      InLieu: 0
    }));
  const overnightRow = {
    Description: 'On the Road',
    TotalDays: 0,
    entries: makeBlankEntries(startDate, {Selection: ''})
  };
  const rows = timesheet.rows.map(row => {
    let rowTotal = 0;
    const chargeable = !row.IndirectCode;    
    let rowHasWeekendTime = false;
    const entries = row.entries.map(entry => ({
      ...entry,
      // in most places we use the time allocation at the entry level, not at the detail level
      TimeAllocation: sumBy(entry.Detail, d => d.TimeAllocation) || 0
    }));
    entries.forEach((entry, i) => {
      if (!isTempEntry(entry)) {
        if (entry.Approved) {
          totals[i].Approved = true;
        } else {
          totals[i].AllApproved = false;
        }

        if (entry.Submitted) {
          totals[i].Submitted = true;
        } else {
          totals[i].AllSubmitted = false;
        }

        if (entry.PostedToGL) {
          totals[i].Posted = true;
        } else {
          totals[i].AllPosted = false;
        }
      }
      
      if (timesheet.timeOff[i].PayHours) {
        totals[i].TimeOff = timesheet.timeOff[i].PayHours;
      } else {
        totals[i].TimeOff = 0;
      }

      if (row.IndirectCode && (Object.values(INVALID_INDIRECT_INLIEU_CODES).indexOf(row.IndirectCode.value) === -1)) {
        totals[i].InLieu += entry.TimeAllocation
      }

      if (chargeable) {
        totals[i].Chargeable += entry.TimeAllocation;
      } else {
        totals[i].NonChargeable += entry.TimeAllocation;
      }

      if (entry.OvernightCompensation) {
        // set overnight comp code on the overnight row        
        Object.assign(overnightRow.entries[i], {Selection: row.id});
        overnightRow.TotalDays += 1;
      }

      rowTotal += entry.TimeAllocation;

      if (entry.TimeAllocation > 0 && i >= 5) {
        rowHasWeekendTime = true;
      }
    });
    hasWeekendTime = hasWeekendTime || rowHasWeekendTime;
    return {
      ...row,
      entries,
      chargeable,
      closed: !!(row.Job && row.Job.Closed),
      hasWeekendTime: rowHasWeekendTime,
      total: rowTotal
    };
  });
  totals.forEach(t => {
    t.Total = roundTime(t.Chargeable + t.NonChargeable + t.TimeOff);
    if (typeof t.AllApproved === 'undefined') t.AllApproved = t.Approved;
    if (typeof t.AllSubmitted === 'undefined') t.AllSubmitted = t.Submitted;
    if (typeof t.AllPosted === 'undefined') t.AllPosted = t.Posted;
  });
  const inLieuRow = makeInLieuRow(timesheet, totals);
  const onCallRow = makeOnCallRow(timesheet, totals);

  return {
    ...timesheet,
    grandTotal: roundTime(sumBy(totals, t => t.Total)),
    hasWeekendTime,
    overnightRow,    
    inLieuRow,
    onCallRow,
    isApproved: totals.every(x => x.AllApproved),
    isPosted: totals.every(x => x.AllPosted),
    isSubmitted: totals.every(x => x.AllSubmitted),
    isSomeSubmitted: totals.some(x => x.Submitted),
    rows,
    totals
  };
}

function makeOnCallRow(timesheet, totals) {
  return {
    Description: 'On Call',
    entries: timesheet.timeOnCall.map((e, i) => ({
      OnCall: e.OnCall || false,
      Date: e.Date,
      InvalidSelection:
        !!e.OnCall &&
        !(totals[i] && totals[i].total) &&
        'No hours entered on this date',
      id: e.id
    })),
    TotalDays: timesheet.timeOnCall.reduce(
      (acc, v, i) =>
        acc + (v.OnCall ? 1 : 0),
      0
    )
  }
}

function makeInLieuRow(timesheet, totals) {
  return {
    Description: 'In Lieu',
    entries: timesheet.timeInLieu.map((e, i) => ({
      Selection: e.InLieuType,
      Date: e.Date,
      InvalidSelection:
        !!e.InLieuType &&
        !(totals[i] && totals[i].Total) &&
        'No hours entered on this date',
      id: e.id
    })),
    TotalDays: timesheet.timeInLieu.reduce(
      (acc, v, i) =>
        acc + (v.InLieuType && totals[i] ? getInLieuDays(totals[i].InLieu + totals[i].Chargeable) : 0),
      0
    )
  };
}

function getInLieuDays(totalHours) {
  if (totalHours > 4) return 1;
  if (totalHours > 0) return 0.5;
  return 0;
}

function makeBlankEntries(startDate, data = {}) {
  return range(7).map(i => {
    const date = moment(startDate)
      .add(i, 'days')
      .format('YYYY-MM-DD');
    return {
      id: `TMP_${date}`,
      Date: date,
      ...data
    };
  });
}

function isTempEntry(e) {
  return e.id.startsWith('TMP');
}
