import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import buildProjectOptions from '../api/buildProjectOptions';
import {inLieuOptions} from '../../constants';

import Banner from '../../shared/components/Banner';
import DayTypeSelectionRow from './DayTypeSelectionRow';
import TimesheetFooter from './TimesheetFooter';
import TimesheetHeaderRow from './TimesheetHeaderRow';
import TimesheetLine from './TimesheetLine';
import TimesheetSubTotals from './TimesheetSubTotals';
import TimesheetWidgetTotals from './TimesheetWidgetTotals';
import OnCallSelectionRow from './OnCallSelectionRow';

const buildInLieuOptions = () => inLieuOptions;

const matches = function(el, selector) {
  return (
    el.matches ||
    el.matchesSelector ||
    el.msMatchesSelector ||
    el.mozMatchesSelector ||
    el.webkitMatchesSelector ||
    el.oMatchesSelector
  ).call(el, selector);
};

/**
 * <TimesheetDetail />
 * View/Edit the timesheet rows.
 * This is used for the regular timesheet view as well as the summary approval view.
 *
 * Children components are rendered inside and will be passed the same props.
 */
export default class TimesheetDetail extends Component {
  static propTypes = {
    activeEntry: PropTypes.number,
    activeRow: PropTypes.number,
    editRow: PropTypes.number,
    forDashboard: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    savePending: PropTypes.object.isRequired,
    timesheet: PropTypes.object.isRequired,
    uiState: PropTypes.object.isRequired
  };

  _handleMouseDown = e => {
    if (
      !matches(e.target, '.timesheet-line--active, .timesheet-line--active *')
    ) {
      this.props.setActiveRow(null);
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this._handleMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleMouseDown);
  }

  render() {
    const {
      activeRow,
      clearInLieu,
      clearOvernightComp,
      editRow,
      forDashboard,
      labels,
      mode,
      saveOnCall,
      saveInLieu,
      saveOvernightComp,
      savePending,
      timesheet,
      setTimesheetUser
    } = this.props;

    const cls = classNames('timesheet-detail clearfix', {
      weekends: true,
      'timesheet--save-pending': savePending[timesheet.id]
    });

    const isTimesheetReadOnly = timesheet.totals.every(
      x => x.Approved || x.Posted || (mode === 'timesheet' && x.Submitted)
    );

    const headerProps = {
      labels,
      mode,
      isTimesheetReadOnly,
      startDate: timesheet.PeriodStartDate,
      totals: timesheet.totals,
      company: timesheet.Company
    };

    // we need to change the label description based on company
    timesheet.overnightRow.Description = this.props.timesheetSource.Company === '100'
                                          ? 'On the Road'
                                          : 'Overnight'

    const isApproved = !timesheet.isPosted && timesheet.isApproved;
    const isSubmitted =
      !(timesheet.isPosted || timesheet.isApproved) && timesheet.isSubmitted;
    const unsubmittedTime = timesheet.UnsubmittedTime.length > 0  

    return (
      <div className={cls}>
        {timesheet.isPosted && <Banner bgStyle="default" text="Posted" />}
        {isApproved && <Banner bgStyle="success" text="Approved" />}
        {mode === 'timesheet' &&
          isSubmitted && <Banner bgStyle="info" text="Submitted" />}
        {unsubmittedTime &&
           <Banner 
              bgStyle='unsubmitted' 
              text={
                <div>
                  <div>
                    {this.props.currentUser.id === timesheet.EmployeeNum + ":" + timesheet.Company
                      ? `You have unsubmitted time for the week${timesheet.UnsubmittedTime.length === 1 ? '' : 's'} of`
                      : `${timesheet.EmployeeName} has unsubmitted time for the week${timesheet.UnsubmittedTime.length === 1 ? '' : 's'} of`}
                  </div> 
                  <div>
                    {timesheet.UnsubmittedTime
                      .map(time => <Link onClick={() => setTimesheetUser({value: timesheet.EmployeeNum + ":" + timesheet.Company})} to={`/timesheet/${time}`} className='timesheet-detail-unsubmitted--link'>{time}</Link>)
                      .reduce((prev, curr) => [prev, ' | ', curr])}
                  </div>
                </div>
              }
            />
        }
        {React.cloneElement(React.Children.only(this.props.children), {
          ...this.props,
          ...headerProps
        })}
        {/* Project Header, with the days (on desktop) */}
        <TimesheetHeaderRow {...headerProps} />
        <div className="timesheet-detail-scroll">
          <div className="timesheet-detail-inner col-md-12">
            {timesheet.rows.map((row, i) => (
              <TimesheetLine
                key={i}
                isActive={i === activeRow}
                isEdit={i === editRow}
                isSavePending={
                  savePending[row.id] || savePending[timesheet.id] || false
                }
                isTimesheetReadOnly={isTimesheetReadOnly}
                mode={mode}
                row={row}
                rowIndex={i}
                {...this.props}
              />
            ))}
            <TimesheetSubTotals totals={timesheet.totals} {...headerProps} />
            {/* In Lieu / Overnight rows exclude companies 500 and 510 for now */}
            {this.props.timesheetSource.Company !== '500' && this.props.timesheetSource.Company !== '510' &&  (
              <DayTypeSelectionRow
                {...headerProps}
                onClear={clearOvernightComp}
                onSelect={saveOvernightComp}
                optionsBuilder={buildProjectOptions(timesheet)}                            
                row={timesheet.overnightRow}              
                savePending={savePending}
              />
            )}             
            {/* In Lieu for UK only */}
            {this.props.timesheetSource.Company !== '100' && (
            <DayTypeSelectionRow
              {...headerProps}
              onClear={clearInLieu}
              onSelect={saveInLieu}
              optionsBuilder={buildInLieuOptions}              
              row={timesheet.inLieuRow}
              savePending={savePending}
            />
            )}
            {/* on call for US only and is employee
            record has the On Call checkbox checked */}    
            {this.props.timesheetSource.OnCallEmployee === true && ( //04-29-2024 MJP Removed Company Filter
            <OnCallSelectionRow
              {...headerProps}
              row={timesheet.onCallRow}
              optionsBuilder={buildProjectOptions(timesheet)}
              onSelect={saveOnCall}
              savePending={savePending}
            />      
            )}    
            {forDashboard && <TimesheetWidgetTotals {...headerProps} />}
          </div>
        </div>
        {!forDashboard && <TimesheetFooter {...this.props} />}
      </div>
    );
  }
}