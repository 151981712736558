import React from 'react';
import PropTypes from 'prop-types';
import LastWeek from './LastWeek';
import NextWeek from './NextWeek';
import CalendarHeader from './CalendarHeader';
import EmployeeNameHeader from './EmployeeNameHeader';

function TimesheetNavigation(props, context) {
  var currentDate = props.timesheet.PeriodStartDate;
  return (
    <div className="timesheet-navigation">
      <div className="timesheet-controls container-fluid">
        <nav>
          {' '}
          <LastWeek {...props} current={currentDate} />{' '}
        </nav>
        <div className="timesheet-header">
          <CalendarHeader
            current={currentDate}
            uiState={props.uiState}
            {...props}
          />
          <EmployeeNameHeader
            currentUser={props.currentUser}
            timesheet={props.timesheet}
          />
        </div>
        <nav>
          {' '}
          <NextWeek {...props} current={currentDate} />{' '}
        </nav>
      </div>
    </div>
  );
}
TimesheetNavigation.propTypes = {
  timesheet: PropTypes.object.isRequired,
  uiState: PropTypes.object.isRequired
};

export default TimesheetNavigation;
