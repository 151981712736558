import {withHandlers} from 'recompose';

export default withHandlers({
  submitAndApproveTimesheet: ({
    submitTimesheet,
    approveTimesheet
  }) => selectedDates => {
    return submitTimesheet(selectedDates).then(() =>
      approveTimesheet(selectedDates)
    );
  }
});
