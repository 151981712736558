// HOC for calculating an inner component height
// This will provide 2 props to the contained component:
//  - calcHeight is the calculated height
//  - calcHeightRef is a function to assign as `ref` to the component for which we want to have the height calculated
//  This needs to be a DOM node, NOT a react component.

import {withStateHandlers} from 'recompose';

export default minHeight =>
  withStateHandlers(
    {calcHeight: minHeight},
    {
      calcHeightRef: () => ref => {
        //const node = ReactDOM.findDOMNode(ref)
        return ref && {calcHeight: Math.max(minHeight, ref.offsetHeight)};
      }
    }
  );
