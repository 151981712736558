export default function(detail) {
  return {    
      id: detail ? detail.id : '0/0',
      TimeAllocation: detail ? detail.TimeAllocation : 0,
      ClockInDate: detail?  detail.ClockInDate : '',
      ClockInTime: detail ? detail.ClockInTime : 0,
      ClockOutDate: detail ? detail.ClockOutDate : '',
      ClockOutTime: detail ? detail.ClockOutTime : 0,
      Narrative: detail ? detail.Narrative : ''    
  }
}