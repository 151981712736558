import React from 'react';

const EmployeeNameHeader = ({currentUser, timesheet}) => {
  if (
    currentUser.EmployeeNum === timesheet.EmployeeNum &&
    currentUser.Company === timesheet.Company
  ) {
    return null;
  }
  return (
    <span className="timesheet-employee-header">
      ({timesheet.EmployeeName})
    </span>
  );
};

export default EmployeeNameHeader;
