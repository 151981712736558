import {ApolloLink, Observable} from 'apollo-link';

// A custom link that will ensure requests are submitted in a sequence, not in parallel
// - if a request has a context key "serializeRequest" set to true, it will always be serialized
// - if a request has a context key "serializeRequest" set to false, or enableByDefault is false,
// it won't be serialized
// - queries are never serialized, unless they have a "serializeRequest" context key set to true
class SerializingLink extends ApolloLink {
  constructor(enableByDefault) {
    super();
    this.previous = null;
    this.enableByDefault = enableByDefault;
  }

  request(operation, forward) {
    // console.log('Operation entering SerializingLink', operation.toKey())
    if (!this.shouldSerializeRequest(operation)) {
      return forward(operation);
    }
    const previous = this.previous;
    // console.log('Waiting to fire...')
    const subscribers = [];
    // observable that will be subcribed to only once
    // we have to be careful not to accidentally subscribe more than once to the result of the
    // operation, because each time we subscribe it will re-send the request
    // likewise, we use the singleObserver as a proxy so that other requests won't subscribe more
    // than once to it
    const singleObserver = new Observable(observer => {
      // console.log('in single observer')
      const subs = [];
      if (!previous) {
        // console.log('firing immediately')
        subs.push(forward(operation).subscribe(observer));
      } else {
        const prevSub = previous.subscribe({
          next() {
            // not interested
          },
          error() {
            // not interested
          },
          complete() {
            // console.log('Completed, firing new operation', operation.toKey())
            subs.push(forward(operation).subscribe(observer));
          }
        });
        subs.push(prevSub);
      }
      return () => {
        subs.forEach(s => s.unsubscribe());
      };
    });
    let completed = false;
    // a single subscription to our observer proxy
    const subscription = singleObserver.subscribe({
      next(result) {
        subscribers.forEach(s => s.next(result));
      },
      error(err) {
        subscribers.forEach(s => s.error(err));
      },
      complete() {
        subscribers.forEach(s => s.complete());
        // use setImmediate in case the operation completes synchronously
        setImmediate(() => subscription.unsubscribe());
        completed = true;
      }
    });
    // this is the one that people will subscribe to
    const sharedObserver = new Observable(observer => {
      // console.log('running shared observer subscription')
      if (completed) {
        observer.complete();
      } else {
        subscribers.push(observer);
      }
    });
    this.previous = sharedObserver;
    return sharedObserver;
  }

  shouldSerializeRequest(operation) {
    if (operation.getContext().serializeRequest === true) return true;
    if (operation.getContext().serializeRequest === false) return false;
    for (let def of operation.query.definitions) {
      if (def.kind === 'OperationDefinition' && def.operation === 'mutation')
        return this.enableByDefault;
    }
    // query?
    return false;
  }
}

export default SerializingLink;
