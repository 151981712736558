import React from 'react'
import PropTypes from 'prop-types'

class SubmitButton extends React.Component {  
  render() {
    return (
      <button
        {...this.props}
      >
        {this.props.buttontext}
      </button>
    )
  }
}

SubmitButton.propTypes = {
  buttontext: PropTypes.string.isRequired
}

export default SubmitButton