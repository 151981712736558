import {compose, withState, lifecycle} from 'recompose';

const appearingTransitionContainer = compose(
  withState('isAppearing', 'setIsAppearing', true),
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        this.props.setIsAppearing(false);
      }, 25);
    }
  })
);

export default appearingTransitionContainer;
