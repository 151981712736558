import PropTypes from 'prop-types'
import * as React from 'react';
import MaterialTable from 'material-table'
import dateUtils from '../../shared/utils/dateUtils';
import NoteIndicator from '../../timesheet/components/NoteIndicator';
import getDayOfWeek from '../../utils/getDayOfWeek'
import { createMuiTheme, Container } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Project from '../../timesheet/components/Project'
import getEntryLockedReason from '../../timesheet/api/getEntryLockedReason'
import TimesheetNumberCellOverlay from '../../timesheet/components/TimesheetNumberCellOverlay'
import moment from 'moment';

const matches = function(el, selector) {
    return (
      el.matches ||
      el.matchesSelector ||
      el.msMatchesSelector ||
      el.mozMatchesSelector ||
      el.webkitMatchesSelector ||
      el.oMatchesSelector
    ).call(el, selector);
  };

class ApprovalDetailView extends React.Component{
    _handleMouseDown = e => {
        if (
            !matches(e.target, '.timesheet-line--project *')
        ) {
        this.props.setEditRow(null);
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this._handleMouseDown)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._handleMouseDown)
    }

    onComplete = () => {
        this.props.setEditRow(null)
    }

    onChange = (entry, hours) => {
        this.props.updateTime({
            rowIndex: entry.rowIndex,
            entryIndex: entry.entryIndex,
            detailIndex: entry.detailIndex,
            hours
        })
    }

    onDeleteEntry = (rowIndex, entryIndex, detailIndex) => {
        this.props.deleteEntry({
            rowIndex: rowIndex,
            entryIndex: entryIndex,
            detailIndex: detailIndex
        });
    };

    findEntry = (id) => {
        return this.props.entries.find(x => x.id === id)
    }

    treeExpandChange = (path) => {
        if (path.tableData.isTreeExpanded) {
            const expandedRows = this.props.expandedRows.concat(path.id)
            this.props.setExpandedRows(expandedRows)
        }    
        else {            
            const expandedRows = this.props.expandedRows.filter((item, j) => path.id !== item)
            this.props.setExpandedRows(expandedRows)            
        }    
    }

    renderContent = (rowData) => {

        const {
            entry,
            row
        } = rowData

        const onChange = v => {
            this.props.updateTime({
                entryIndex: rowData.entryIndex,
                hours: v,
                rowIndex: rowData.rowIndex,
                detailIndex: rowData.detailIndex
            })
        }                 
        const total = this.props.timesheet.totals[rowData.entryIndex]
				const isTemporary = /^TMP/.test(entry.id)
					// show as posted: individually submitted entries, or entries that have not been committed to Epicor when the day
    			// has been posted
        const isPosted = !!entry.PostedToGL || (isTemporary && total.Posted)
				const isApproved = 
					// show as approved: individually submitted entries, or entries that have not been committed to Epicor when the day
      		// has been approved
					!isPosted && (entry.Approved || (isTemporary && !!total.Approved))
        const isSubmitted = 
        	!isPosted && 
					!isApproved && 
					// show as submitted: individually submitted entries, or entries that have not been committed to Epicor when the day
      		// has been submitted
					(entry.Submitted || (isTemporary && !!total.Submitted))
        const lockedReason = getEntryLockedReason(
            entry,
            total,
            row,
            this.props.mode,
            true,
            false
        )

				const tooltip = 
					// don't show lock reason if cell is read only, because in that case it likely has an obvious cause
      		// (eg for the cells on the blank row)
					lockedReason && 
					!this.props.isReadOnly && 
					lockedReason !== 'MES' && (this.props.labels.lockedReason[lockedReason] || lockedReason)

        const isCellReadOnly = (this.props.isReadOnly || rowData.row.key === '' || !!lockedReason) && lockedReason !== 'MES'

        return (        
            <Container
                className='material-table-hours'>
                <TimesheetNumberCellOverlay
                    hasOvernight={rowData.entry.PayType === 'RP'}
                    hasOvernightOff={rowData.entry.PayType === 'RPR'}
                    isApproved={isApproved}
                    isPosted={isPosted}
                    isReadOnly={isCellReadOnly}
                    isRounded={this.props.isRounded}
                    isSubmitted={isSubmitted}
                    isSavePending={this.props.isSavePending}
                    key={entry.Date}                    
                    onChange={onChange}
                    onFocus={() => this.props.setActiveEntry(rowData.entryIndex)}
                    tooltip={tooltip}
                    value={Number(rowData.hours)}
                    mode={this.props.mode}
                    view={this.props.view}
                    rowIndex={rowData.rowIndex}
                    entryIndex={rowData.entryIndex}
                    detailIndex={rowData.detailIndex}
                    {...this.props}
            />
            </Container>
        )
    }

    render() {    
        const {
            company,
            editRow,
            isSavePending,
            mode,
            setActiveEntry,
            updateEntryDetail,
            updateNarrative,
            view
        } = this.props

        const columns = [
            { 
                field: 'start', title: 'Start', editable: 'never', sorting: false,
                    headerStyle: {
                        width: 190
                    },
                render: rowData =>
                    <div><div>{rowData.start}</div><div>{rowData.time}</div></div>
            },  
            { 
                field: 'hours', title: 'Hours', type: 'numeric', sorting: false,
                headerStyle : {
                    width: 50,
                    textAlign: 'left' 
                },
                cellStyle: {
                    textAlign: 'left',
                    paddingLeft: '40px'
                },
                render: rowData =>                                                                      
                    rowData.row
                    ? this.renderContent(rowData)
                    : !rowData.parentId && rowData.hasOvernight
                        ? (<div className='timesheet-cell has-icon'>{rowData.hours}<div className="timesheet-cell--overnight" title="Overnight" /></div>)
                        : !rowData.parentId && rowData.hasOvernightOff
                            ? (<div className='timesheet-cell has-icon'>{rowData.hours}<div className="timesheet-cell--overnightoff" title="Overnight Off" /></div>)
                            : rowData.hours
            },
            { 
                field: 'job', title: 'Job', type: 'string', editable: 'never', sorting: false,
                render: rowData => 
                    rowData.row 
                    ? <div
                        className='timesheet-line--project'>
                        <Container
                        className='material-table-job'>                            
                            <Project                             
                                {...this.props} 
                                company={company}
                                hasProjectSelection={true}
                                isEdit={
                                    rowData.id === editRow
                                }
                                isRowEditable={
                                    !getEntryLockedReason(
                                        rowData.entry,
                                        rowData.entry,
                                        rowData.row,
                                        mode,
                                        false,
                                        true
                                    )
                                }
                                row={rowData.row}
                                // note we use id, not rowIndex... reason is that a single "row" on the timesheet summary
                                // will correspond to several rows on the detail view
                                rowIndex={rowData.id}
                                onComplete={this.onComplete}
                                updateRow={updateEntryDetail}
                            />
                        </Container>
                        </div>
                    : null
            },
            {
                field: 'notes', title: 'Notes', editable: 'never', sorting: false,
                headerStyle: {
                    paddingLeft: 0
                },
                render: rowData =>
                    rowData.row && rowData.parentId
                    ? <NoteIndicator
                        note={rowData.narrative}
                        onDialogClose={() => setActiveEntry(null)}
                        updateNarrative={updateNarrative}
                        isSavePending={isSavePending}
                        isReadOnly={this.props.isReadOnly}
                        view={view}
                        mode={mode}
                        rowIndex={rowData.rowIndex}
                        entryIndex={rowData.entryIndex}
                        detailIndex={rowData.detailIndex}
                      />                      
                    : null
            }
        ]

        this.theme = createMuiTheme({
            overrides: {
                MuiTooltip: {
                    tooltip: {
                        fontSize: 'small'
                    }
                },
                MuiTableHead: {
                    root: {
                        fontStyle: 'bold',
                        textTransform: 'uppercase',
                        font: 'revert',
                        color: 'black'
                    }
                },
                MuiTable: {
                    root: {
                        height: 100
                    }
                },                
            },
            typography: {
                fontFamily: 'AvantGardeGothicITCW02Md',
                
            }   
        })

        const rows = _getRows(this.props)
        
        return (        
            <MuiThemeProvider theme={this.theme}>
                <MaterialTable                       
                title='Labor Details'
                columns={columns}
                data={rows}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}    
                onTreeExpandChange={(path, data) => this.treeExpandChange(path, data)}    
                options={{      
                    toolbar: false,
                    paging: false,
                    rowStyle: (rowData, index, level) => {
                        if (index % 2 && level === 1) {
                            return { backgroundColor: '#f2f2f2' }
                        }
                        else if (level === 0) {
                            return { backgroundColor: '#d9d9d9'}
                        }
                        else {
                            return { backgroundColor: 'white' }
                        }
                    }
                }}               
                />
            </MuiThemeProvider>
        )
    }
}

function _getTotalHours(entries, entryIndex) {
    return entries.reduce (
        (acc, entry) =>
            acc + (entry.Date === entries[entryIndex].Date && entry.Submitted === true ? entry.TimeAllocation : 0),
            0
    )
}

function _idExists(id, rows) {
    return rows.some(x => x.id === id)
}

function _isTreeExpanded(id, expandedRows) {
    return expandedRows.find(x => x === id)
}

function _getRows(props) {
    const rows = []    
    let i
    // sort entries by date
    props.entries.sort((a, b) => {
        return new Date(a.Date) - new Date(b.Date)
    })
    for (i = 0; i < props.entries.length; i++) {              
        const totalHours = _getTotalHours(props.entries, i)
        if (props.entries[i].Submitted === true){ // filter out entered time for view
            if (!_idExists(props.entries[i].LaborHed[0].payrollDate, rows)) {
                let clockInTime = getClockInTime(props.entries, props.entries[i].LaborHed[0].payrollDate)
                clockInTime = timeConvert(clockInTime.toFixed(2))
                clockInTime = moment(`${props.entries[i].LaborHed[0].payrollDate} ${clockInTime}`).format('LT')        
                let clockOutTime= getClockOutTime(props.entries, props.entries[i].LaborHed[0].payrollDate)           
                clockOutTime = timeConvert(clockOutTime.toFixed(2))         
                clockOutTime = moment(`${props.entries[i].LaborHed[0].payrollDate} ${clockOutTime}`).format('LT')       
                rows.push({
                    id: props.entries[i].LaborHed[0].payrollDate,
                    hours: Number(totalHours).toFixed(2),
                    start: `${dateUtils.format(props.entries[i].LaborHed[0].payrollDate, props.timesheet.company)} - ${getDayOfWeek(props.entries[i].LaborHed[0].payrollDate)}`,
                    time: `${clockInTime}-${clockOutTime}`,
                    hasOvernight: hasOvernight(props.entries, props.entries[i].LaborHed[0].payrollDate),
                    hasOvernightOff: hasOvernightOff(props.entries, props.entries[i].LaborHed[0].payrollDate),
                    tableData: {
                        isTreeExpanded: _isTreeExpanded(props.entries[i].LaborHed[0].payrollDate, props.expandedRows)
                    }

                })
            }
            let j
            for (j = 0; j < props.entries[i].Detail.length; j++) {                
                if (!_idExists(props.entries[i].Detail[j].id, rows)) {
                    const rowIndex = _findTimesheetRow(props.entries[i], props.timesheet)
                    const entryIndex = _findTimesheetEntryIndex(props.timesheet, rowIndex, props.entries[i].Date)
                    const detailIndex = _findTimesheetDetailIndex(props.timesheet, rowIndex, entryIndex, props.entries[i].Detail[j].id)
                    rows.push({
                        parentId: props.entries[i].LaborHed[0].payrollDate,
                        hours: Number(props.entries[i].Detail[j].TimeAllocation).toFixed(2),
                        start: dateUtils.format(props.entries[i].Detail[j].ClockInDate, props.timesheet.company),
                        id: props.entries[i].Detail[j].id,
                        submitted: props.entries[i].Submitted,
                        approved: props.entries[i].Approved,
                        job: props.entries[i].row.Job && props.entries[i].row.Job.label,
                        operation: props.entries[i].row.Operation && props.entries[i].row.Operation.label,
                        indirect: props.entries[i].row.IndirectCode && props.entries[i].row.IndirectCode.label,
                        payType: props.entries[i].PayType,
                        narrative: props.timesheet.rows[rowIndex].entries[entryIndex].Detail[detailIndex].Narrative,
                        rowIndex: rowIndex,
                        row: props.timesheet.rows[rowIndex],
                        entryIndex: entryIndex,
                        entry: props.timesheet.rows[rowIndex].entries[entryIndex],
                        detailIndex: detailIndex,
                        detail: props.timesheet.rows[rowIndex].entries[entryIndex].Detail[detailIndex],
                        company: props.timesheet.Company
                    })
                }
            };
        }        
    };
    return rows
}

function timeConvert(num) // expects decimal formatted time
 {
    var temp = Number(num).toFixed(2).toString().split('.')   
    var hours = temp[0]
    var minutes = Number(temp[1]) === 0 ? '00' : Number(60 * (num - hours)).toFixed()
    return hours + ":" + minutes;         
}

function getClockInTime(entries, clockInDate) {
    let min = entries.find(entry => entry.Date === clockInDate).LaborHed[0].clockInTime
    for (let i = 0, len=entries.length; i < len; i++) {
        if (entries[i].Date === clockInDate) {
            for (let j = 0; j < entries[i].LaborHed.length; j++) {
                let v = entries[i].LaborHed[j].clockInTime
                min = (v < min) ? v : min
            }
        }        
    }
    return Number(min.toFixed(2))
}

function getClockOutTime(entries, clockInDate) {
    let max = entries.find(entry => entry.Date === clockInDate).LaborHed[0].clockOutTime
    for (let i = 0, len=entries.length; i < len; i++) {
        if (entries[i].Date === clockInDate) {
            for (let j = 0; j < entries[i].LaborHed.length; j++) {
                let v = entries[i].LaborHed[j].clockOutTime
                max = (v > max) ? v : max
            }
        }        
    }
    return Number(max.toFixed(2))
}

function hasOvernight(entries, clockInDate) {
    for (let i = 0; i < entries.length; i++) {
        if (entries[i].Date === clockInDate && entries[i].PayType === 'RP') {
            return true
        }
    }
    return false
}

function hasOvernightOff(entries, date) {
    for (let i = 0; i < entries.length; i++) {
        if (entries[i].Date === date && entries[i].PayType === 'RPR') {
            return true
        }
    }
    return false
}

function _findTimesheetRow(entry, timesheet) {
    for (let i = 0; i < timesheet.rows.length; i++) {
        if (timesheet.rows[i].id === entry.row.id) {
            return i
        }
    }
}

function _findTimesheetEntryIndex(timesheet, rowIndex, date) {
    for (let i = 0; i < timesheet.rows[rowIndex].entries.length; i++) {
        if (timesheet.rows[rowIndex].entries[i].Date === date) {
            return i
        }
    }
}

function _findTimesheetDetailIndex(timesheet, rowIndex, entryIndex, id) {
    for (let i = 0; i < timesheet.rows[rowIndex].entries[entryIndex].Detail.length; i++) {
        if (timesheet.rows[rowIndex].entries[entryIndex].Detail[i].id === id) {
            return i
        }
    }
}

ApprovalDetailView.prototypes = {
    timesheet: PropTypes.object.isRequired
}

export default ApprovalDetailView