import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TotalCell from './TotalCell';

const mql = window.matchMedia(`(max-width: 1023px)`)

class TimesheetSubTotals extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: mql.matches
    }

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged)
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged)
  }

  mediaQueryChanged() {
    this.setState({ mobile: mql.matches })
  }

  render() {
    const {
      totals,
      company
    } = this.props
    return (

    
      <div>
        {renderRow('Chargeable Hours', totals, 'Chargeable', company, this.state.mobile)}
        {renderRow('Non-Chargeable Hours', totals, 'NonChargeable', company, this.state.mobile)}   
        {totals.reduce(function(prev, cur){
            return prev + cur.TimeOff
          }, 0) > 0 
          ? renderRow('Time Off', totals, 'TimeOff', company, this.state.mobile, true) 
          : null
        }    
      </div>
    )
  }
};

function renderRow(label, totals, prop, company, mobile, timeOff=false) {      
  const titleClass = classNames('title col-xs-12', {
    'col-md-3': (company === '100' || company === '700' || company === '770'),
    'col-md-4': (company !== '100' && company !== '700' && company !== '770')
  })
  const subtotalsClass = classNames('subtotals col-xs-12', {
    'col-md-6': (company === '100' || company === '700' || company === '770'),
    'col-md-7': (company !== '100' && company !== '700' && company !== '770')
  })
  const daysClass = classNames('days col-xs-12', {
    'col-md-6': (company === '100' || company === '700' || company === '770'),
    'col-md-7': (company !== '100' && company !== '700' && company !== '770')
  })
  return (
    <div className="timesheet-line timesheet-line--subtotals row">
    <div className={titleClass}>{label}</div>  
    <div className={
      mobile ? subtotalsClass : daysClass
    }>    
      {totals.map(val => (              
        <TotalCell
          key={val.Date}
          includeDayOfWeek={mobile}
          date={val.Date}
          value={val[prop]}
          timeOff={timeOff}
          mobile={mobile}
        />      
      ))}  
    </div>
    </div>
  )
}

TimesheetSubTotals.propTypes = {
  totals: PropTypes.array.isRequired
};

export default TimesheetSubTotals;