import React from 'react';
import Tooltip from 'rc-tooltip'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {CurtainTransition} from '../../shared/components/styles';

import calcHeight from '../../shared/hoc/calcHeight';
import dateUtils from '../../shared/utils/dateUtils';
import getApprovalLink from '../api/getApprovalLink';

const ApprovalEmployeeItem = ({
  calcHeight,
  calcHeightRef,
  employee,
  expanded,
  isSelectedUser,
  selectedDate,
  toggleUser,
  view,
  setTimesheetUser,
  currentUser,
  setExpandedRows
}) => {
  if(employee.Timesheets.length > 0){
    return (
      <li className="approval-employee-item">    
        <h4 onClick={() => toggleUser(employee.id)}>        
          {
            expanded ? (
              <i className="fa fa-caret-down curtain-arrows" aria-hidden="true" />
            ) : (
              <i className="fa fa-caret-right curtain-arrows" aria-hidden="true" />
            )
          }
          {employee.Name}                  
          <span className="timesheet-notifications">
            {employee.Timesheets.length}
          </span>                          
          { employee.HasEnteredTime ? (
              <Tooltip 
                title={"GoToTimesheet"} 
                overlay={<span>{'Employee has unsubmitted time.'}</span>}                   
              >
                <i className="fa fa-exclamation-triangle entered-time-warning" />
              </Tooltip>
            ) : ('')
          }
        </h4>              
        <CurtainTransition show={expanded} maxHeight={calcHeight + 'px'}>
          <ul className="timesheets" ref={calcHeightRef}>
            {employee.Timesheets.map(ts => (
              <li
                className={
                  isSelectedUser && ts.PeriodStartDate === selectedDate
                    ? 'approval-employee-item--selected'
                    : ''
                }
                key={ts.PeriodStartDate}>
                <div className="approval-links">
                  <Link className="approval-employee-item-goto" onClick={() => setExpandedRows([])} to={getApprovalLink(employee, ts, view)}>
                    {`Week of ${dateUtils.format(ts.PeriodStartDate, currentUser.Company)}`                }              
                  </Link>    
                  <Tooltip 
                    title={"GoToTimesheet"} 
                    overlay={<span>{'Go to timesheet'}</span>}                   
                  >
                    <Link className="approval-employee-timesheet" onClick={() => setTimesheetUser({value: employee.id})} to={`/timesheet/${ts.PeriodStartDate}`}>
                      <i className="fa fa-clock-o" />              
                    </Link>     
                  </Tooltip>      
                </div>       
              </li>
            ))}
          </ul>
        </CurtainTransition>
      </li>
    )
  } else {
    return (    
      <li className='empty-employee-item'> 
          <div className='employee-name'>            
            {employee.Name}    
            { employee.HasEnteredTime ? (
                <Tooltip 
                title={"GoToTimesheet"} 
                overlay={<span>{'Employee has unsubmitted time.'}</span>}                   
              >
                <i className="fa fa-exclamation-triangle entered-time-warning" />
              </Tooltip>
              ) : ('')
            }
          </div>          
          <Tooltip 
            title={"GoToTimesheet"} 
            overlay={<span>{'Go to timesheet'}</span>}                   
          >
            <Link className='timesheet-link' onClick={() => setTimesheetUser({value: employee.id})} to={`/timesheet`}>            
              <i className="fa fa-clock-o" />              
            </Link> 
          </Tooltip> 
      </li>                          
    )
  }
}      


ApprovalEmployeeItem.propTypes = {
  employee: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleUser: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired
};

export default calcHeight(100)(ApprovalEmployeeItem);
