import React from 'react'
import styled from 'styled-components'
import getGreetingTime from '../../app/utils/getGreetingTime'

const GreetingContainer = ({
  userName
}) => {
  return (
    <div className='pull-right'>
      <Container>
        <h4>
          Good {getGreetingTime()}, {userName}!
        </h4>
      </Container>
    </div>
  )
}

const Container = styled.div`    
    h4 {
        margin-right: 15px;
        font-size: 20px;
        color: #333;
    }
`;

export default GreetingContainer