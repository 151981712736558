import PropTypes from 'prop-types';
import React from 'react';
// import Sticky from 'react-sticky-fill';
import styled from 'styled-components';

import Breadcrumbs from './Breadcrumbs';
import BackButton from './BackButton';
import ClearButton from './ClearButton';

const ItemList = ({
  className,
  items,
  loading,
  onBack,
  onClear,
  onBreadcrumbClick,
  selection,
  selectItem,
  cursor
}) => (
  <div className={`${className} item-list`}>
    {selection.length > 0 && (
      // <Sticky>
        <div className="navigation">
          <ClearButton loading={loading} clear={onClear} />
          <BackButton loading={loading} back={onBack} />
          <Breadcrumbs onClick={onBreadcrumbClick} selection={selection} />
        </div>
      // </Sticky>
    )}
    <Ul>
      {items.map((item, i) => (
        <li key={item.id} onClick={() => selectItem(item)} className={cursor === i ? 'active' : ''}>
          {item.title}
        </li>
      ))}
      {items.length === 0 && (
        <li className="item-list--noitem">No item found</li>
      )}
    </Ul>
  </div>
);

const Ul = styled.ul`
  margin: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;  

  li {
    color: rgba(0, 0, 0, 0.8);
    text-align: justify;
    text-justify: inter-word;
    padding: 11px;
    cursor: pointer;
    transition: all 0.1s ease-in;

    &:last-child {
      border-bottom: none;
    }

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      color: rgba(255, 255, 255, 0.8);
      transition: all 0.1s ease-out;
    }
  }
  
  .active {
    background-color: rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 0.8);
    transition: all 0.1s ease-out;
  }
`;

ItemList.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingComponent: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onBreadcrumbClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selection: PropTypes.array.isRequired,
  selectItem: PropTypes.func.isRequired
};

export default ItemList;
