import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Custom checkbox component
const Checkbox = props => {
  const inputProps = props.input ? props.input : props;
  const val =
    typeof inputProps.value === 'string' ? inputProps.value : undefined;
  const checked =
    typeof inputProps.value === 'boolean'
      ? inputProps.value
      : inputProps.checked;
  const onChange = props.input
    ? evt => props.input.onChange(evt.target.checked)
    : props.onChange;
  const cls = classNames('treehouse-checkbox', props.className, {
    checked,
    'left-label': props.labelOnLeft
  });
  return (
    <label className={cls}>
      <input
        type="checkbox"
        checked={checked}
        value={val}
        name={props.name}
        disabled={props.disabled}
        onChange={onChange}
      />
      {/* 
        note the stopPropagation call here, to avoid having click events on the div bubble 2 click events.
          */}
      <div onClick={e => e.stopPropagation()}>
        <i className="fa fa-check" />
      </div>
      {props.label}
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelOnLeft: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  // optional name will be applied to the input element
  name: PropTypes.string,
  // for redux form
  input: PropTypes.object
};

export default Checkbox;
