import update from 'immutability-helper';
import {handleActions} from 'redux-actions';
import {
  CLEAR_MESSAGES,
  SET_LOADING,
  CLEAR_LOADING,
  SET_SAVE_PENDING,
  SET_WARNING,
  SET_ERROR,
  SET_SUCCESS,
  TOGGLE_CALENDAR,
  SET_ACTIVE_ENTRY,
  SET_ACTIVE_ROW,
  SET_EDIT_ROW,
  SHOW_CONFIRMATION,
  HIDE_CONFIRMATION,
  TOGGLE_MOBILE_DASHBOARD,
  ON_TIMESHEET_NAVIGATE,
  SET_TIMESHEET_USER
} from '../actions/types';
import {AUTH_SIGNIN} from '../../auth/actions/types';
import cleanErrorMessage from '../../utils/cleanErrorMessage';
import {saveState, restoreState} from 'persist-reducer';
import pick from 'lodash/pick';

const saveConfig = {
  storageKey: 'timesheetState',
  keysToSave: ['timesheetUser']
};

const defaultState = restoreState(saveConfig, {
  // active row / cell index
  activeRow: null,
  activeEntry: null,
  editRow: null,
  // what user we want to see the timesheet for
  timesheetUser: null,
  // the last date that we viewed, we use it to remember the position when navigating from
  // the approval view
  timesheetDate: '',
  ui: {
    showMobileDashboard: false,
    loading: false,
    savePending: {},
    showCalendar: false,
    confirmDialog: null,
    success: '',
    // a flashing error
    error: '',
    warning: ''
  }
});

const resetUiState = state => ({
  ...state,
  ui: {
    ...defaultState.ui,
    ...pick(state.ui, ['showMobileDashboard'])
  }
});

export default saveState(
  saveConfig,
  handleActions(
    {
      [SET_ACTIVE_ENTRY]: (state, {payload}) => ({
        ...state,
        activeEntry: payload
      }),
      [SET_ACTIVE_ROW]: (state, {payload}) => ({
        ...state,
        activeRow: payload,
        editRow: null
      }),
      [SET_EDIT_ROW]: (state, {payload}) => {
        const updates = {editRow: payload};
        if (payload != null && typeof payload !== 'undefined') {
          updates.activeRow = payload;
        }

        return {...state, ...updates};
      },
      [SET_LOADING]: state =>
        update(state, {
          ui: {$merge: {loading: true, success: '', error: '', warning: ''}}
        }),
      [CLEAR_LOADING]: state => update(state, {ui: {loading: {$set: false}}}),
      [SET_WARNING]: (state, {payload}) =>
        update(state, {ui: {warning: {$set: payload}}}),
      [SET_ERROR]: (state, {payload}) =>
        update(state, {ui: {error: {$set: cleanErrorMessage(payload)}}}),
      [SET_SUCCESS]: (state, {payload}) =>
        update(state, {ui: {success: {$set: payload}}}),
      [SET_SAVE_PENDING]: (state, {payload}) =>
        update(state, {ui: {savePending: {$merge: payload}}}),
      [TOGGLE_CALENDAR]: state =>
        update(state, {ui: {$toggle: ['showCalendar']}}),
      [TOGGLE_MOBILE_DASHBOARD]: state =>
        update(state, {ui: {$toggle: ['showMobileDashboard']}}),
      [SHOW_CONFIRMATION]: (state, {payload}) =>
        update(state, {ui: {confirmDialog: {$set: payload}}}),
      [HIDE_CONFIRMATION]: state =>
        update(state, {ui: {confirmDialog: {$set: null}}}),
      // navigation to a new timesheet (same user, different date)
      [ON_TIMESHEET_NAVIGATE]: (state, {payload}) =>
        resetUiState({...state, timesheetDate: payload}),
      [SET_TIMESHEET_USER]: (state, {payload}) =>
        resetUiState({...state, timesheetUser: payload}),
      [CLEAR_MESSAGES]: state =>
        update(state, {
          ui: {
            $unset: ['error', 'success', 'warning']
          }
        }),
      // set default user when signing in
      [AUTH_SIGNIN]: state => ({...state, timesheetUser: null})
    },
    defaultState
  )
);
