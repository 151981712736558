import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function LastWeek(props, context) {
  var onClick = () => {
    var next = moment(props.current).add(-1, 'week');
    props.goToTimesheet(next);
  };
  return (
    <a className="timesheet-lastweek" onClick={onClick}>
      <i className="fa fa-arrow-left fa-lg" aria-hidden="true" />
    </a>
  );
}

LastWeek.propTypes = {
  current: PropTypes.string.isRequired,
  goToTimesheet: PropTypes.func.isRequired
};

export default LastWeek;
