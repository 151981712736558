import React from 'react';
import PropTypes from 'prop-types';
import CloneOverlay from './CloneOverlay';

/**
 * <Clone />
 * Clone button for timesheet
 */
export default function Clone({
  copyPreviousWeek,
  copyPreviousWeekWithTime,
  disabled,
  mobile
}) {
  return (
    <div className="timesheet--clone">
      {mobile && (
        <button
          className="btn actions-sub-button"
          disabled={disabled}
          onClick={copyPreviousWeekWithTime}>
          <i className="fa fa-clone" aria-hidden="true" />Copy Records with Time
        </button>
      )}
      {mobile && (
        <button
          className="btn actions-sub-button"
          disabled={disabled}
          onClick={copyPreviousWeek}>
          <i className="fa fa-clone" aria-hidden="true" />Copy Records without
          Time
        </button>
      )}
      {!mobile && (
        <CloneOverlay
          copyPreviousWeek={copyPreviousWeek}
          copyPreviousWeekWithTime={copyPreviousWeekWithTime}
          disabled={disabled}
        />
      )}
    </div>
  );
}

Clone.propTypes = {
  copyPreviousWeek: PropTypes.func.isRequired,
  copyPreviousWeekWithTime: PropTypes.func.isRequired
};
