import className from 'classnames';
import React from 'react';
import {withHandlers} from 'recompose';

import Checkbox from '../../shared/components/inputs/Checkbox';

/**
 * Cell used for the full-day type selection (overnight comp & in lieu)
 */
const OnCallCell = ({
  approved,
  isInvalidSelection,
  isSavePending,
  isReadOnly,
  onChange,
  checked,
  posted,
  submitted,
  selection,
  ...props
}) => (
  <div
    className={className('timesheet-cell timesheet-cell--oncall', {
      'timesheet-cell--approved': approved,
      'timesheet-cell--invalid': isInvalidSelection,
      'timesheet-cell--posted': posted,
      'timesheet-cell--save-pending': isSavePending,
      'timesheet-cell--submitted': !approved && submitted
    })}
    {...props}>
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={isReadOnly}
      className='timesheet-cell timesheet-cell--oncall-checkbox'
      
    />
  </div>
);

const container = withHandlers({
  onChange: ({onChange, date, selection}) => event => {
    onChange(date, selection, event && event.target.checked);
  }
});

export default container(OnCallCell);
