import moment from 'moment';
import {graphql} from 'react-apollo';

import {RECALL_TIMESHEET_SUBMISSION_MUTATION} from '../../queries';
import {buildTsInput} from '../../../shared/utils/queries';

export default graphql(RECALL_TIMESHEET_SUBMISSION_MUTATION, {
  props: ({
    ownProps: {timesheet, setLoading, setError, setSuccess, clearLoading},
    mutate
  }) => ({
    recallTimesheetSubmission(selectedDates) {
      const dates = timesheet.totals
        .filter(
          x =>
            selectedDates.includes(x.Date.format('YYYY-MM-DD')) &&
            x.Date <= moment() &&
            !(x.PostedToGL || x.Approved) &&
            x.Submitted
        )
        .map(x => x.Date.format('YYYY-MM-DD'));

      if (!dates.length) {
        return setError('There are no days which can be recalled.');
      }

      setLoading();

      mutate({
        variables: {
          ...buildTsInput(timesheet),
          dates
        }
      })
        .then(
          ({data: {recallTimesheetSubmission: {message}}}) => {
            setSuccess(message.toString());
          },
          e => setError(e)
        )
        .then(clearLoading);
    }
  })
});
