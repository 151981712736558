import {connect} from 'react-redux';
import {compose} from 'recompose';
import {rootClick, toggleMenu} from '../actions';
import withAuth from '../../auth/hoc/withAuth';
import autoLogout from './autoLogout';

// HOC for the application-level page
export default compose(
  withAuth,
  connect(
    state => ({
      fatalError: state.app.fatalError,
      menuExpanded: state.app.menuExpanded
    }),
    {
      rootClick,
      toggleMenu
    }
  ),
  autoLogout
);
