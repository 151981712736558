import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from 'rc-tooltip';

import Checkbox from '../inputs/Checkbox';

const TimeDialogCell = props => {
  const {
    amount,
    approved,
    checked,
    date,
    disabled,
    message,
    onChange,
    posted,
    readOnly,
    submitted
  } = props;

  const cls = classNames('time-dialog-cell-content', {
    'time-dialog-cell-content--disabled': disabled,
    'time-dialog-cell-content--read-only': readOnly,
    'time-dialog-cell-content--approved': date <= Date.now() && approved,
    'time-dialog-cell-content--submitted':
      date <= Date.now() && !(approved || posted) && submitted,
    'time-dialog-cell-content--posted':
      date <= Date.now() && !approved && posted
  });

  let content = (
    <div className={cls} onClick={disabled ? undefined : onChange}>
      <Checkbox
        className="time-dialog-cell-checkbox"
        checked={checked}
        disabled={disabled || readOnly}
        type="checkbox"
      />
      <div className="time-dialog-date">{date.format('M/D')}</div>
      <div>{amount} hrs</div>
    </div>
  );
  if (message) {
    content = (
      <Tooltip overlay={<span>{message}</span>} placement="top">
        {content}
      </Tooltip>
    );
  }

  return (
    <div className="time-dialog-cell">
      <p className="time-dialog-cell-title">{date.format('ddd')}</p>
      {content}
    </div>
  );
};

TimeDialogCell.propTypes = {
  amount: PropTypes.number.isRequired,
  approved: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  date: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  posted: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired
};

export default TimeDialogCell;
