import React from 'react';
// import PropTypes from 'prop-types'
import {LoginButton, LoginHeader} from '../../shared/components/styles';
import Spinner from '../../shared/components/Spinner';
import Logo from './bedrock_wordmark.png';

const SignInPage = ({
  user,
  setUser,
  password,
  setPassword,
  login,
  error,
  loading
}) => (
  <form className="form-signin">
    <LoginHeader className="form-signin-heading">
      <img alt="TAIT Logo" className="tait-logo" src={Logo} />
    </LoginHeader>
    <div className="login-wrap">
      <input
        placeholder="Login"
        autoCorrect="off"
        autoCapitalize="off"
        autoFocus
        className="form-control"
        type="text"
        onChange={e => setUser(e.target.value)}
        value={user}
      />
      <input
        placeholder="Password"
        className="form-control"
        type="password"
        onChange={e => setPassword(e.target.value)}
        value={password}
      />
      {error && (
        <p className="error" dangerouslySetInnerHTML={{__html: error}} />
      )}
      {loading && <Spinner />}
      <LoginButton
        className="btn btn-lg btn-login btn-block"
        type="submit"
        onClick={login}>
        Submit
      </LoginButton>
      {!error && (
        <p className="login-help">
          Need Assistance? Visit{' '}
          <a href="https://ithelp.taittowers.com" target="_blank" rel="noopener noreferrer">IT Help</a>
          {' '}to submit a ticket
        </p>
      )}
    </div>
  </form>
);

export default SignInPage;
// vim ft=javascript.jsx
