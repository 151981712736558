import React from 'react';
import PropTypes from 'prop-types';
import TotalCell from './TotalCell';
import {getTimesheetTimeTrackingMethod} from '../api/methods';

/**
 * <TimesheetWidgetTotals />
 * Total tally at bottom of the timesheet rows
 * This is a version for the Widget that is displayed on the dashboard - it has markup
 * that more closely matches the display of the header row
 */
function TimesheetWidgetTotals({timesheet}, context) {
  function renderTotalCell(tot) {
    var isPercent = getTimesheetTimeTrackingMethod(timesheet).usePercentage;
    return <TotalCell key={tot.Date} tot={tot} usePercentage={isPercent} />;
  }

  return (
    <div className="timesheet-project-header timesheet-widget-total row">
      <div className="timesheet-project col-md-5">
        <div className="project-heading col-md-12">Weekly Totals</div>
      </div>
      <div className="timesheet-days col-md-7">
        <div className="day-titles col-md-12 col-xs-3">
          {timesheet.totals.map(renderTotalCell)}
        </div>
      </div>
    </div>
  );
}
TimesheetWidgetTotals.propTypes = {
  timesheet: PropTypes.object.isRequired
};

export default TimesheetWidgetTotals;
