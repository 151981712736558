import {restoreState, saveState} from 'persist-reducer';
import update from 'immutability-helper';
import {handleActions} from 'redux-actions';

import {
  TOGGLE_USER,
  SET_NAME_FILTER,
  SET_EDIT_ROW,
  SET_FOCUS_ROW,
  SET_CURRENT_PATH,
  SET_DEFAULT_VIEW,
  SET_SUPERVISOR_SELECT,
  TOGGLE_APPROVAL_SIDEBAR,
  SET_EXPANDED_ROWS
} from '../actions/types';

const saveConfig = {
  storageKey: 'approval',
  keysToSave: ['defaultView']
};
const defaultState = restoreState(saveConfig, {
  nameFilter: '',
  currentPath: '/approval',
  defaultView: 'summary',
  sidebarExpanded: true,
  expandedRows: [],
  supervisorSelect: "",
  expandedUsers: {}
});

export default saveState(
  saveConfig,
  handleActions(
    {
      [TOGGLE_USER]: (state, {payload}) =>
        update(state, {
          expandedUsers: {$toggle: [payload]}          
        }),
      [SET_EDIT_ROW]: (state, {payload}) =>
        update(state, {
          editRow: {$set: payload}
        }),
      [SET_FOCUS_ROW]: (state, {payload}) =>
        update(state, {
          focusRow: {$set: payload}
        }),
      [SET_NAME_FILTER]: (state, {payload}) => ({
        ...state,
        nameFilter: payload
      }),
      [SET_CURRENT_PATH]: (state, {payload}) => ({
        ...state,
        currentPath: payload
      }),
      [SET_DEFAULT_VIEW]: (state, {payload}) => ({
        ...state,
        defaultView: payload
      }),
      [SET_SUPERVISOR_SELECT]: (state, {payload}) => ({
        ...state,
        supervisorSelect: payload
      }),
      [TOGGLE_APPROVAL_SIDEBAR]: (state, {payload}) => ({
        ...state,
        sidebarExpanded: payload
      }),
      [SET_EXPANDED_ROWS]: (state, {payload}) => ({
        ...state,
        expandedRows: payload
      })
    },
    defaultState
  )
);
