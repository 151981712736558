import {withHandlers, compose} from 'recompose';
import {graphql} from 'react-apollo';
import every from 'lodash/every';
import fromPairs from 'lodash/fromPairs';

import {UPDATE_ROW_MUTATION} from '../../queries';
import buildRowInput from './_buildRowInput';
import makeBlankRow from './_makeBlankRow';

// Used to update the project on an existing row

const buildUpdates = (rowIndex, row, timesheet) => {
  const update = Object.assign(
    {IndirectCode: null, Job: null, Operation: null, Project: null, ReworkCode: null},
    row
  );
  if (update.Project.value === 'INDIRECT') {
    update.Project = null;
  }

  const updates = [
    {
      rows: {
        [rowIndex]: {$merge: update}
      }
    }
  ];

  // if this is the last row, we'll add a new blank one
  if (rowIndex === timesheet.rows.length - 1) {
    updates.push({
      rows: {$push: [makeBlankRow(timesheet)]}
    });
  }

  return updates;
};

export default compose(
  graphql(UPDATE_ROW_MUTATION, {
    props: ({
      ownProps: {
        clearLoading,
        setLoading,
        setSavePending,
        setError,
        setSuccess,
        updateTimesheetData,
        timesheet
      },
      mutate
    }) => ({
      updateRowData(rowIndex, input) {
        // delete row data on the server
        const row = timesheet.rows[rowIndex];

        setLoading();
        setSavePending(fromPairs(row.entries.map(e => [e.id, true])));

        mutate({
          variables: {
            ...buildRowInput(timesheet, row),
            input: {
              IndirectCode: input.IndirectCode && input.IndirectCode.value,
              Job: input.Job && input.Job.value,
              Operation: input.Operation && input.Operation.value,
              ReworkCode: input.ReworkCode && input.ReworkCode.value,
              Project:
                input.Project && input.Project.value !== 'INDIRECT'
                  ? input.Project.value
                  : null
            }
          },
          update(store, {data: {updateTimesheetRow}}) {
            if (!updateTimesheetRow) {
              setTimeout(() => setError('Update failed'));
            } else {
              const updates = buildUpdates(rowIndex, input, timesheet);
              updateTimesheetData(updates);
            }
          }
        })
          .then(
            () => setSuccess('Timesheet entry has been updated.'),
            err => setError(err)
          )
          .then(() => {
            clearLoading();
            setSavePending(fromPairs(row.entries.map(e => [e.id, false])));
          });
      }
    })
  }),
  withHandlers({
    updateRow: ({timesheet, updateRowData, updateTimesheetData}) => (
      rowIndex,
      row
    ) => {
      // check if row is transient if it is, update timesheet data w/out sending
      const transient = every(timesheet.rows[rowIndex].entries, e =>
        e.id.startsWith('TMP')
      );

      if (transient) {
        // updateTimesheetData({rows: { [rowIndex]: { $set: makeBlankRow(timesheet) } } })
        const updates = buildUpdates(rowIndex, row, timesheet);
        updateTimesheetData(updates);
      } else {
        updateRowData(rowIndex, row);
      }
    }
  })
);
