import React from 'react';
import Select from 'react-select';

import 'react-select/dist/react-select.css';

// small wrapper for React-Select to address issue with the blur event, and to load the react select css
class ReactSelect extends React.Component {
  onBlur = () => {
    // hack to get around the fact that react-select does not pass value on blur
    // https://github.com/erikras/redux-form/issues/82
    if (this.props.onBlur) {
      this.props.onBlur(this.props.value);
    }
  };

  // to scroll the menu into view, when it is contained within a scrolling container
  // https://github.com/JedWatson/react-select/issues/1267
  // (there is a PR open, so we might be able to remove this code if/when that gets merged)
  onOpen = () => {
    window.requestAnimationFrame(() => {
      if (
        this.select.props.scrollMenuIntoView &&
        this.select.menuContainer &&
        this.select.menu
      ) {
        const container = getScrollParent(this.select.menuContainer);
        const menuRect = this.select.menu.getBoundingClientRect();
        const menuBottom = menuRect.bottom + this.select.props.menuBuffer;
        const containerRect = container.getBoundingClientRect();
        const scrollBottom = containerRect.top + containerRect.height;

        if (menuBottom > scrollBottom) {
          container.scrollTop += menuBottom - scrollBottom;
        }
      }
    });
  };

  setSelectRef = node => {
    this.select = node;
  };

  render() {
    return (
      <Select
        ref={this.setSelectRef}
        {...this.props}
        onBlur={this.onBlur}
        onOpen={this.onOpen}
        menuBuffer={5}
      />
    );
  }
}

function getScrollParent(element, includeHidden) {
  const overflowRegex = includeHidden
    ? /(auto|scroll|hidden)/
    : /(auto|scroll)/;
  let parent = element.parentElement;

  while (parent) {
    const style = getComputedStyle(parent);
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
      return parent;
    parent = parent.parentElement;
  }

  return document.body;
}

export default ReactSelect;
