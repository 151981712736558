import PropTypes from 'prop-types';
import {compose, withHandlers, setPropTypes} from 'recompose';
import {withRouter} from 'react-router-dom';
import ensureBlankRow from './handlers/ensureBlankRow';
import submitTimesheet from './handlers/submitTimesheet';
import approveTimesheet from './handlers/approveTimesheet';
import submitAndApproveTimesheet from './handlers/submitAndApproveTimesheet';
import copyPreviousWeek from './handlers/copyPreviousWeek';
import recallTimesheetSubmission from './handlers/recallTimesheetSubmission';
import deleteRow from './handlers/deleteRow';
import addRow from './handlers/addRow';
import deleteEntry from './handlers/deleteEntry';
import updateRow from './handlers/updateRow';
import updateTime from './handlers/updateTime';
import updateNarrative from './handlers/updateNarrative';
import saveOvernightComp from './handlers/saveOvernightComp';
import saveInLieu from './handlers/saveInLieu';
import saveOnCall from './handlers/saveOnCall';
import updatePercentComplete from './handlers/updatePercentComplete'
import updateOpOutlook from './handlers/updateOpOutlook'

export default compose(
  setPropTypes({
    timesheet: PropTypes.object.isRequired
  }),
  withRouter,
  withHandlers({
    goToTimesheet: ({history, onTimesheetNavigate}) => date => {
      onTimesheetNavigate(date.format('YYYY-MM-DD'));
      history.push('/timesheet/' + date.format('YYYY-MM-DD'));
    },
    // updates that only affect the Graphql cache.
    // we do this, rather than modifying the data in Redux, because it lets us only have one copy of the timesheet,
    // this makes it easy to provide an optimistic UI just using the default Apollo capabilities
    addRow
  }),
  ensureBlankRow,
  // graphql mutations
  submitTimesheet,
  approveTimesheet,
  submitAndApproveTimesheet,
  copyPreviousWeek,
  recallTimesheetSubmission,
  deleteRow,
  updateRow,
  updateTime,
  deleteEntry,
  updateNarrative,
  saveOvernightComp,
  saveInLieu,
  saveOnCall,
  updatePercentComplete,
  updateOpOutlook
);
