import React from 'react'
import Tooltip from 'rc-tooltip'
import EditRow from './EditRow'

const EditRowOverlay = ({ disabled, onEdit }) => {
    const content = <EditRow disabled={disabled} onEdit={onEdit} />
    if (disabled) {
        return (
            <Tooltip
                overlay={<span>To change the selection for individual days or days with time that is Approved,<br /> please switch to Detail View</span>}
            >
                <span>{content}</span>
            </Tooltip>
        )
    }
    return content
}

export default EditRowOverlay