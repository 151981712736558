import {createAction} from 'redux-actions';

import * as types from './types';

// toggleUser(employeeNum): expand / unexpand a user
export const toggleUser = createAction(types.TOGGLE_USER);
export const toggleDirectReport = createAction(types.TOGGLE_DIRECT_REPORT);
export const setNameFilter = createAction(
  types.SET_NAME_FILTER,
  e => e.target.value
);
export const setSupervisorSelect = createAction(
  types.SET_SUPERVISOR_SELECT,
  sel => sel.value
);
export const setExpandedRows = createAction(types.SET_EXPANDED_ROWS);