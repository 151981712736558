import {branch, withProps, renderComponent, compose} from 'recompose';
import Alert from '../components/Alert';
import cleanErrorMessage from '../../utils/cleanErrorMessage';

// render an error alert if there is an error prop
export default branch(
  ({error}) => error,
  compose(
    withProps(({error}) => ({error: cleanErrorMessage(error)})),
    renderComponent(Alert)
  )
);
