import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

class UserSelection extends React.Component {
  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      this.selectRef &&
      this.selectRef.state.isOpen &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.selectRef.closeMenu();
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  setSelectRef = component => {
    this.selectRef = component;
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const {timesheetUser, currentUser, setTimesheetUser} = this.props;
    return (
      <div className="timesheet--userselection" ref={this.setWrapperRef}>
        <label>Timesheet for:</label>
        <ReactSelect
          ref={this.setSelectRef}
          clearable={false}
          value={timesheetUser}
          options={makeOptions(currentUser.UsersManaged)}
          onChange={setTimesheetUser}
        />
      </div>
    );
  }
}

UserSelection.propTypes = {
  timesheet: PropTypes.shape({
    EmployeeNum: PropTypes.string.isRequired,
    Company: PropTypes.string.isRequired
  }),
  currentUser: PropTypes.shape({
    UsersManaged: PropTypes.array.isRequired
  }),
  setTimesheetUser: PropTypes.func.isRequired
};

const makeOptions = users =>
  users.map(u => ({
    label: u.Name,
    value: u.id
  }));

export default UserSelection;
