import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import Approve from './Approve';
import recallTimesheetApproval from '../hoc/handlers/recallTimesheetApproval';
import Recall from './Recall';

const ApprovalControlBar = props => {
  const {
    approveTimesheet,
    currentUser,
    forDashboard = false,
    match,
    mobile = false,
    recallTimesheetApproval,
    timesheet
  } = props;

  const {totals} = timesheet;

  const controlProps = {
    forDashboard,
    mobile,
    timesheet
  };

  const isApprovable = totals.some(
    // if ANY time is not approved, and we are all submitted, we can approve
    x => !x.AllPosted && !x.AllApproved && x.AllSubmitted
  );
  // if any time is submitted, we can unsubmit
  const isUnsubmittable = totals.some(x => !x.AllPosted && x.Submitted);
  const isUnapprovable = totals.some(
    // if ANY time is approved, we can unapprove
    x => !x.AllPosted && x.Approved && x.Submitted
  );
  const isSameUser =
    currentUser.Company === timesheet.Company &&
    currentUser.EmployeeNum === timesheet.EmployeeNum;

  return (
    <div className="timesheet-desktop-controls">
      <div>
        {match.params.view === 'summary' && (
          <Link to={match.url.replace(/summary$/, 'detail')}>
            Switch to Detail View <i className="fa fa-angle-double-right" />
          </Link>
        )}
        {match.params.view === 'detail' && (
          <Link to={match.url.replace(/detail$/, 'summary')}>
            Switch to Summary View <i className="fa fa-angle-double-right" />
          </Link>
        )}
      </div>
      <Container>
        <Recall
          {...controlProps}
          isUnsubmittable={isUnsubmittable}
          isUnapprovable={isUnapprovable}
          disabled={!(isUnsubmittable || isUnapprovable) || isSameUser}
          onRecall={recallTimesheetApproval}
        />
        <Approve
          {...controlProps}
          disabled={!isApprovable || isSameUser}
          onApprove={approveTimesheet}
        />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  .desktop-control:not(:last-child) {
    margin-right: 0;
  }
`;

export default recallTimesheetApproval(ApprovalControlBar);
