import React from 'react';
import Spinner from '../../shared/components/Spinner';
import hoc from '../hoc/noTimesheetSelected';

class NoTimesheetSelected extends React.Component {
  menuButtonClick = (ev) => {
    ev.preventDefault()
    this.props.toggleApprovalSidebar(!this.props.sidebarExpanded)
  }

  render() {
    if (this.props.isLoading) return <Spinner />;
    return (
      <div className="placeholder-container">        
        <div className="placeholder-heading" >
        {!this.props.sidebarExpanded && ( <span className='timesheet-sidebar-dock--no-time'>
                    <a
                      href=''
                      onClick={this.menuButtonClick}
                    >
                      =
                    </a>
                </span>
            )}
        </div>
        <div className="placeholder-text">
          {this.props.employeeList && this.props.employeeList.length ? (
            <p>Select timesheet from the left</p>
          ) : (
            <p>You have no pending approvals.</p>
          )}
        </div>
      </div>
    );
  }
}

export default hoc(NoTimesheetSelected);
