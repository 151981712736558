import React from 'react';
import PropTypes from 'prop-types';

import hoc from '../hoc/timesheetControlProps';
import Clone from './Clone';
import Recall from './Recall';
import Submit from './Submit';

const MobileDashboard = props => {
  var cls = 'timesheet-mobile-dashboard';
  if (props.uiState.showMobileDashboard) cls += ' expanded';
  return (
    <div className={cls}>
      <div>
        <button
          type="button"
          className="close pull-left"
          onClick={props.toggleMobileDashboard}
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="actions-button" onClick={props.toggleMobileDashboard}>
        {/* note we pass false for the hasWeekendTime because having the tooltip does not work well on mobile */}
        <Clone {...props} mobile disabled={!props.isCloneable} />
        <Recall {...props} mobile disabled={!props.isRecallable} />
        <Submit {...props} mobile disabled={!props.isSubmittable} />
      </div>
    </div>
  );
};

MobileDashboard.propTypes = {
  uiState: PropTypes.object.isRequired,
  toggleMobileDashboard: PropTypes.func.isRequired
};

export default hoc(MobileDashboard);
