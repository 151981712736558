import {branch, renderComponent} from 'recompose';
import {compose} from 'redux';
import Spinner from '../components/Spinner';
import errorAlert from './errorAlert';

// render a loading spinner if the isLoading prop is set, and an error alert if there is an error prop
export default compose(
  branch(({isLoading}) => isLoading, renderComponent(Spinner)),
  errorAlert
);
