import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Breadcrumbs = ({selection, onClick}) => (
  <BreadcrumbsContainer className="breadcrumb">
    {selection.map((sel, i) => {
      const current = i === selection.length - 1;
      const handler = e => {
        e.preventDefault();
        if (!current) onClick(i);
      };
      return (
        <a
          href={sel.id}
          key={i}
          onClick={handler}
          className={classNames({
            'breadcrumb--current': current
          })}>
          {sel.id}
          {!current && <strong style={{color: 'black'}}> > </strong>}
        </a>
      );
    })}
  </BreadcrumbsContainer>
);

const BreadcrumbsContainer = styled.div`
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;

  a {
    cursor: pointer;
    text-decoration: none;
  }
  a.breadcrumb--current {
    cursor: default;
    color: initial;
  }
`;

export default Breadcrumbs;
