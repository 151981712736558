import {graphql} from 'react-apollo';
import {compose, withHandlers} from 'recompose';
import {COPY_PREVIOUS_WEEK_MUTATION} from '../../queries';
import {buildTsInput} from '../../../shared/utils/queries';
import makeBlankRow from './_makeBlankRow';

// copyPreviousWeek mutation

export default compose(
  graphql(COPY_PREVIOUS_WEEK_MUTATION, {
    props: ({
      ownProps: {
        setError,
        setSuccess,
        clearLoading,
        setLoading,
        timesheet,
        updateTimesheetData
      },
      mutate
    }) => ({
      copyPreviousWeekMutation(includeTime) {
        setLoading();
        mutate({
          variables: {
            ...buildTsInput(timesheet),
            includeTime
          },
          update(store) {
            // make sure we add a blank row.
            // Normally this is added when loading the timesheet, but here because the id is the same
            // it won't happen automatically
            updateTimesheetData(
              {
                rows: {
                  $push: [makeBlankRow(timesheet)]
                }
              },
              store
            );
          }
        })
          .then(
            ({data: {copyPreviousWeek: {message}}}) => {
              setSuccess(message.toString());
            },
            e => {
              setError(e);
            }
          )
          .then(clearLoading);
      }
    })
  }),
  withHandlers({
    copyPreviousWeek: ({
      timesheet,
      setError,
      showConfirmation
    }) => includeTime => {
      if (timesheet.Submitted) {
        setError('Timesheet has already been submitted');
        return;
      }
      showConfirmation({
        title: 'Copy Last Week',
        message: "Are you sure you wish to copy the last week's timesheet?",
        successAction: ['copyPreviousWeekMutation', includeTime]
      });
    }
  }),
  withHandlers({
    copyPreviousWeek: ({copyPreviousWeek}) => e => {
      copyPreviousWeek(false);
    },
    copyPreviousWeekWithTime: ({copyPreviousWeek}) => e => {
      copyPreviousWeek(true);
    }
  })
);
