import React from 'react';
import identity from 'lodash/identity';

import CalendarHeader from '../../timesheet/components/CalendarHeader';
import EmployeeNameHeader from '../../timesheet/components/EmployeeNameHeader';

import hoc from '../hoc/approvalHeader'

class ApprovalHeader extends React.Component {

  menuButtonClick = (ev) => {
    ev.preventDefault()
    this.props.toggleApprovalSidebar(!this.props.sidebarExpanded)
  }

  render() {
    return (    
      <div className="timesheet-navigation">          
        <div className="timesheet-controls">
          {!this.props.sidebarExpanded && ( <span className='timesheet-sidebar-dock'>
                  <a
                    href=''
                    onClick={this.menuButtonClick}
                  >
                    =
                  </a>
              </span>
          )}
          <div className="timesheet-header">                
            <CalendarHeader
              toggleCalendar={identity}
              current={this.props.timesheet.PeriodStartDate}
            />
            <EmployeeNameHeader currentUser={this.props.currentUser} timesheet={this.props.timesheet} />              
          </div>      
        </div>    
      </div>
    )
  }
};

export default hoc(ApprovalHeader);
