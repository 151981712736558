import React from 'react';

import timesheetRedux from '../hoc/timesheetRedux';
import timesheetRouteParams from '../hoc/timesheetRouteParams';
import labels from '../api/labels';
import {TIMESHEET_MODE} from '../../constants';

import TimesheetBasePage from './TimesheetBasePage';
import Alert from '../../shared/components/Alert';
import TimesheetDetail from '../components/TimesheetDetail';
import TimesheetNavigation from '../components/TimesheetNavigation';
import TimesheetControlBar from '../components/TimesheetControlBar';

/**
 * <TimesheetPage />
 * Detail page for the timesheet, where they can enter their time, as well as
 * navigate to another timesheet.
 */
const TimesheetPage = props => (
  <TimesheetBasePage {...props}>
    {timesheetProps => (
      <React.Fragment>
        <TimesheetNavigation {...timesheetProps} />
        <Alert
          dismiss={props.clearMessages}
          warning={props.uiState.warning}
          error={props.uiState.error}
          success={props.uiState.success}
        />
        <TimesheetDetail
          {...timesheetProps}
          labels={labels}
          forDashboard={false}
          mode={TIMESHEET_MODE}
          savePending={props.uiState.savePending}>
          <TimesheetControlBar />
        </TimesheetDetail>
      </React.Fragment>
    )}
  </TimesheetBasePage>
);

export default timesheetRedux(timesheetRouteParams(TimesheetPage));
