// ui state
export const CLEAR_MESSAGES = 'timesheet:CLEAR_MESSAGES';
export const TOGGLE_CALENDAR = 'timesheet:TOGGLE_CALENDAR';
export const TOGGLE_MOBILE_DASHBOARD = 'timesheet:TOGGLE_MOBILE_DASHBOARD';
export const SET_SUCCESS = 'timesheet:SET_SUCCESS';
export const SET_ERROR = 'timesheet:SET_ERROR';
export const SET_WARNING = 'timesheet:SET_WARNING';
export const SET_LOADING = 'timesheet:SET_LOADING';
export const SET_SAVE_PENDING = 'timesheet:SET_SAVE_PENDING';
export const CLEAR_LOADING = 'timesheet:CLEAR_LOADING';
export const SHOW_CONFIRMATION = 'timesheet:SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'timesheet:HIDE_CONFIRMATION';
export const ON_TIMESHEET_NAVIGATE = 'timesheet:NAVIGATE';
export const SET_TIMESHEET_USER = 'timesheet:SET_TIMESHEET_USER';

// time entry
export const SET_ACTIVE_ENTRY = 'timesheet:SET_ACTIVE_ENTRY';
export const SET_ACTIVE_ROW = 'timesheet:SET_ACTIVE_ROW';
export const SET_EDIT_ROW = 'timesheet:SET_EDIT_ROW';
