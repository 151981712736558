const Authentication = {
  login(user, password) {
    return Authentication.call('login', 'POST', {user, password}).then(
      response => {
        // Should we save anything?
        // currentUser = response.userInfo
        // save the token for future requests
        // localStorage.apiToken = response.token
        return response.userInfo;
      }
    );
  },

  logout() {
    return Authentication.call('logout', 'POST');
  },

  // make an authenticated API call
  call(endpoint, method = 'GET', data = null) {
    const params = {
      method,
      // include cookies with request, and save returned cookies
      credentials: 'include',
      headers: {}
    };
    if (data) {
      params.body = JSON.stringify(data);
      params.headers['Content-Type'] = 'application/json';
    }
    // if (localStorage.apiToken) {
    //   params.headers['X-API-Token'] = localStorage.apiToken
    // }

    // XXX do we need a polyfill for IE?
    return fetch('/api/' + endpoint, params)
      .then(response => {
        // console.log(response);
        if (!response.ok) {
          if (response.status === 500) {
            return Promise.reject('Error communicating with the server');
          }
          return response.text().then(text => Promise.reject(text));
        }
        return response.text().then(text => text && JSON.parse(text));
      })
      .catch(err => {
        console.warn('API Request failed', err);
        return Promise.reject(err);
      });
  }
};
export default Authentication;
