import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../shared/components/Dialog';

/**
 * <AddNoteDialog />
 * Used to pop up the calendar selection screen.
 */
class AddNoteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteValue: props.currentNote,
      submitDisabled: props.submitDisabled
    };
  }

  handleChange = e => {
    this.setState({ noteValue: e.target.value }, () => {
      this.setState({
        submitDisabled: this.state.noteValue.length < 10
          && this.props.mode === 'approval'
          && this.state.submitDisabled
      })      
    })
  };

  onSave = () => {
    this.props.onSaveNote(
      this.state.noteValue,
      this.props.rowIndex,
      this.props.entryIndex,
      this.props.detailIndex
    );
    this.props.onClosed();
  };

  render() {
    return (
      <Dialog
        title="Notes"
        size="small"
        className="timesheet--add-note-dialog"
        showConfirm={!this.props.isReadOnly}
        cancelLabel={this.props.isReadOnly ? 'Close' : 'Cancel'}
        onConfirm={this.onSave}
        onDismiss={this.props.onClosed}
        onOpen={() => this.textareaInput.focus()}
        cancelDisabled={this.props.cancelDisabled}
        submitDisabled={this.state.submitDisabled}>
        <textarea
          className="form-control"
          disabled={this.props.isReadOnly}
          onChange={this.handleChange}
          placeholder={this.props.submitDisabled && 'A note is required when editing a detail record'}
          ref={input => {
            this.textareaInput = input;
          }}
          rows={5}
          value={this.state.noteValue}
        />
      </Dialog>
    );
  }
}
AddNoteDialog.propTypes = {
  onClosed: PropTypes.func.isRequired,
  currentNote: PropTypes.string.isRequired,
  onSaveNote: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export default AddNoteDialog;
