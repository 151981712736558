import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import rowShape from './shapes/rowShape';
import roundTime from '../api/roundTime';
import PercentCompleteSelectionOverlay from './PercentCompleteSelectionOverlay';
import OpOutlookSelectionOverlay from './OpOutlookSelectionOverlay';
import NoteIndicatorOverlay from './NoteIndicatorOverlay';
import Project from './Project';
import TimesheetDayTitles from './TimesheetDayTitles';
import TimesheetEntries from './TimesheetEntries';
import getNoteLockedReason from '../api/getNoteLockedReason';
import getPercentCompleteLockedReason from '../api/getPercentCompleteLockedReason'
import getOpOutlookLockedReason from '../api/getOpOutlookLockedReason'
import getPercentCompleteTooltipReason from '../api/getPercentCompleteTooltipReason'

/**
 * <TimesheetLine />
 * A timesheet line (including row + its header)
 */
class TimesheetLine extends React.Component {
  _onFocus = () => {
    const {
      isActive,
      isTimesheetReadOnly,
      row,
      rowIndex,
      setActiveRow
    } = this.props;

    const isReadOnly = row.closed || isTimesheetReadOnly;

    if (!isActive && !isReadOnly && (row.IndirectCode || row.Operation)) {
      setActiveRow(rowIndex);
    }
  };

  render() {
    const {
      isActive,
      isEdit,
      isSavePending,
      isTimesheetReadOnly,
      mode,
      row,
      rowIndex,
      timesheet,
      updatePercentComplete,
      updateOpOutlook
    } = this.props;

    const hasProjectSelection = !!(row.Operation || row.IndirectCode);
    if (isTimesheetReadOnly && !hasProjectSelection) {
      return null;
    }
    if (row.IsSystemEntry) return null;

    const noteLockedReason = getNoteLockedReason(
      row,
      mode
    )
        
    const percentCompleteLockedReason = getPercentCompleteLockedReason(
      row,
      mode
    )

    const percentCompleteTooltipReason = getPercentCompleteTooltipReason(
      row
    )

    const opOutlookLockedReason = getOpOutlookLockedReason(
      row,
      mode
    )

    const opOutlookTooltip = 
      opOutlookLockedReason &&
      (this.props.labels.opOutlookLockedReason[opOutlookLockedReason] || opOutlookLockedReason)

    const percentCompleteTooltip = 
      (percentCompleteLockedReason &&
      (this.props.labels.percentCompleteLockedReason[percentCompleteLockedReason] || percentCompleteLockedReason)) ||
      (percentCompleteTooltipReason &&
      (this.props.labels.percentCompleteTooltipReason[percentCompleteTooltipReason] || percentCompleteTooltipReason))

    const tooltip = 
      noteLockedReason &&
      (this.props.labels.noteLockedReason[noteLockedReason] || noteLockedReason)

    const cls = classNames('timesheet-line row', {
      'timesheet-line--overhead': !!row.IndirectCode,
      'timesheet-line--new': !row.IndirectCode && !row.Operation,
      'timesheet-line--save-pending': isSavePending,
      'timesheet-line--active': isActive,
      'timesheet-line--edit': isEdit,
      'timesheet-line--closed': row.closed,
    });
    const timesheetProjectClass = classNames('timesheet-project', {
      'col-md-3': (timesheet.Company === '100' || timesheet.Company === '700' || timesheet.Company === '770'),
      'col-md-4': (timesheet.Company !== '100' && timesheet.Company !== '700' && timesheet.Company !== '770')
    })
    const timesheetDaysClass = classNames('timesheet-days col-xs-12', {
      'col-md-6': (timesheet.Company === '100' || timesheet.Company === '700' || timesheet.Company === '770'),
      'col-md-7': (timesheet.Company !== '100' && timesheet.Company !== '700' && timesheet.Company !== '770')
    })
    let isRowEditable =
      !isTimesheetReadOnly &&
      !row.entries.some(
        x =>
          x.TimeAllocation > 0 &&
          (x.Approved || x.Posted || (mode === 'timesheet' && x.Submitted))
      );

    return (
      <div className={cls} onClick={this._onFocus} onFocus={this._onFocus}>
        <div className={timesheetProjectClass}>
          <Project
            {...this.props}
            company={timesheet.Company}
            hasProjectSelection={hasProjectSelection}
            isEdit={isEdit}
            isRowEditable={isRowEditable}
            row={row}
            rowIndex={rowIndex}
            onFocus={this._onFocus}
          />
        </div>
        <div className={timesheetDaysClass}>
          <TimesheetDayTitles entries={row.entries} totals={timesheet.totals} />
          <TimesheetEntries
            {...this.props}
            isReadOnly={!hasProjectSelection || isTimesheetReadOnly}
          />          
        </div>  
        {(this.props.timesheetSource.Company === '100' || this.props.timesheetSource.Company === '700' || this.props.timesheetSource.Company === '770') 
        && (
          <div className='percent-complete-row col-md-1 col-xs-12'>
            <PercentCompleteSelectionOverlay
              row={row}
              disabled={!hasProjectSelection || isTimesheetReadOnly || percentCompleteLockedReason}
              onChange={updatePercentComplete}
              rowIndex={rowIndex}
              tooltip={percentCompleteTooltip}
            />
          </div> 
        )} 
        {(this.props.timesheetSource.Company === '100' || this.props.timesheetSource.Company === '700' || this.props.timesheetSource.Company === '770') 
        && (
          <div className='op-outlook-row col-md-1 col-xs-12'>
            <OpOutlookSelectionOverlay
              row={row}
              disabled={!hasProjectSelection || isTimesheetReadOnly || opOutlookLockedReason}
              rowIndex={rowIndex}
              onChange={updateOpOutlook}
              tooltip={opOutlookTooltip}
            />
          </div> 
        )}            
        <div className="line-controls col-md-1 col-xs-12">
          <span className="row-total">Total</span>
          <span className="row-total">{roundTime(row.total)}</span>
            <NoteIndicatorOverlay
              tooltip={tooltip}
              {...this.props}
            />            
        </div>
      </div>
    );
  }
}

TimesheetLine.propTypes = {
  isActive: PropTypes.bool,
  isEdit: PropTypes.bool,
  // indicates the whole row is pending a save (eg for the narrative)
  isSavePending: PropTypes.bool.isRequired,
  isTimesheetReadOnly: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  row: rowShape.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  timesheet: PropTypes.object.isRequired,
  updateNarrative: PropTypes.func.isRequired
  // updateProject: PropTypes.func.isRequired
};

export default TimesheetLine;
