import React from 'react';
import {Link} from 'react-router-dom';
import SubmitButton from '../components/SubmitButton'
import Header from '../components/Header'
import Spinner from '../../shared/components/Spinner'


class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      validPW: false
    }
  }

  componentDidMount() {
    this.props.verifyToken({token: this.props.match.params.resetToken})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.validationError !== this.props.validationError) {
      this.setState({
        validationError: this.props.validationError
      })
    }
    if (prevProps.validationError !== this.props.validationError) {
      this.setState({
        validationError: this.props.validationError
      })
    }
    if (prevProps.success !== this.props.success) {
      this.setState({
        success: this.props.success
      })
    }
  }
  
  onChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  onFocus = event => {
    document.getElementById('message').style.display = 'block'
  }

  onBlur = event => {
    document.getElementById('message').style.display = 'none'
  }

  onKeyUp = event => {
    const pwInput = document.getElementById('pw')
    const pwInput2 = document.getElementById('pw2')
    const letter = document.getElementById("letter");
    const capital = document.getElementById("capital");
    const number = document.getElementById("number");
    const length = document.getElementById("length");
    const match = document.getElementById("match");
    let validPW = true
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g
    if (pwInput.value.match(lowerCaseLetters)) {
      letter.classList.remove('invalid')
      letter.classList.add('valid')
    } else {
      validPW = false
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }
    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if(pwInput.value.match(upperCaseLetters)) {  
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      validPW = false
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if(pwInput.value.match(numbers)) {  
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      validPW = false
      number.classList.remove("valid");
      number.classList.add("invalid");
    }
    
    // Validate length
    if(pwInput.value.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      validPW = false
      length.classList.remove("valid");
      length.classList.add("invalid");
    }
    // Validate password match
    if (pwInput.value === pwInput2.value &&
        pwInput.value &&
        pwInput2.value
    ) {
      match.classList.remove("invalid");
      match.classList.add("valid");
    } else {
      validPW = false
      match.classList.remove("valid");
      match.classList.add("invalid");
    }    

    this.setState({validPW: validPW})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { newpassword } = this.state
    this.props.changePassword({userID: this.props.userID, resetPasswordToken: this.props.resetPasswordToken, newPassword: newpassword})     
  }

  renderForm() {
    const { newpassword, verifypassword, validPW } = this.state
    return(
      <div className='password-reset'>
        <form className='form-reset' onSubmit={this.handleSubmit}>
          <h3 className='password-reset-heading'>Reset Password</h3>
          <div className='reset-wrap'>
            <div>
              <input
                id='pw'
                placeholder='New Password'
                type='password'
                className='form-control'
                name='newpassword'
                value={newpassword}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyUp={this.onKeyUp}
              />
            </div>
            <div>
              <input
                id='pw2'
                placeholder='Verify Password'
                type='password'
                className='form-control'
                name='verifypassword'
                value={verifypassword}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyUp={this.onKeyUp}
              />
            </div>
            <div>
              <SubmitButton 
                className='btn btn-lg btn-submit btn-block'
                buttontext={'Change Password'} 
                type='submit'
                disabled={!validPW}
              />
            </div>
          </div>          
        </form>
        <div id='message'>
          <h4>Password must contain the following:</h4>
          <p id='letter' className='invalid'>A <b>lowercase</b> letter</p>
          <p id='capital' className='invalid'>A <b>capital (uppercase)</b> letter</p>
          <p id='number' className='invalid'>A <b>number</b></p>
          <p id='length' className='invalid'>Minimum <b>8 characters</b></p>
          <p id='match' className='invalid'>Passwords must match</p>
        </div>
      </div>
    )
  }

  renderError() {
    return (
      <div className='password-reset'>
        <form className='form-reset error'>
          <p>
            Sorry, the reset link is not valid or has expired.
          </p>
        </form>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className='password-reset'>
        <form className='form-reset success'>
          <p>
            Password Reset Successful            
          </p>
          <p>
            <Link to={'/login'}>
              Go To Bedrock
            </Link>
          </p>
        </form>
      </div>
    );
  }

  renderPage() {
    if (this.props.loading) {
      return <Spinner />
    }
    if (this.state.validationError) {
      return this.renderError()
    } else if (this.state.success) {
      return this.renderSuccess()
    } else {
      return this.renderForm()
    }
  }

  render() {
    return (
      <div className='passwordreset-container'>
        <Header {...this.props} />
        {this.renderPage()}
      </div>
    )
  }
}

export default ResetPasswordPage
