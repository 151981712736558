import 'babel-polyfill';
import 'rc-tooltip/assets/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {ApolloProvider} from 'react-apollo';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import createApolloClient from './createApolloClient';
import noTouchShim from './noTouchShim';
// import formReducer from 'redux-form/lib/reducer'

// import registerServiceWorker from './registerServiceWorker';
import {unregister as unregisterServiceWorker} from './registerServiceWorker';
import authReducer from './auth/reducers';
import appReducer from './app/reducers';
import timesheetReducer from './timesheet/reducers';
import approvalReducer from './approval/reducers';
import Routes from './routes';

// Invoke the no-touch shim as part of the application loading.
noTouchShim();

const store = createStore(
  combineReducers({
    // form: formReducer,
    auth: authReducer,
    app: appReducer,
    timesheet: timesheetReducer,
    approval: approvalReducer
  }),
  {}, // initial state
  composeWithDevTools(
    applyMiddleware(
      // Add additional middleware here
      thunk
    )
  )
);
const client = createApolloClient(store);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
// Do not enable service workers.
// It is preventing the training document from being served correctly, because
// it is being interpreted as a fallback URL.
// We'll need to fix it once we can edit the service worker plugin configuration,
// but with CRA it is not an option
// registerServiceWorker()
// and disable it, just in case it was already activated
unregisterServiceWorker();
