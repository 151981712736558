import {createAction} from 'redux-actions';
import {ROOT_CLICK, TOGGLE_MENU, FATAL_ERROR} from './types';

// used to detect clicks outside of menus / modals
export const rootClick = createAction(ROOT_CLICK, () => null);
// for a critical error that should cause a refresh
export const setFatalError = createAction(FATAL_ERROR);
export const toggleMenu = createAction(TOGGLE_MENU, () => null);
// export const onLogout = () => {
//   if(window.location.pathname != '/logout')
//     window.location.replace(window.location.href)
//   return {type: APP_LOGOUT}
// }
