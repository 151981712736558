import React from 'react';

import hoc from '../hoc/page';
import labels from '../api/labels';
import {APPROVAL_MODE} from '../../constants';

import Alert from '../../shared/components/Alert';
import ApprovalControlBar from '../components/ApprovalControlBar';
import ApprovalHeader from '../components/ApprovalHeader';
import ApprovalDetail from '../components/ApprovalDetail';
import ApprovalEmployeeList from '../components/ApprovalEmployeeList';
import NoTimesheetSelected from '../components/NoTimesheetSelected';
import TimesheetBasePage from '../../timesheet/pages/TimesheetBasePage';
import TimesheetDetail from '../../timesheet/components/TimesheetDetail';
import Sidebar from 'react-sidebar'

const mql = window.matchMedia(`(min-width: 1025px)`)
/**
 * <ApprovalPage />
 * Detail page for the timesheet, where they can enter their time, as well as
 * navigate to another timesheet.
 *
 */
class ApprovalPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarDocked: mql.matches
    }

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this)
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged)
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged)
  }

  onSetSidebarOpen(open) {
    this.props.toggleApprovalSidebar(open)
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false })
  }

  render() {
    return (
      // had to place sidebar outside what is now the second <div> or else the content wouldn't render
    <div className="approval-view-container"> 
    <Sidebar
        sidebar={<ApprovalEmployeeList {...this.props} />}
        docked={this.state.sidebarDocked}
        open={this.props.sidebarExpanded}
        onSetOpen={this.onSetSidebarOpen}
        touchHandleWidth={50}
        styles={{ sidebar: {backgroundColor: 'white' } }}
    >
    <div className="approval-view-container">
      
      {this.props.date ? (
        <TimesheetBasePage {...this.props} mode={APPROVAL_MODE}>
          {timesheetprops => (
            <React.Fragment>
              <ApprovalHeader {...timesheetprops} />
              <Alert
                dismiss={this.props.clearMessages}
                warning={this.props.uiState.warning}
                error={this.props.uiState.error}
                success={this.props.uiState.success}
              />
              {this.props.view === 'summary' && (
                <TimesheetDetail
                  {...timesheetprops}
                  labels={labels}
                  forDashboard={false}
                  savePending={this.props.uiState.savePending}
                  uiState={{...this.props.uiState}}>
                  <ApprovalControlBar />
                </TimesheetDetail>
              )}
              {this.props.view === 'detail' && (
                <ApprovalDetail
                  {...timesheetprops}
                  labels={labels}
                  savePending={this.props.uiState.savePending}
                />
              )}
            </React.Fragment>
          )}
        </TimesheetBasePage>
      ) : (
        <NoTimesheetSelected {...this.props} />
      )}        
      </div>  
      </Sidebar>
      </div>
    )
  }
};

export default hoc(ApprovalPage);
