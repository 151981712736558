import {createAction} from 'redux-actions';
import {showMessageFor} from '../../constants'

import * as types from './types';

export const clearMessages = createAction(types.CLEAR_MESSAGES);
export const setError = e => dispatch => {
  dispatch({type: types.SET_ERROR, payload: e});
  setTimeout(
    () => dispatch({type: types.SET_ERROR, payload: ''}),
    showMessageFor
  );
};
