import React from 'react';
import PropTypes from 'prop-types';

import TimesheetTotals from './TimesheetTotals';
import MobileDashboard from './MobileDashboard';

/**
 * <TimesheetFooter />
 * Action panel at the bottom of the timesheet (including the submit button)
 */
function TimesheetFooter(props, context) {
  return (
    <div className="timesheet-footer">
      <MobileDashboard {...props} />
      <TimesheetTotals {...props} />
    </div>
  );
}
TimesheetFooter.propTypes = {
  timesheet: PropTypes.object
};

export default TimesheetFooter;
