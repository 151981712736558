import { escape } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Alert, Modal, Button } from 'react-bootstrap';
import { graphql } from 'react-apollo';
import ReactSelect from 'react-select';
import {
  CREATE_NCR_MUTATION,
  NCRCOMPANYLIST_LOOKUP_QUERY,
  NCRPROJECTLIST_LOOKUP_QUERY,
  NCRJOBLIST_LOOKUP_QUERY,
  NCRSUPPLIERS_LOOKUP_QUERY,
  NCRCHAMPION_LOOKUP_QUERY,
  NCRINITIATOR_LOOKUP_QUERY,
  NCRSOURCES_LOOKUP_QUERY,
  NCRREASONS_LOOKUP_QUERY,
  NCRLOCATIONS_LOOKUP_QUERY,
  NCRAREAS_LOOKUP_QUERY
} from '../../../timesheet/queries';

// import '../../../../userCodes.js';

function NCRForm({ onFormSubmitSuccess, onFormSubmitError }) {
  const initialState = {
    "CompanyID": "",
    "ProjectID": "",
    "JobCode": "",
    "SourceTypeID": "",
    "SourceType": "",
    "CaseTitle": "",
    "CaseSummary": "",
    "SupplierNumber": "",
    "SupplierName": "",
    "IncidentDate": "",
    "IncidentLocation": "",
    "OtherLocation": "",
    "IncidentArea": "",
    "NCRChampionID": "",
    "NCRChampion": "",
    "CaseTypeID": "",
    "CaseType": "",
    "ProjectRelated": true,
    "SafetyCritical": false,
    "LegalCompliance": false,
    "NCRReason": "",
    "NCRInitiator": "",
    "Notes": "",
    "NCRReasonID": "",
    "NCRInitiatorID": ""
  };

  const [formData, setFormData] = useState(initialState);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [champions, setChampions] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [initiator, setInitiator] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [sources, setSources] = useState([]);
  const [areas, setAreas] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hdCaseNum, setHdCaseNum] = useState(null);
  const alertRef = useRef(null);

  const [dropdownData, setDropdownData] = useState([]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSelectChange = (selectedOption, field) => {
    if (field === 'CompanyID') {
      setFormData({
        ...initialState,
        [field]: selectedOption,
      });
    } else {
      setFormData({
        ...formData,
        [field]: selectedOption,
      });
    }
  };
  

  useEffect(() => {

    const fetchCompanies = async () => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRCOMPANYLIST_LOOKUP_QUERY,
            variables: {
            },
          }),
        });
        if (!response.ok) {
          throw new Error('Companies fetch failed');
        }
        const data = await response.json();
        setCompanies(data.data.ncrCompanyList);

      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    // Fetch projects when the component mounts
    const fetchProjects = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRPROJECTLIST_LOOKUP_QUERY,
            variables: {
                company: CompanyID
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Projects fetch failed');
        }
        const data = await response.json();
        setProjects(data.data.ncrProjectList);

      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchJobs = async (ProjectID, CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRJOBLIST_LOOKUP_QUERY,
            variables: {
                company: CompanyID,
                projectID: ProjectID
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Jobs fetch failed');
        }
        const data = await response.json();
        setJobs(data.data.ncrJobList);

      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchLocations = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRLOCATIONS_LOOKUP_QUERY,
            variables: {
              company: CompanyID,
              whereClauseUDCodeType: "CodeTypeID = 'CMLOCATION'", 
              pageSize: 0, 
              absolutePage: 1
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Location fetch failed');
        }
        const data = await response.json();
        setLocations(data.data.ncrLocations);

      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    const fetchAreas = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRAREAS_LOOKUP_QUERY,
            variables: {
              company: CompanyID,
              whereClauseUDCodeType: "CodeTypeID = 'CMAREA'", 
              pageSize: 0, 
              absolutePage: 1
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Area fetch failed');
        }
        const data = await response.json();
        setAreas(data.data.ncrAreas);

      } catch (error) {
        console.error('Error fetching Area:', error);
      }
    };

    const fetchNCRChampions = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRCHAMPION_LOOKUP_QUERY,
            variables: {
                company: CompanyID
              },
          }),
        });
        if (!response.ok) {
          throw new Error('NCR Champion fetch failed');
        }
        const data = await response.json();
        setChampions(data.data.ncrChampion);

      } catch (error) {
        console.error('Error fetching NCR Champion:', error);
      }
    };

    const fetchSuppliers = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRSUPPLIERS_LOOKUP_QUERY,
            variables: {
                company: CompanyID
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Supplier fetch failed');
        }
        const data = await response.json();
        setSuppliers(data.data.ncrSupplierList);

      } catch (error) {
        console.error('Error fetching Supplier:', error);
      }
    };

    const fetchInitiator = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRINITIATOR_LOOKUP_QUERY,
            variables: {
              company: CompanyID
            },
          }),
        });
        if (!response.ok) {
          throw new Error('Initiator fetch failed');
        }
        const data = await response.json();
        setInitiator(data.data.ncrInitiators);

      } catch (error) {
        console.error('Error fetching initiator:', error);
      }
    };

    const fetchReasons = async (CompanyID) => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRREASONS_LOOKUP_QUERY,
            variables: {
              company: CompanyID,
              whereClauseReason: "JDFWorkType = 'NCR'", 
              pageSize: 0, 
              absolutePage: 1
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Reasons fetch failed');
        }
        const data = await response.json();
        setReasons(data.data.ncrReasons);

      } catch (error) {
        console.error('Error fetching reasons:', error);
      }
    };

    const fetchSources = async (CompanyID) => {
      
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: NCRSOURCES_LOOKUP_QUERY,
            variables: {
              company: CompanyID,
              whereClauseUDCodeType: "CodeTypeID = 'CMSOURCE'", 
              pageSize: 0, 
              absolutePage: 1
              },
          }),
        });
        if (!response.ok) {
          throw new Error('Source fetch failed');
        }
        const data = await response.json();
        setSources(data.data.ncrSources);

      } catch (error) {
        console.error('Error fetching sources:', error);
      }
    };

    fetchCompanies();
    if (formData.CompanyID) {
      fetchProjects(formData.CompanyID);
      fetchNCRChampions(formData.CompanyID);
      fetchSuppliers(formData.CompanyID);
      fetchInitiator(formData.CompanyID);
      fetchLocations(formData.CompanyID);
      fetchReasons(formData.CompanyID);
      fetchSources(formData.CompanyID);
      fetchAreas(formData.CompanyID);
    }
    if (formData.ProjectID) {
      fetchJobs(formData.ProjectID, formData.CompanyID);
    }
  }, [formData.ProjectID, formData.CompanyID]);

  const handleSupplierChange = (selectedOption) => {
    const selectedSupplier = suppliers.find(supplier => supplier.VendorNum.toString() === selectedOption.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      SupplierName: selectedSupplier ? selectedSupplier.VendorName : '',
      SupplierNumber: selectedSupplier ? selectedSupplier.VendorNum : '',
    }));
  };

  const handleReasonChange = (selectedOption) => {
    const selectedReason = reasons.find(reason => reason.value.toString() === selectedOption.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      NCRReason: selectedReason ? selectedReason.label : '',
      NCRReasonID: selectedReason ? selectedReason.value : '',
    }));
  };

  const handleSourceChange = (selectedOption) => {
    const selectedSource = sources.find(source => source.value.toString() === selectedOption.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      SourceType: selectedSource ? selectedSource.label : '',
      SourceTypeID: selectedSource ? selectedSource.value : '',
    }));
  };

  async function handleSubmit(event) {
    event.preventDefault();
  
    try {
      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: CREATE_NCR_MUTATION,
          variables: {
            company: formData.CompanyID,
            efxLibraryID: "NCR",
            efxFunctionID: "create-new-ncr",
            params: formData
          }
        })
      });
      if (!response.ok) {
        throw new Error('Form submission failed');
      }
  
      const jsonResponse = await response.json();
  
      setHdCaseNum(jsonResponse.data.createNCR.HDCaseNum);
      onFormSubmitSuccess(jsonResponse.data.createNCR.HDCaseNum);
      setFormData(initialState);
  
    } catch (error) {
      console.error('Error:', error);
      setShowErrorAlert(true);
      onFormSubmitError('An error occurred while submitting the form.');
      setTimeout(() => setShowErrorAlert(false), 5000);
    }
  }

  const companyOptions = companies.map((company) => ({
    value: company.CompanyID,
    label: `${company.CompanyID} - ${company.Name}`,
  }));

  const projectOptions = projects.map((project) => ({
    value: project.ProjectID,
    label: `${project.ProjectID} - ${project.ProjectName}`,
  }));

  const jobOptions = jobs.map((job) => ({
    value: job.JobNum,
    label: `${job.JobNum} ${job.JobDescription}`,
  }));

  const locationOptions = locations.map((location) => ({
    value: location.value,
    label: location.label,
  }));

  const areaOptions = areas.map((area) => ({
    value: area.value,
    label: area.label,
  }));

  const championOptions = champions.map((champion) => ({
    value: champion.value,
    label: champion.label,
  }));

  const reasonOptions = reasons.map((reason) => ({
    value: reason.value,
    label: reason.label,
  }));

  const sourceOptions = sources.map((source) => ({
    value: source.value,
    label: source.label,
  }));

  const supplierOptions = suppliers.map((supplier) => ({
    value: supplier.VendorNum,
    label: supplier.VendorName,
  }));

  const initiatorOptions = initiator.map((init) => ({
    value: init.value,
    label: init.label,
  }));

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label htmlFor="CompanyID">
          <span className="ncr-required">
            <span className="text-danger">* </span>Company:
          </span>
        </label>
        <ReactSelect
          id="CompanyID"
          name="CompanyID"
          value={formData.CompanyID}
          required={formData.ProjectRelated}
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'CompanyID')
          }
          options={companyOptions}
          placeholder="Select a Company"
        />

        <label htmlFor="ProjectID">
          <span className="ncr-required">
            <span className="text-danger">* </span>Project ID:
          </span>
        </label>
        <ReactSelect
          id="ProjectID"
          name="ProjectID"
          value={formData.ProjectID}
          required={formData.ProjectRelated}
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'ProjectID')
          }
          options={projectOptions}
          placeholder="Select a Project"
        />

        <label htmlFor="JobCode">
          <span className="ncr-required">
            <span className="text-danger">* </span>Job:
          </span>
        </label>
        <ReactSelect
          id="JobCode"
          name="JobCode"
          value={formData.JobCode}
          required={formData.ProjectRelated}
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'JobCode')
          }
          options={jobOptions}
          placeholder="Select a Job"
        />

        <label htmlFor="SourceTypeID">
          <span className="ncr-required">
            <span className="text-danger">* </span>Source Type:
          </span>
        </label>
        <ReactSelect
          id="SourceTypeID"
          name="SourceTypeID"
          value={formData.SourceTypeID}
          required
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'SourceTypeID')
          }
          options={sourceOptions}
          placeholder="Select Source Type"
        />

        <label htmlFor="caseTitle">
          <span className="ncr-required">
            <span className="text-danger">* </span>Case Title:
          </span>
        </label>
        <input type="text" id="caseTitle" name="CaseTitle" value={formData.CaseTitle} onChange={handleChange} autoComplete="off" required />

        <label htmlFor="caseSummary">
          <span className="ncr-required">
            <span className="text-danger">* </span>Case Summary:
          </span>
        </label>
        <textarea id="caseSummary" name="CaseSummary" value={formData.CaseSummary} onChange={handleChange} autoComplete="off" style={{ resize: 'none' }} required rows="4"></textarea>

        <input type="checkbox" id="projectrelated" name="ProjectRelated" checked={formData.ProjectRelated} onChange={handleChange} />
        <label htmlFor="projectRelated">Project Related</label>

        <input type="checkbox" id="safetyCritical" name="SafetyCritical" checked={formData.SafetyCritical} onChange={handleChange} />
        <label htmlFor="safetyCritical">EHS Critical</label>

        <input type="checkbox" id="legalCompliance" name="LegalCompliance" checked={formData.LegalCompliance} onChange={handleChange} />
        <label htmlFor="legalCompliance">Legal Compliance</label>

        <label htmlFor="incidentDate">
          <span className="ncr-required">
            <span className="text-danger">* </span>Incident Date:
          </span>
        </label>
        <input type="date" id="incidentDate" name="IncidentDate" value={formData.IncidentDate} onChange={handleChange} required />

        <label htmlFor="IncidentLocation">
          <span className='ncr-required'>
            <span className="text-danger">* </span>Incident Location/Area:
          </span>
        </label>
        <ReactSelect
          id="IncidentLocation"
          name="IncidentLocation"
          value={formData.IncidentLocation}
          required
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'IncidentLocation')
          }
          options={locationOptions}
          placeholder="Select a Location"
        />

        <label htmlFor="otherLocation">Other Location:</label>
        <input type="text" id="otherLocation" name="OtherLocation" value={formData.OtherLocation} onChange={handleChange} autoComplete="off" />

        <label htmlFor="IncidentArea">
          <span className="ncr-required">
            <span className="text-danger">* </span>Affected Department:
          </span>
        </label>
        <ReactSelect
          id="IncidentArea"
          name="IncidentArea"
          value={formData.IncidentArea}
          required
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'IncidentArea')
          }
          options={areaOptions}
          placeholder="Affected Department"
        />

        <label htmlFor="NCRChampionID">
          <span className="ncr-required">
            <span className="text-danger">* </span>NCR Champion:
          </span>
        </label>
        <ReactSelect
          id="NCRChampionID"
          name="NCRChampionID"
          value={formData.NCRChampionID}
          required
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'NCRChampionID')
          }
          options={championOptions}
          placeholder="Select an NCR Champion"
        />

        <label htmlFor="NCRReasonID"> 
          <span className="ncr-required">
            <span className="text-danger">* </span>Reason:
          </span>
        </label>
        <ReactSelect
          id="NCRReasonID"
          name="NCRReasonID"
          value={formData.NCRReasonID}
          required
          onChange={handleReasonChange}
          options={reasonOptions}
          placeholder="Select a Reason"
        />

        <label>Supplier:</label>
        <ReactSelect
          name="Supplier"
          value={formData.SupplierNumber}
          onChange={handleSupplierChange}
          options={supplierOptions}
          placeholder="Select a Supplier"
        />

        <label htmlFor="NCRInitiatorID">
          <span className="ncr-required">
            <span className="text-danger">* </span>NCR Initiator:
          </span>
        </label>
        <ReactSelect
          id="NCRInitiatorID"
          name="NCRInitiatorID"
          value={formData.NCRInitiatorID}
          required
          onChange={(selectedOption) =>
            handleSelectChange(selectedOption.value, 'NCRInitiatorID')
          }
          options={initiatorOptions}
          placeholder="Select an NCR Initiator"
        />

        <label htmlFor="Notes">Notes:</label>
        <textarea id="Notes" name="Notes" value={formData.Notes} onChange={handleChange} autoComplete="off" rows="4" style={{ resize: 'none' }}></textarea>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </>
  );
}

export default NCRForm;