export const TOGGLE_USER = 'approval:TOGGLE_USER';
export const TOGGLE_DIRECT_REPORT = 'approval:TOGGLE_DIRECT_REPORT';

export const SET_EDIT_ROW = 'approval:SET_EDIT_ROW';
export const SET_FOCUS_ROW = 'approval:SET_FOCUS_ROW';
export const SET_NAME_FILTER = 'approval:SET_NAME_FILTER';

export const SET_DEFAULT_VIEW = 'approval:SET_DEFAULT_VIEW';
export const SET_CURRENT_PATH = 'approval:SET_CURRENT_PATH';

export const SET_SUPERVISOR_SELECT = 'approval:SET_SUPERVISOR_SELECT';

export const TOGGLE_APPROVAL_SIDEBAR = 'approval:SET_APPROVAL_SIDEBAR';

export const SET_EXPANDED_ROWS = 'approval:SET_EXPANDED_ROWS';
