import React from 'react';
import PropTypes from 'prop-types';
import {withState} from 'recompose'
import DebounceInput from 'react-debounce-input';
import classNames from 'classnames';
import isInternetExplorer from '../../utils/isInternetExplorer';
import AddNoteDialog from './AddNoteDialog'

// for IE, because of problem with cursor getting set to the end of the control
const USE_TEXT_INPUT = isInternetExplorer();

class TimesheetNumberCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingValue: this.getEditingValue(props.value)
    };

    this.onKeyPress = e => {
      if ((e.key < '0' || e.key > '9') && e.charCode !== 46) {
        e.preventDefault();
      }
    };

    this.onClosed = () => {
      this.props.setDialogOpen(false)
      if (this.props.onDialogClose) this.props.onDialogClose()
    }

    const showDialog = () => {
      // require a note to be entered if editing time
      this.props.setDialogOpen(true)        
    }

    this.onChange = e => {
      if (this.props.mode === 'approval' && this.props.view === 'detail') {
        showDialog()
      }      

      // console.log('ONCHANGE -> SAVE', e.target.value)
      this.setState({
        editingValue: e.target.value
      });
      if (/\.$/.test(e.target.value)) {
        // we don't have a number yet
        return;
      }
      let v = Number(e.target.value);
      if (isNaN(v)) {
        return; // error?
      }
      if (v < 0) {
        // they can't type in a - sign, but they can use the arrow keys to change to a negative number
        v = 0;
      }
      // force rounding in .25 increment
      if (this.props.isRounded) v = Math.round(v * 4) / 4;
      else v = Math.round(v * 100) / 100;
      // console.log('ROUNDING TO ', v)
      this.setState({
        editingValue: v
      });
      this.props.onChange(v);
    };
    this.onBlur = e => {
      // console.log('ONBLUR')
      let v = Number(this.state.editingValue);
      if (isNaN(v)) {
        v = Number(this.props.value);
        if (isNaN(v)) v = 0;
      }
      // console.log('onBlur, new value', v)
      this.setState({
        editingValue: this.getEditingValue(v)
      });
    };
    this.onFocus = e => {
      const input = e.target;
      // on focus, make sure we reset the editing value as the unformatted version
      // (and if it is 0, set it to a blank)
      this.setState({
        editingValue: this.props.value || ''
      });
      // this is a hack for IE, because it always resets the cursor selection at the beginning of the text box.
      // as a side effect, we also move it to the end when the user clicks at the beginning of a textbox in Chrome,
      // but according to TAIT that is actually a benefit
      if (USE_TEXT_INPUT)
        setTimeout(
          () => input.setSelectionRange(input.value.length, input.value.length),
          100
        );
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.value !== nextProps.value &&
      (this.state.editingValue !== nextProps.value || nextProps.isSavePending)
    ) {
      // console.log('Received new props', nextProps)
      this.setState({
        editingValue: this.getEditingValue(nextProps.value)
      });
    }
  }

  getEditingValue(val) {
    return val ? val.toFixed(2) : '';
    // return val.toFixed(2)
  }

  renderContent() {
    if (this.props.isReadOnly) {
      return (
        <input
          type="text"
          className="form-control"
          readOnly={true}
          disabled={true}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          value={this.getEditingValue(this.props.value)}
        />
      );
    }
    // debounce to delay the save a little bit
    return (
      <DebounceInput
        debounceTimeout={1500}
        type={USE_TEXT_INPUT ? 'text' : 'number'}
        className="form-control"
        disabled={this.props.isSavePending}
        min="0"
        step={0.25}
        onKeyPress={this.onKeyPress}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        value={String(this.state.editingValue)}
        placeholder={this.props.remainingHours}
      />
    );
  }

  render() {
    const cls = classNames('timesheet-cell timesheet-cell--entry', {
      'timesheet-cell--save-pending': this.props.isSavePending,
      'timesheet-cell--approved': this.props.isApproved,
      'timesheet-cell--posted': this.props.isPosted,
      'timesheet-cell--submitted': this.props.isSubmitted,
      'has-icon': this.props.hasOvernight || this.props.hasOvernightOff,
      'timesheet-cell--disabled': this.props.isReadOnly
    });

    const clsOvernight = classNames('timesheet-cell--overnight',{
      'approval-detail-cell--overnight': this.props.mode === 'approval' && this.props.view === 'detail'
    })

    const clsOvernightOff = classNames('timesheet-cell--overnightoff', {
      'approval-detail-cell--overnightoff': this.props.mode === 'approval' && this.props.view === 'detail'
    })

    return (
      <div onFocus={this.props.onFocus} className={cls}>
        {this.renderContent()}
        {this.props.hasOvernightOff && (
          <div className={clsOvernightOff} title="Overnight Off" />
        )}
        {this.props.hasOvernight && (
          <div className={clsOvernight} title="Overnight" />
        )}        
        {this.props.dialogOpen && (
          <AddNoteDialog 
            onClosed={this.onClosed}
            rowIndex={this.props.rowIndex}
            entryIndex={this.props.entryIndex}
            detailIndex={this.props.detailIndex}
            cancelDisabled={true}
            submitDisabled={true}
            onSaveNote={this.props.updateNarrative}
          />
        )}
      </div>
    );
  }
}
TimesheetNumberCell.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isSavePending: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isPosted: PropTypes.bool.isRequired,
  // Do you want to have it round by .25?
  // (this will set the step on the input control - does not work on IE)
  isRounded: PropTypes.bool,
  value: PropTypes.number
};

const container = withState('dialogOpen', 'setDialogOpen', false);

export default container(TimesheetNumberCell);
