// Provide data for the project / job / operation dropdown.
// Data comes from 3 GraphQL queries.  They are activated based on the current selection.
// The selection is maintained in the row (which is stored in the GraphQL query)
// The dropdown is only active for rows that don't have a selection yet.
// Conversedly while there is no operation selected the user can't enter time.

import {compose, withHandlers, withProps, withState} from 'recompose';
import {graphql} from 'react-apollo';

import buildRowFromSelections from './handlers/_buildRowFromSelections';
import {
  JOB_LOOKUP_QUERY,
  OPERATION_LOOKUP_QUERY,
  PROJECT_LOOKUP_QUERY,
  INDIRECT_LOOKUP_QUERY,
  REWORK_LOOKUP_QUERY,
  JOB_BY_COMPANY_SEARCH
} from '../queries';

const makeItems = (lookupValues, type, leaf = false) => {
  return lookupValues.map(x => makeItem(x, leaf, type));
};

const makeItem = (input, leaf, type) => {
  // we need to add rework prop to operation item
  if (type === 'JobsByCompany') {
    // need to update project data
    return {
      id: input.value,
      title: input.label,
      type: type || input.__typename,
      leaf: input.Rework ? !input.Rework : leaf,
      ProjectID: input.ProjectID,
      ProjectDesc: input.ProjectDesc
    }
  }
  if (type === 'Operation') {
    return {
      id: input.value,
      title: input.label,
      type: type || input.__typename,
      leaf: input.Rework ? !input.Rework : leaf,
      Rework: input.Rework ? input.Rework : null,
      RunQty: input.RunQty,
      CompletedQty: input.CompletedQty,
      Outlook: input.Outlook,
      OpComplete: input.OpComplete
    };
  }
  return {
    id: input.value,
    title: input.label,
    type: type || input.__typename,
    leaf: input.Rework ? !input.Rework : leaf,
  };
};

// check if there is a row with matching values
// (we have to check all the values, not just the last one, because they are not unique across different projects)
const selectionAlreadyExists = (
  timesheet,
  currentRow,
  rowIndex,
  key,
  value
) => {
  const newSelection = {
    [key]: value,
    Job: currentRow.Job && currentRow.Job.value,
    Operation: currentRow.Operation && currentRow.Operation.value
  };
  const keys = Object.keys(newSelection);

  for (let x = 0; x < timesheet.rows.length; x++) {
    if (x === rowIndex) {
      continue;
    }

    let row = timesheet.rows[x];
    if (keys.every(k => (row[k] && row[k].value) === newSelection[k])) {
      return true;
    }
  }
  return false;
};

const selectionAllowed = (timesheet, currentRow, rowIndex) => {
  if(timesheet.Subcontractor.Contracted || timesheet.Subcontractor.Freelancer){
    const newSelection = {
      Job: currentRow.Job && currentRow.Job.value,
    };
    const keys = Object.keys(newSelection);

    for (let x = 0; x < timesheet.rows.length; x++) {
      if (x === rowIndex) {
        continue;
      }

      let row = timesheet.rows[x];
      if (keys.every(k => (row[k] && row[k].value) === newSelection[k])) {
        return true;
      }
    }
  } else {
    return false;
  }
}

export default compose(
  withProps(({timesheet}) => ({
    company: timesheet.Company,
    empId: timesheet.EmployeeNum,
    periodStartDate: timesheet.PeriodStartDate,
    periodEndDate: timesheet.PeriodEndDate, 
  })),
  withState('selection', 'setSelection', ({row}) =>
    [
      row.Project && makeItem(row.Project, false, 'Project'),
      row.Job && makeItem(row.Job, false, 'Job'),
      row && row.Operation && makeItem(row.Operation, false, 'Operation'),
      row && row.Rework && makeItem(row.Rework, false, 'ReworkCode'),
    ].filter(x => x)
  ),
  // reading the projects
  graphql(PROJECT_LOOKUP_QUERY, {
    options: ({company, empId, periodStartDate, periodEndDate}) => ({
      variables: {
        company,
        empId,
        periodStartDate,
        periodEndDate
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }),
    props: ({data}) => {
      return {
        loading: !data.projects,
        items:
          data.projects &&
          makeItems(data.projects, 'Project'),
        error: data.error
      };
    },
    // only run if they are selecting the project
    skip: ({selection}) => {
      // console.log('Check project skip', selection.length !== 0)
      return selection.length !== 0;
    }
  }),
  graphql(JOB_LOOKUP_QUERY, {
    options: ({company, selection}) => ({
      variables: {
        projectId: selection.length > 0 && selection.slice(-1)[0].id,
        company
      },
      // otherwise, the component won't repaint when we are done loading, if the
      // jobs data is the same...
      notifyOnNetworkStatusChange: true
    }),
    props: ({data}) => {
      return {
        loading: data.loading,
        items: data.jobs && makeItems(data.jobs, 'Job'),
        error: data.error
      };
    },
    // only run if they are selecting the job
    skip: ({selection}) =>
      selection.length !== 1 || selection[0].id === 'INDIRECT' || selection[0].id === 'SEARCH BY JOB'
  }),
  graphql(INDIRECT_LOOKUP_QUERY, {
    props: ({data}) => {
      return {
        loading: data.loading,
        items:
          data.indirectCodes &&
          makeItems(data.indirectCodes, 'IndirectCode', true),
        error: data.error
      };
    },
    // only run if they are selecting the indirect code
    skip: ({selection}) =>
      selection.length !== 1 || selection[0].id !== 'INDIRECT'
  }),
  graphql(JOB_BY_COMPANY_SEARCH, {
    options: ({company, empId, periodStartDate, periodEndDate}) => ({
      variables: {
        company,
        projectId: '',
        empId,
        periodStartDate,
        periodEndDate
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }),
    props: ({data}) => {
      return {
        loading: !data.getJobsByCompany,
        items: data.getJobsByCompany && makeItems(data.getJobsByCompany, 'JobsByCompany'),
        error: data.error
      };
    },
    skip: ({selection}) => 
      selection.length !== 1 || selection[0].id !== 'SEARCH BY JOB'
  }),
  graphql(OPERATION_LOOKUP_QUERY, {
    options: ({company, selection}) => ({
      variables: {
        jobNum: selection.length > 0 && selection.slice(-1)[0].id,
        company
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({data}) => {
      return {
        loading: !data.operations,
        items: data.operations && makeItems(data.operations, 'Operation', true),
        error: data.error
      };
    },
    // only run if they are selecting the project
    skip: ({selection}) => selection.length !== 2
  }),
  graphql(REWORK_LOOKUP_QUERY, {
    options: ({ company }) => ({
      variables: {
        company
      }
    }),
    props: ({ data }) => {
      return {
        loading: !data.reworkCodes,
        items:
          data.reworkCodes &&
          makeItems(data.reworkCodes, 'ReworkCode', true),
        error: data.error
      }
    },
    // only run if they have selected a rework operation
    skip: ({ selection }) => selection.length !== 3
  }),
  withHandlers({
    resetSelections: ({rowIndex, setSelection}) => () => {
      setSelection([]);
    },
    selectItem: ({
      onCompleted,
      rowIndex,
      selection,
      setError,
      setSelection,
      timesheet,
      updateRow
    }) => item => {
      let value = [...selection, item];

      if (item.leaf) {
        value = value.map(val => ({
          label: val.title,
          type: val.type,
          value: val.id,
          Rework: val.Rework,
          RunQty: val.RunQty,
          CompletedQty: val.CompletedQty,
          Outlook: val.Outlook,
          OpComplete: val.OpComplete
        }));
        const row = buildRowFromSelections(value);

        if (
          selectionAlreadyExists(timesheet, row, rowIndex, item.type, item.id)
        ) {
          // setError('There is already an entry for this ' + item.type);
          // return;
        }

        if (
          selectionAllowed(timesheet, row, rowIndex, item.type, item.id)
        ) {
          setError('Subcontractors may only clock to one operation per job');
          return;
        }

        updateRow(rowIndex, row);
        onCompleted && onCompleted();
      } else {
        if (item.type === 'JobsByCompany') {
          value[0].id = item.ProjectID
          value[0].title = item.ProjectDesc
        }
        setSelection(value);
      }
    },
    unselectItem: ({selection, setSelection}) => () => {
      if (selection.length === 0) {
        throw new Error('Invalid selection length');
      }

      setSelection(selection.slice(0, -1));
    },
    setSelectionLength: ({selection, setSelection}) => length => {
      setSelection(selection.slice(0, length));
    }
  })
);
