import {withProps, compose} from 'recompose';
import moment from 'moment';
import recallTimesheetApproval from '../../approval/hoc/handlers/recallTimesheetApproval';

// extract props used by timesheet control buttons
export default compose(
  withProps(
    ({
      copyPreviousWeek,
      copyPreviousWeekWithTime,
      currentUser,
      forDashboard,
      mobile = false,
      recallTimesheetSubmission,
      setTimesheetUser,
      submitTimesheet,
      submitAndApproveTimesheet,
      timesheet,
      timesheetUser
    }) => {
      const {
        isApproved,
        isPosted,
        isSubmitted,
        isSomeSubmitted,
        PeriodStartDate: startDate,
        totals
      } = timesheet;
  
      const isApprovedOrPosted = isApproved || isPosted;
      const isCloneable = !isSomeSubmitted;
      const isRecallable =
        moment(startDate) <= Date.now() &&
        !isApprovedOrPosted &&
        totals.some(x => {
          return !x.Posted && !x.Approved && x.Submitted;
        });
      const isSubmittable =
        moment(startDate) <= Date.now() &&
        !isApprovedOrPosted &&
        !isSubmitted &&
        totals.some(x => {
          return !x.Posted && !x.Approved && !x.Submitted;
        });
  
      return {
        canApprove: timesheetUser !== currentUser.id,
        copyPreviousWeek,
        copyPreviousWeekWithTime,
        currentUser,
        forDashboard,
        mobile,
        isApprovedOrPosted,
        isCloneable,
        isRecallable,
        isSubmittable,
        onRecall: recallTimesheetSubmission,
        onSubmit: submitTimesheet,
        onSubmitAndApprove: submitAndApproveTimesheet,
        setTimesheetUser,
        timesheet,
        timesheetUser
      };
    }
  ),
  recallTimesheetApproval
)

