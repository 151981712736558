import pick from 'lodash/pick';
import get from 'lodash/get'

export function buildTsInput(timesheet) {  
  const ts1 = pick(timesheet, [
    'PeriodStartDate',
    'PeriodEndDate',
    'EmployeeNum',
    'OnCallEmployee',
    'Company',      
  ])
  const ts2 = {
    Contracted: get(timesheet, 'Subcontractor.Contracted'),
    Freelancer: get(timesheet, 'Subcontractor.Freelancer')
  }
  return {
    ts: {...ts1, ...ts2}
  };
}

export const timesheetFields = `
  id
  EmployeeNum
  EmployeeName
  OnCallEmployee
  Subcontractor {
    Freelancer
    Contracted
    Contracts {
      Start
      End
      ProjectID
    }
    ActiveWeekContract {
      Valid
      Date
    }
  }
  Company
  PeriodStartDate
  PeriodEndDate
  rows {
    id
    Project {
      label
      value
    }
    Job {
      label
      value
      Closed
    }
    Operation {
      label
      value
      Rework
      RunQty
      CompletedQty
      Outlook
      OpComplete
    }
    IndirectCode {
      label
      value
    }
    ReworkCode {
      label
      value
    }
    Narrative
    IsSystemEntry
    entries {
      id      
      Date
      Detail {
        id
        TimeAllocation
        ClockInDate
        ClockInTime
        ClockOutDate
        ClockOutTime
        Narrative
      }
      LaborHed {
        laborHedSeq
        payrollDate
        clockInTime
        clockOutTime
        payType
        payHours
      }
      OvernightCompensation      
      Approved
      Submitted
      PostedToGL
      PayType
    }
  }
  timeInLieu {
    id
    Date
    InLieuType
  }
  timeOnCall {
    id,
    Date,
    OnCall
  }
  timeOff {
    id
    Date
    PayHours  
  }
  UnsubmittedTime
`;
