import PropTypes from 'prop-types';
import {withHandlers, compose, setPropTypes} from 'recompose';
import {graphql} from 'react-apollo';
import every from 'lodash/every';
import fromPairs from 'lodash/fromPairs';
import {DELETE_ROW_MUTATION} from '../../queries';
import buildRowInput from './_buildRowInput';
// import makeBlankRow from './_makeBlankRow'

export default compose(
  setPropTypes({
    showConfirmation: PropTypes.func.isRequired
  }),
  graphql(DELETE_ROW_MUTATION, {
    props: ({
      ownProps: {setSavePending, setError, updateTimesheetData, timesheet},
      mutate
    }) => ({
      deleteRowData(rowIndex) {
        // delete row data on the server
        const row = timesheet.rows[rowIndex];
        setSavePending(fromPairs(row.entries.map(e => [e.id, true])));
        mutate({
          variables: {
            ...buildRowInput(timesheet, timesheet.rows[rowIndex])
          },
          update(store, {data: {deleteTimesheetRow}}) {
            if (!deleteTimesheetRow) {
              setTimeout(() => {
                setError('Delete failed');
              });
            } else {
              updateTimesheetData({rows: {$splice: [[rowIndex, 1]]}}, store);
            }
          }
        })
          .catch(err => {
            setError(err);
          })
          .then(() => {
            setSavePending(fromPairs(row.entries.map(e => [e.id, false])));
          });
      }
    })
  }),
  withHandlers({
    deleteRow: ({
      timesheet,
      deleteRowData,
      showConfirmation,
      updateTimesheetData
    }) => rowIndex => {
      // check if row is transient.
      // if it is, update timesheet data to remove it
      // otherwise, request confirmation
      const transient = every(timesheet.rows[rowIndex].entries, e =>
        e.id.startsWith('TMP')
      );
      if (transient) {
        // updateTimesheetData({rows: { [rowIndex]: { $set: makeBlankRow(timesheet) } } })
        updateTimesheetData({rows: {$splice: [[rowIndex, 1]]}});
      } else {
        showConfirmation({
          title: 'Delete Timesheet Row',
          message: 'Are you sure you wish to delete this row?',
          successAction: ['deleteRowData', rowIndex]
        });
      }
    }
  })
);
