/**
 * Shared state reducer (state.app)
 */

import {restoreState, saveState} from 'persist-reducer';
import {handleActions} from 'redux-actions';
import {
  APP_LOGIN,
  APP_LOGOUT,
  FATAL_ERROR,
  // ROOT_CLICK,
  TOGGLE_MENU
} from '../actions/types';

const isMobile = window.innerWidth < 1024;

const saveConfig = {storageKey: 'app', keysToSave: ['menuExpanded']};
const defaultState = restoreState(saveConfig, {
  // an error that demands a page refresh
  fatalError: '',
  // side menu
  menuExpanded: false,
  // TODO default this one according to the size
  useMobileViews: isMobile
});

export default saveState(
  saveConfig,
  handleActions(
    {
      // SET_TITLE: (state, action) => ({...state, title: action.payload}),
      [APP_LOGIN]: (state, action) => ({...state, user: action.payload}),
      [APP_LOGOUT]: (state, action) => ({...state, user: null}),
      [FATAL_ERROR]: (state, action) => ({
        ...state,
        fatalError: action.payload
      }),
      // [ROOT_CLICK]: (state, action) => ({...state, menuExpanded: false}),
      [TOGGLE_MENU]: (state, action) => ({
        ...state,
        menuExpanded: !state.menuExpanded
      })
      // [SHOW_NAV_CONFIRMATION]: (state, action) => ({...state, navigationConfirmation: action.payload}),
      // NAVIGATE: (state, action) => ({
      //   ...state,
      //   menuExpanded: state.useMobileViews ? false : state.menuExpanded,
      //   location: action.payload.to.location.pathname
      // })
    },
    defaultState
  )
);
