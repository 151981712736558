import React from 'react';
import Tooltip from 'rc-tooltip';

import TimesheetNumberCell from './TimesheetNumberCell';

const TimesheetNumberCellOverlay = ({tooltip, tooltipPlacement, ...props}) => {
  const content = <span><TimesheetNumberCell {...props} /></span>;
  if (tooltip) {
    return (
      <Tooltip
        overlay={<span>{tooltip}</span>}
        placement={tooltipPlacement || 'top'}>
        {content}
      </Tooltip>
    );
  }
  return content;
};

export default TimesheetNumberCellOverlay;
