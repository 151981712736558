import React from 'react';
import projectSelectionData from '../hoc/projectSelectionData';
import NestedDropdown from '../../shared/components/nested-dropdown';
import Spinner from '../../shared/components/Spinner';

const ProjectSelection = props => (
  <div className="project-selection">
    <NestedDropdown
      {...props}
      loadingComponent={Spinner}
      onSearchChanged={props.setSearch}
    />
  </div>
);

export default projectSelectionData(ProjectSelection);
