import {graphql} from 'react-apollo';

import {buildTsInput} from '../../../shared/utils/queries';
import {RECALL_TIMESHEET_APPROVAL_MUTATION} from '../../queries';

export default graphql(RECALL_TIMESHEET_APPROVAL_MUTATION, {
  props: ({
    ownProps: {timesheet, setLoading, setError, setSuccess, clearLoading},
    mutate
  }) => ({
    recallTimesheetApproval(selectedDates, resubmit) {
      const dates = timesheet.totals
        .map(x => x.Date.format('YYYY-MM-DD'))
        .filter(x => selectedDates.includes(x));

      if (!dates.length) {
        return setError('There are no days which can be recalled.');
      }

      setLoading();

      mutate({
        variables: {
          ...buildTsInput(timesheet),
          dates,
          resubmit
        }
      })
        .then(
          ({data: {recallTimesheetApproval: {message}}}) => {
            setSuccess(message.toString());
          },
          e => setError(e)
        )
        .then(clearLoading);
    }
  })
});
