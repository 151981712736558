import React from 'react';
import styled from 'styled-components';

import ProjectSelection from './ProjectSelection';

const UpdateSelection = ({onCancel, onDelete, ...props}) => {
  return (
    <Container className="project-update">
      <ProjectSelection {...props} expanded={true} />
      <button className="btn timesheet-cancel" onClick={onCancel}>
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <button className="btn timesheet-delete" onClick={onDelete}>
        <i className="fa fa-trash-o" aria-hidden="true" />
      </button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default UpdateSelection;
