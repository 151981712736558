import React from 'react';
import classNames from 'classnames';

export default ({glyph, className, label}) => (
  <i
    className={classNames('fa', 'fa-' + glyph, className)}
    aria-hidden={!label}
    aria-label={label}
  />
);
