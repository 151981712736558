import React from 'react';
import {withState} from 'recompose';
import AddNoteDialog from './AddNoteDialog';
import ClearButton from '../../shared/components/ClearButton';
import Glyphicon from '../../shared/components/Glyphicon';
import className from 'classnames'

const NoteIndicator = ({
  isReadOnly,
  isSavePending,
  note,
  onDialogClose,
  updateNarrative,
  dialogOpen,
  setDialogOpen,
  view,
  mode,
  onFocus,
  rowIndex,
  entryIndex,
  detailIndex,
  disabled
}) => {
  if (isReadOnly && !note) {
    return null;
  }

  const onClosed = () => {
    setDialogOpen(false);
    if (onDialogClose) onDialogClose();
  };

  const cls = "timesheet-line--note-indicator"

  return (
    <div className={className(cls, {
      'timesheet-line--note-indicator-detail': view === 'detail' && mode === 'approval'
    })

    }>
      <ClearButton onFocus={() => onFocus} onClick={() => setDialogOpen(true)} disabled={isSavePending || disabled}>
        <Glyphicon
          label="View / Edit Notes"
          glyph={note ? 'sticky-note' : 'sticky-note-o'}
        />
      </ClearButton>
      {dialogOpen && (
        <AddNoteDialog
          isReadOnly={isReadOnly}
          onClosed={onClosed}
          onSaveNote={updateNarrative}
          currentNote={note}
          rowIndex={rowIndex}
          entryIndex={entryIndex}
          detailIndex={detailIndex}
          mode={mode}
        />
      )}
    </div>
  );
};

const container = withState('dialogOpen', 'setDialogOpen', false);

export default container(NoteIndicator);
