import React from 'react'
import GreetingContainer from './GreetingContainer'
import Logo from '../../auth/pages/bedrock_wordmark.png'

const Header = ({
	userName
}) => (
	<div>
		<header className='app-header'>
			<a href='https://bedrock.taittowers.com'>
				<img src={Logo} alt='Bedrock Logo' />
			</a>
			{userName && (
				<GreetingContainer
					userName={userName}
				/>
			)}		
		</header>
		<div className='placeholder-heading' />
	</div>
)

export default Header