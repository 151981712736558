import React from 'react';
import Tooltip from 'rc-tooltip';

import DayTypeCell from './DayTypeCell';
import getEntryLockedReason from '../api/getEntryLockedReason';

const DayTypeCellOverlay = ({invalidSelection, mode, labels, ...props}) => {
  const {
    selection,
    isSavePending,
    options,
    posted,
    submitted,
    approved,
    chargeableHours,
    nonChargeableHours
  } = props;

  // if we have only indirect user cannot select road pay
  const roadPayInEligible = (chargeableHours === 0 && nonChargeableHours > 0)

  const selected = options.find(x => x.value === selection);
  const lockedReason = getEntryLockedReason(
    {}, // entry: don't need
    {
      Approved: approved,
      Posted: posted,
      Submitted: submitted,
      RoadPayInEligible: roadPayInEligible
    },
    {}, // row: don't need
    mode
  );
  const tooltip =
    invalidSelection ||
    (lockedReason && labels.lockedReason[lockedReason]) ||
    (typeof selected === 'undefined' ? null : selected.label);

  //Fix error of DOM element not recognizing props
  const rest = Object.assign({}, props);
  delete rest.chargeableHours;
  delete rest.nonChargeableHours;

  let content = (
    <DayTypeCell
      isReadOnly={!!lockedReason || isSavePending || roadPayInEligible}
      isInvalidSelection={!!invalidSelection}
      {...rest}
    />
  );
  if (tooltip) {
    content = (
      <Tooltip overlay={<span>{tooltip}</span>} placement="top">
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default DayTypeCellOverlay;