import {graphql} from 'react-apollo';
// import pick from 'lodash/pick'
// import fromPairs from 'lodash/fromPairs'
import {SAVE_NARRATIVE_MUTATION} from '../../queries';
import buildRowInput from './_buildRowInput';
import buildDetailInput from './_buildDetailInput'
import pick from 'lodash/pick';

export default graphql(SAVE_NARRATIVE_MUTATION, {
  props: ({
    ownProps: {setError, updateTimesheetData, setSavePending, timesheet, activeRow},
    mutate
  }) => ({
    updateNarrative(note, rowIndex, entryIndex, detailIndex) {
      let row2 = timesheet.rows[rowIndex];
      if (!row2) row2 = timesheet.rows[activeRow];
      setSavePending({[row2.id]: true});
      // setSavePending(fromPairs(row.entries.map(e => [e.id, true])))
      return mutate({
        variables: {
          ...buildRowInput(timesheet, row2),
          detail: entryIndex ? buildDetailInput(row2.entries[entryIndex].Detail[detailIndex]) : buildDetailInput(undefined),
          narrative: note          
        },

        // Don't need to do that, GraphQL will handle it
        // update: (store, {data: { saveNarrative } }) => {
        //   // we should update the entry
        //   // if(!saveTime.__optimistic)
        //   //   saveTime.__optimistic = false
        //   updateTimesheetData({ rows: { [activeRow]: { $merge: saveNarrative } } }, store)
        // }
        update: (store, {data: {saveTimeNarrative}}) => {
          const narrative = pick(saveTimeNarrative, [
            'Narrative'
          ])
          updateTimesheetData(
            {
              rows: {
                [rowIndex] : {
                  entries: {
                    [entryIndex]: {
                      Detail: {
                        [detailIndex]: {
                          $merge: narrative
                        }
                      }
                    }
                  }
                }
              }
            }
          )
        }
      })
        .catch(err => {
          setError(err);
        })
        .then(() => {
          // setSavePending(fromPairs(row.entries.map(e => [e.id, false])))
          setSavePending({[row2.id]: false});
        });
    }
  })
});
