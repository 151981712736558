import {withState, compose, withHandlers} from 'recompose';
import ResetPasswordPage from '../pages/ResetPasswordPage'
import { setError  } from '../actions';
import Authentication from '../utils/Authentication'
import User from '../utils/User'

export default compose(
  withState('error', 'setError', ''),
  withState('validationError', 'setValidationError', false),
  withState('userID', 'setUserID', ''),
  withState('success', 'setSuccess', false),
  withState('userName', 'setUserName', ''),
  withState('resetPasswordToken', 'setToken', ''),
  withState('loading', 'setLoading', false),
  withHandlers({
    verifyToken:({setValidationError, setUserID, setUserName, setToken, setLoading}) => e => {
      if (!e.token) {
        setError('Invalid token')
      } else {
        setLoading(true)
        Authentication.verifyToken(e.token).then(
          res => {            
              setUserID(res.UserID)      
              setUserName(res.Name)        
              setToken(res.Token)     
              setLoading(false)
          },
          e => {
            setLoading(false)
            setValidationError(true)
            setError(e.toString())            
          }          
        )
      }
    },
    changePassword:({setSuccess, setValidationError, setLoading}) => e => {
      if (!e.userID && !e.newPassword && !e.resetPasswordToken) {
        setError('User ID or New Password are invalid')
      } else {
        setLoading(true)
        User.changePassword(e.userID, e.resetPasswordToken, e.newPassword).then (
          res => {
            if (res.Success) {
              setSuccess(res.Success)
            } else {
              setError('Unable to change password')
            }
            setLoading(false)
          },
          e => {
            setLoading(false)
            setError(e.toString())
            setValidationError(true)            
          }          
        )
      }      
    }
  })
)(ResetPasswordPage)