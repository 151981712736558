import className from 'classnames';
import React from 'react';
import {withHandlers} from 'recompose';

import ReactSelect from '../../shared/components/inputs/ReactSelect';

/**
 * Cell used for the full-day type selection (overnight comp & in lieu)
 */
const DayTypeCell = ({
  approved,
  isInvalidSelection,
  isSavePending,
  isReadOnly,
  onChange,
  options,
  posted,
  selection,
  submitted,
  ...props
}) => (
  <div
    className={className('timesheet-cell timesheet-cell--daytype', {
      'timesheet-cell--approved': approved,
      'timesheet-cell--invalid': isInvalidSelection,
      'timesheet-cell--posted': posted,
      'timesheet-cell--save-pending': isSavePending,
      'timesheet-cell--submitted': !approved && submitted
    })}
    {...props}>
    <ReactSelect
      options={options}
      value={selection}
      onChange={onChange}
      disabled={isReadOnly}
    />
  </div>
);

const container = withHandlers({
  onChange: ({onChange, date, selection}) => newSelection => {
    onChange(date, selection, newSelection && newSelection.value);
  }
});

export default container(DayTypeCell);