import PropTypes from 'prop-types';

const detailShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  TimeAllocation: PropTypes.number.isRequired
});

export default PropTypes.shape({
  Date: PropTypes.string.isRequired,
  TimeAllocation: PropTypes.number.isRequired,
  OvernightCompensation: PropTypes.boolean,
  Detail: PropTypes.arrayOf(detailShape)
});
