import React from 'react'
import Tooltip from 'rc-tooltip'

import OpOutlookSelection from './OpOutlookSelection'

const OpOutlookSelectionOverlay = ({tooltip, tooltipPlacement, ...props}) => {
  const content = <OpOutlookSelection {...props} />
  if (tooltip && tooltip !== 'NO_ENTRIES') {
    return (
      <Tooltip
        overlay={<span>{tooltip}</span>}
        placement={tooltipPlacement || 'top'}>
        <span>{content}</span>
      </Tooltip>
    )
  }
  return content
}

export default OpOutlookSelectionOverlay