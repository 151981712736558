import {graphql} from 'react-apollo';

import {DELETE_ENTRY_MUTATION} from '../../queries';
import makeBlankEntry from './_makeBlankEntry.js';

export default graphql(DELETE_ENTRY_MUTATION, {
  props: ({
    ownProps: {setError, updateTimesheetData, setSavePending, timesheet},
    mutate
  }) => ({
    deleteEntry({rowIndex, entryIndex, detailIndex = 0}) {
      const row = timesheet.rows[rowIndex];
      const entry = row.entries[entryIndex];

      setSavePending({
        [entry.Date]: true,
        [entry.id]: true
      });
      return mutate({
        variables: {
          id: entry.Detail[detailIndex].id,
          company: timesheet.Company
        },
        update: store => {
          const detailOp = {
            $splice: [[detailIndex, 1]]
          };
          let entryUpdates = {};
          if (entry.Detail.length === 1) {
            // remove everything else on the entry, if there was only one detail
            entryUpdates = makeBlankEntry(timesheet, entryIndex);
          }
          updateTimesheetData(
            {
              rows: {
                [rowIndex]: {
                  entries: {
                    [entryIndex]: {
                      // this could include a new id, so we need to do an explicit update
                      $merge: entryUpdates,
                      Detail: detailOp
                    }
                  }
                }
              }
            },
            store
          );
        }
      })
        .catch(err => {
          setError(err);
        })
        .then(result => {
          // unlock cell and column
          setSavePending({[entry.id]: false, [entry.Date]: false});
        });
    }
  })
});
