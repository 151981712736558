import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import roundTime from '../api/roundTime';
import rowShape from './shapes/rowShape';
import getEntryLockedReason from '../api/getEntryLockedReason';

import TimesheetNumberCellOverlay from './TimesheetNumberCellOverlay';

import {
  REMAININGHOURS_LOOKUP_QUERY
} from '../queries.js';

function TimesheetEntries(props) {
  const { recallTimesheetSubmission, row } = props;
  const [remainingHoursValue, setRemainingHoursValue] = useState(null);

  // Fetch remaining hours when row.Job changes or recallTimesheetSubmission is true
  useEffect(() => {
    if (props.row.Job && recallTimesheetSubmission) {
      fetchRemainingHours();
    }
  }, [props.row.Job, recallTimesheetSubmission]);

  async function fetchRemainingHours() {
    const { row } = props;

    try {
      if (row.Job !== null) {
        const placeholder = await remainingHours(row.Job.value, row.Operation.value);
        setRemainingHoursValue(placeholder);
        
      }
    } catch (error) {
      console.error('Error fetching remaining hours:', error);
    }
  }

  async function remainingHours(jobNum, oprSeq) {
    try {
      const empCompany = props.timesheetSource.Company

      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: REMAININGHOURS_LOOKUP_QUERY,
          variables: {
            company: empCompany,
            jobNum: jobNum,
            assemblySeq: '0',
            oprSeq: oprSeq
          },
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to grab remaning hours');
      }
      const result = await response.json();
      return result.data?.remainingHours?.[0]?.value || 0;

    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Render each cell in the timesheet
  function renderCell(entry, index) {
    const {
      mode,
      isReadOnly,
      timesheet,
      savePending,
      setActiveEntry,
      rowIndex,
      entryIndex,
      detailIndex,
      labels
    } = props;

    const isSavePending =
      props.isSavePending ||
      savePending[entry.id] ||
      savePending[entry.Date] ||
      false;
    const total = timesheet.totals[index];
    const isTemporary = /^TMP/.test(entry.id);
    const isPosted = !!entry.PostedToGL || (isTemporary && !!total.Posted);
    const isApproved = !isPosted && (entry.Approved || (isTemporary && !!total.Approved));
    const isSubmitted = !isPosted && !isApproved && (entry.Submitted || (isTemporary && !!total.Submitted));
    const isOpComplete = row.Operation && row.Operation.OpComplete;
    const lockedReason = getEntryLockedReason(
      entry,
      total,
      row,
      mode,
      true,
      false,
      timesheet.Subcontractor.ActiveWeekContract && timesheet.Subcontractor.ActiveWeekContract[index],
      isOpComplete
    );
    const tooltip = lockedReason && !isReadOnly && (labels.lockedReason[lockedReason] || lockedReason);
    const onChange = async v => {
      // Update the time allocation for the entry
      props.updateTime({ entryIndex: index, hours: v, rowIndex });

      // Fetch remaining hours after updating the entry
      await fetchRemainingHours();
    };

    const isCellReadOnly = isReadOnly || row.key === '' || !!lockedReason;

    // Rendering TimesheetNumberCellOverlay component with calculated props
    return (
      <TimesheetNumberCellOverlay
        hasOvernight={entry.PayType === 'RP'}
        hasOvernightOff={entry.PayType === 'RPR'}
        isApproved={isApproved}
        isPosted={isPosted}
        isReadOnly={isCellReadOnly}
        isRounded
        isSubmitted={isSubmitted}
        isSavePending={isSavePending}
        key={entry.Date}
        onChange={onChange}
        onFocus={() => setActiveEntry(index)}
        tooltip={tooltip}
        value={roundTime(entry.TimeAllocation)}
        rowIndex={rowIndex}
        entryIndex={entryIndex}
        detailIndex={detailIndex}
        remainingHours={remainingHoursValue} // Pass remainingHoursValue here
      />
    );
  }

  return (
    <div className="day-inputs col-md-12 col-xs-9">
      {row.entries.map(renderCell)}
    </div>
  );
}

TimesheetEntries.propTypes = {
  recallTimesheetSubmission: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  isSavePending: PropTypes.bool.isRequired,
  isTimesheetReadOnly: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  row: rowShape.isRequired,
  rowIndex: PropTypes.number.isRequired,
  setActiveEntry: PropTypes.func.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  savePending: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  updateTime: PropTypes.func.isRequired
};

export default TimesheetEntries;